import React from 'react';
import styled from 'styled-components';

const QeAWrapper = styled.div`
  padding: 10px 0;
  border-bottom: ${props => `1px solid ${props.color}`};
  gap: 10px;
  cursor: pointer;
`;

const QuestionWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 24px;
  height: 60px;

  img {
    transition: all 0.5s ease-in-out;    
  }
`;

const Question = styled.h3`
  font-size: 20px;
  line-height: 125%;
  font-weight: 500;
  margin: 0;
`;

const Answer = styled.p`
  max-width: 100%;
  font-size: 16px;
  line-height: 125%;
  font-weight: 500;
  font-family: 'Red Hat Display', sans serif;
  opacity: 0.8;
  margin: ${props => props.open ? '0' : '-10px 0 0'};
  padding: ${props => props.open ? '10px 44px 10px 24px' : '0px 44px 0px 24px'};
  overflow: hidden;
  max-height: ${props => props.open ? '800px' : '0px'};
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
`;

const QeA = ({ qea, separatorColor }) => {
  const [open, setOpen] = React.useState(false);
  
  return (
    <QeAWrapper color={separatorColor} className='flex column full-width text-left' onClick={() => setOpen(!open)}>
      <QuestionWrapper className='flex row justify-between align-center nowrap full-width'>
        <Question>
          {qea.question}
        </Question>
        <img src='/commerce/plus-icon.svg' alt="" width="19px" style={{ transform: open ? 'rotate(-225deg)' : '' }}/>
      </QuestionWrapper>
      <Answer open={open}>{qea.answer}</Answer>
    </QeAWrapper>
  );
};

export default QeA;
