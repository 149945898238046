import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
	0% { transform: rotate(0deg); }
  100%   { transform: rotate(360deg); }
`

const floatation = keyframes`
	0%, 100% { transform: translateY(var(--height)); }
  50%   { transform: translateY(0); }
`
const grow = keyframes`
	0%, 100% { transform: scale(1.5); }
  50%   { transform: scale(1); }
`

const Swiper = styled.div`
  width: 100%;
  height: 34.64vw;
  position: relative;

  @media screen and (max-width: 1440px) {
    height: 500px;
  }
`;

const CharCylinders = styled.img`
  height: auto;
  width: 47.4vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    width: 682px;
  }

  @media screen and (max-width: 980px) {
    width: 100vw;
    max-width: 420px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  wrap: nowrap;
  width: max-content;
  position: relative;
  width: 100%;
  height: inherit;

  .active-slide {
    opacity: 1;
    width: 32.24vw;
    padding-top: 2vw;
    z-index: 2;
    margin-bottom: 6.85vw;
  }

  .active-slide .char-image {
    --height: -2vw;
  }

  #position-0 {
    left: 0;
  }

  #position-1 {
    left: 5.81vw;
  }

  #position-2 {
    left: 34.04vw;
  }

  #info-active {
    transform: scaleX(1);
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 0.75s;
  }

  #info-normal {
    transform: scaleX(0);
    opacity: 0;
    transition-delay: 0s;
    transition-duration: 0.75s;
  }

  @media screen and (max-width: 1440px) {
    .active-slide {
      width: 464px;
      padding-top: 30px;
      margin-bottom: 98px;
    }

    .active-slide .char-image {
      --height: -30px;
    }

    #position-1 {
      left: 84px;
    }

    #position-2 {
      left: 490px;
    }
  }

  @media screen and (max-width: 980px) {
    align-items: end;
    max-width: 420px;
    left: 50%;
    transform: translateX(-50%);

    .active-slide {
      width: 70%;
      padding-top: 15px;
      margin-bottom: 45px;
    }

    .active-slide .char-image {
      --height: -15px;
    }

    #position-0 {
      bottom: 7.8%;
      left: -5%;
    }

    #position-1 {
      left: 15%;
    }

    #position-2 {
      left: 74%;
      bottom: 7.8%;
    }
  }
`;

const Gradient = styled.div`
  width: 14.14vw;
  height: 34.64vw;
  position: absolute;
  left: ${props => props.left ? '0' : '34.04vw'};
  background: ${props => props.left ? 'linear-gradient(90deg, #030917 0%, rgba(3, 9, 23, 0) 100%)' : 'linear-gradient(270deg, #030917 0%, rgba(3, 9, 23, 0) 100%)'};
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 204px;
    height: 500px;
    left: ${props => props.left ? '0' : '490px'};
  }

  @media screen and (max-width: 980px) {
    width: 30%;
    left: ${props => props.left ? '-5%' : '74%'};
  }
`;

const Slide = styled.div`
  opacity: 0.6;
  width: 14.14vw;
  padding-top: 1vw;
  margin-bottom: 4.4vw;
  display: flex;
  justify-content: end;
  z-index: 1;
  position: absolute;
  overflow: hidden;
  transition: all 1s ease-in-out, opacity 0.01s ease-in-out;

  .char-image {
    animation: ${floatation} 6s ease-in-out infinite;
    --height: -1vw;
  }

  @media screen and (max-width: 1440px) {
    width: 204px;
    padding-top: 15px;
    margin-bottom: 63px;

    .char-image {
      --height: -15px;
    } 
  }

  @media screen and (max-width: 980px) {
    width: 30%;

    .char-image {
      --height: -10px;
    } 
  }
`;

const CharImage = styled.img`
  height: auto;
  width: 100%;
  margin-bottom: 10px;
`;

const CharShadow = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  animation: ${grow} 6s ease-in-out infinite;
  z-index: 2;
`;

const CardWrapper = styled.div`
  margin: 5vw 0 0 -3vw;
  width: 29vw;
  height: 9.83vw;
  z-index: 3;
  position: absolute;
  top: 0px;
  right: 0px;

  @media screen and (max-width: 1440px) {
    margin: 72px 0 0 -43px;
    width: 417px;
    height: 142px;
  }

  @media screen and (max-width: 980px) {
    margin: 0;
    width: 100%;
    height: 160px;
  }
`;

const InfoWrapper = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  border-radius: 12px;
  transition: 1s ease-in-out;
`;

const InfoWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1.45vw 5.36vw 1.45vw 2.08vw;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  gap: 16px;
  letter-spacing: 0.015em;
  font-weight: 400;
  text-align: left;
  background: linear-gradient(275deg,rgba(12,12,13,0.38) 0%, rgba(202,202,202,0.1) 40%, rgba(202,202,202,0.1) 60%, rgba(12,12,13,0.38) 100%);
  border-radius: 12px;

  @media screen and (max-width: 1440px) {
    padding: 20px 60px 20px 30px;  
  }

  @media screen and (max-width: 980px) {
    padding: 25px;  
  }
`;

const InfoTitle = styled.p`
  font-size: 2.08vw;
  line-height: 2.19vw;
  margin: 0;
  max-width: calc(100% - 70px);
  text-transform: uppercase;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 32px;
  }

  @media screen and (max-width: 980px) {
    font-size: 24px;
    line-height: 25px;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(-90deg, #4AA6F7 -20%, #1141BD 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`;

const InfoText = styled.p`
  font-size: 1.25vw;
  line-height: 1.3vw;
  color: #FFFFFF;
  opacity: 0.7;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 19px;
  }

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 21px;
  }
`;

const SealWrapper = styled.div`
  width: 10.78vw;
  height: 10.78vw;
  padding: 1px;
  border-radius: 100%;
  background: linear-gradient(-264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  position: absolute;
  top: -5.4vw;
  right: -6.1vw;

  @media screen and (max-width: 1440px) {
    width: 155px;
    height: 155px;
    top: -77px;
    right: -88px;
  }

  @media screen and (max-width: 1340px) {
    right: -50px;
  }

  @media screen and (max-width: 980px) {
    width: 98px;
    height: 98px;
    top: -49px;
    right: -10px;
  }
`;

const SealWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  background: #030917;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CircleText = styled.div`
  width: 100%;
  height: 100%;
  font-size: 17px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.15em;
  animation: ${rotation} 8s linear infinite;
`;

const SharkWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 4.38vw;
  height: 4.38vw;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: linear-gradient(154.49deg, #EABEFF -66.33%, #4AA6F7 -33.37%, #00288F 107.87%);

  @media screen and (max-width: 1440px) {
    width: 63px;
    height: 63px;
  }  

  @media screen and (max-width: 980px) {
    width: 40px;
    height: 40px;
  }
`;

const SharkImage = styled.img`
  width: 2.55vw;
  height: auto;
  margin: 6px 0 0 2px;

  @media screen and (max-width: 1440px) {
    width: 37px;
  }

  @media screen and (max-width: 980px) {
    width: 23px;
  }
`;

const NavigationArrows = styled.div`
  height: 72px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  gap: 24px;
  z-index: 2;

  @media screen and (max-width: 1165px) {
    bottom: 90px;
    right: 54px;
  }

  @media screen and (max-width: 980px) {
    height: 56px;
    gap: 16px;
    right: 50%;
    transform: translateX(50%);
    bottom: -80px;
  }
`;

const ContactButton = styled.a`
  background: linear-gradient(247.26deg, #4AA6F7 -59.54%, #4AA6F7 -44.66%, #1141BD 90.48%);
  filter: drop-shadow(0px 4px 30px rgba(0, 136, 255, 0.1));
  display: flex;
  column-gap: 0px;
  align-items: center;
  justify-content: center;
  width: ${props => `${props.width}px`};
  max-width: calc(100vw - 176px);
  height: 64px;
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  z-index: 2;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 1165px) {
    position: absolute;
    top: 90px;
    right: -54px;
  }
  @media screen and (max-width: 980px) {
    position: static;
    grid-column: 2;
  }
`;

const NavigationArrow = styled.img`
  height: 100%;
  width: auto;
  cursor: pointer;
`;

const ChooseChar = () => {
  
  const [prevSlide, setPrevSlide] = React.useState(0);
  const [activeSlide, setActiveSlide] = React.useState(1);
  const [nextSlide, setNextSlide] = React.useState(2);

  async function next() {
    setPrevSlide(activeSlide);
    setNextSlide(prevSlide);
    setActiveSlide(activeSlide === 2 ? 0 : activeSlide + 1);
    console.log(prevSlide, activeSlide, nextSlide);
  };

  async function prev() {
    setNextSlide(activeSlide);
    setPrevSlide(nextSlide);
    setActiveSlide(activeSlide === 0 ? 2 : activeSlide - 1);
    console.log(prevSlide, activeSlide, nextSlide);
  };

  const slides = [
    {
      image: '/char-websites.png',
      shadow: '/char-ws-shadow.png',
      title: 'Web Site',
      text: "Sites institucionais 100% focados em conversão: cadastros, agendamentos ou vendas"
    },
    {
      image: '/char-landingpage.png',
      shadow: '/char-lp-shadow.png',
      title: 'Landing Page',
      text: "Páginas de captura, de obrigado, de vendas, de 'replay', entre outros tipos"
    },
    {
      image: '/char-ecommerce.png',
      shadow: '/char-ec-shadow.png',
      title: 'Ecommerce',
      text: "Lojas projetadas para elevar tanto o número de vendas, quanto o ticket médio"
    }
  ];

  return (
    <Swiper>
      <SwiperWrapper>
        <CharCylinders src={'/char-cylinders.png'}/>
        <Gradient left={true}> </Gradient>
        {slides.map((slide, index) => (
          <Slide key={index} next={index === nextSlide} id={index === activeSlide ? 'position-1' : (index === nextSlide ? 'position-2' : 'position-0')} className={activeSlide === index ? 'active-slide' : 'normal-slide'}>
            <CharImage className="char-image" src={slide.image} index={index}/>
            <CharShadow className="char-shadow" index={index} src={slide.shadow}/>    
          </Slide>
        ))}
        <CardWrapper>
          {slides.map((slide, index) => (
            <InfoWrapper id={index === activeSlide ? 'info-active' : 'info-normal'}>
              <div style={{ background: '#030917', borderRadius: '12px', width: 'calc(100% - 2px)', height: 'calc(100% - 2px)', margin: '1px' }} >
                <InfoWrapperInner>
                  <InfoTitle>
                    <GradientTitle>
                      {slide.title}
                    </GradientTitle>
                  </InfoTitle>
                  <InfoText>
                    {slide.text}
                  </InfoText>
                </InfoWrapperInner>
              </div>
            </InfoWrapper>
          ))}
          <SealWrapper>
            <SealWrapperInner>
              <CircleText>
                <svg viewBox="0 0 207 207">
                  <path 
                    id="curve"
                    d="
                      M 34, 104
                      a 70,70 0 1,1 140,0
                      a 70,70 0 1,1 -140,0
                      "
                    fill="transparent"
                  />
                  <text fill="#fff">
                    <textPath xlinkHref="#curve">
                      TURBINE SEU SITE • TURBOPARTNERS •
                    </textPath>
                  </text>
                </svg>
              </CircleText>
              <SharkWrapper>
                <SharkImage src={'/shark-image.png'}/>
              </SharkWrapper>
            </SealWrapperInner>
          </SealWrapper>
        </CardWrapper>
        <Gradient left={false}> </Gradient>
        <NavigationArrows>
          <NavigationArrow src={'navigation-arrow.png'} onClick={() => prev()}/>
          <ContactButton className='change-link' href='https://form.respondi.app/SOMTDxh7' target="_blank" width={275}>
            Selecionar
          </ContactButton>
          <NavigationArrow src={'navigation-arrow.png'} style={{ transform: 'scaleX(-1)' }} onClick={() => next()}/>
        </NavigationArrows>
      </SwiperWrapper>
    </Swiper>
  );
};

export default ChooseChar;
