import React from 'react';
import Header from '../sections/Header';
import ThanksMessage from '../sections/ThanksMessage';
import ParceirosCards from '../sections/ParceirosCards';
import Status from '../sections/status';

import Footer from '../sections/Footer';

const ThankYou2 = ({ isMobile, isTablet }) => {

  return (
    <div style={{ backgroundColor: '#0D031A' }}>
      <Header  isTablet={isTablet}/>
      <ThanksMessage isMobile={isMobile} />
      <ParceirosCards isMobile={isMobile} />
      <Status isTablet={isTablet}/>
      <Footer isMobile={isMobile} />
    </div>
  )
};

export default ThankYou2;
