import React from 'react';
import styled, { keyframes } from 'styled-components';

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(2vw) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(2vw) rotate(0deg); }
`

const floatation2 = keyframes`
	from { transform: rotate(0deg) translateX(2vw) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(2vw) rotate(-360deg); }
`

const PolicyContent = styled.div`
  display: flex;
  width: 100vw;
  position: relative;
  padding: 9.2vw 0 5vw 0;

  @media screen and (max-width: 1440px) {
    padding: 132px 0 72px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 100px 0 195px 0;
  }
`;

const Planet1 = styled.img`
  height: auto;
  width: 39.06vw;
  position: absolute;
  top: 3vw;
  left: -17.58vw;
  animation: ${floatation} 24s linear infinite;
  animation-delay: -8s;

  @media screen and (max-width: 1440px) {
    width: 562px;
    top: 43px;
    left: -253px;
  }

  @media screen and (max-width: 768px) {
    width: 323px;
    top: 20px;
    left: -150px;
    z-index: 0;
  }
`;

const Planet2 = styled.img`
  height: auto;
  width: 53.02vw;
  position: absolute;
  top: 3vw;
  right: -23.86vw;
  animation: ${floatation} 24s linear infinite;
  animation-delay: -16s;

  @media screen and (max-width: 1440px) {
    width: 763px;
    top: 43px;
    right: -343px;
  }

  @media screen and (max-width: 768px) {
    width: 290px;
    top: 400px;
    right: -130px;
    z-index: 0;
  }
`;

const Planet3 = styled.img`
  height: auto;
  width: 14.17vw;
  position: absolute;
  top: 50vw;
  left: -5vw;
  animation: ${floatation} 16s linear infinite;

  @media screen and (max-width: 1440px) {
    width: 204px;
    top: 720px;
    left: -72px;
  }

  @media screen and (max-width: 768px) {
    width: 210px;
    left: -75px;
    bottom: -75px;
    top: unset;
    z-index: 0;
  }
`;

const LogoDino = styled.img`
  height: auto;
  width: 11.61vw;
  position: absolute;
  bottom: 4vw;
  right: 2.45vw;
  animation: ${floatation2} 24s linear infinite;
  animation-delay: -16s;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    width: 167px;
    bottom: 57px;
    right: 35px;
  }

  @media screen and (max-width: 768px) {
    width: 177px;
    bottom: 70px;
    right: -30px;
    z-index: 2;
  }
`;

const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 120px);
  box-sizing: border-box;
  padding: 56px 24px 36px 56px;
  background: rgba(217, 217, 217, 0.01);
  border: 1px solid rgba(255, 255, 255, 0.52);
  backdrop-filter: blur(13.5px);
  border-radius: 36px;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 1440px) {
    padding: 42px 18px 27px 42px;
    border-radius: 27px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 10px 0 20px;
    border-radius: 24px;
  }
`;

const BallWrapper = styled.div`
  display: flex;
  column-gap: 13px;
  margin-bottom: 20px;

  div {
    width: 17px;
    height: 17px;
    border-radius: 100%;
  }

  div:nth-child(1) {
    background: #BC584A;
  }

  div:nth-child(2) {
    background: #EAB050;
  }

  div:nth-child(3) {
    background: #6ACB85;
  }
`;

const PolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 194px);
  overflow-y: scroll;
  box-sizing: border-box;
  padding-right: 24px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff; 
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6163F0; 
  }

  @media screen and (max-width: 768px) {
    padding-right: 10px;
  }
`;

const PolicyTitle = styled.h2`
  font-size: 3.33vw;
  line-height: 4.06vw;
  margin: 0 0 22px 0;
  font-weight: 700;
  color: #fff;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
  }

  @media screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
    margin: 25px 0 19px 0;
    text-align: left;
  }
`;

const PolicyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  font-size: 1.25vw;
  line-height: 2.08vw;
  margin: 0;
  font-weight: 400;
  color: #fff;
  padding-bottom: calc(100vh - 230px);

  b {
    font-size: 1.67vw;
    line-height: 2.71vw;
  }

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 30px;

    b {
      font-size: 24px;
      line-height: 39px;
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;

    b {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const GradientEffect = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 36px);
  height: 188px;
  background: linear-gradient(180deg, rgba(8, 3, 26, 0) 0%, #08031A 82.8%);
  z-index: 2;

  @media screen and (max-width: 768px) {
    height: 113px;
    width: 100%;
  }
`;

const PoliciesTemplate = ({ policy }) => {

  return (
    <PolicyContent className="page-width">
      <LogoDino src="turbo-dino.png"/>
      <Planet1 src='planet1.png'/>
      <Planet2 src='planet2.png'/>
      <Planet3 src='planet3.png'/>
      <DeviceWrapper>
        <BallWrapper>
          <div> </div>
          <div> </div>
          <div> </div>
        </BallWrapper>
        <PolicyWrapper>
          <PolicyTitle>{policy.title}</PolicyTitle>
          <PolicyText>
            {policy.text}
          </PolicyText>
        </PolicyWrapper>
        <GradientEffect></GradientEffect>
      </DeviceWrapper>
    </PolicyContent>
  );
};

export default PoliciesTemplate;

