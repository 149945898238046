import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  height: ${props => `${props.height}px`};
  width: ${props => `${props.height}px`};
  margin: ${props => props.margin};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Icon = ({ height, fill, margin, icon }) => {

  return (
    <IconWrapper height={height} margin={margin ? margin : '0 0 0 10px'}>
      {icon === 'user' && (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1495_796)">
            <path d="M8.39607 8.67065C9.52109 8.67065 10.4953 8.24342 11.2912 7.4005C12.0872 6.55771 12.4907 5.52651 12.4907 4.33519C12.4907 3.14427 12.0872 2.11294 11.2911 1.26988C10.495 0.427229 9.52096 0 8.39607 0C7.27093 0 6.29701 0.427229 5.50105 1.27001C4.70508 2.1128 4.30145 3.14414 4.30145 4.33519C4.30145 5.52651 4.70508 6.55785 5.50117 7.40064C6.29727 8.24328 7.27132 8.67065 8.39607 8.67065Z" fill={fill ? fill : "#fff"}/>
            <path d="M15.5606 13.8417C15.5376 13.491 15.4912 13.1084 15.4228 12.7043C15.3538 12.2973 15.265 11.9125 15.1586 11.5608C15.0488 11.1973 14.8993 10.8383 14.7147 10.4943C14.523 10.1373 14.2978 9.82635 14.0451 9.57051C13.7809 9.30285 13.4575 9.08766 13.0834 8.93069C12.7107 8.77455 12.2976 8.69545 11.8557 8.69545C11.6821 8.69545 11.5143 8.77084 11.1902 8.99428C10.9907 9.13202 10.7574 9.29132 10.4969 9.46751C10.2743 9.61775 9.97257 9.75851 9.59995 9.88595C9.2364 10.0105 8.86727 10.0737 8.50294 10.0737C8.13862 10.0737 7.76962 10.0105 7.40569 9.88595C7.03345 9.75865 6.73177 9.61789 6.50933 9.46765C6.25136 9.2931 6.0179 9.1338 5.81544 8.99414C5.49171 8.77071 5.32375 8.69531 5.15021 8.69531C4.70819 8.69531 4.29523 8.77455 3.9226 8.93083C3.54881 9.08752 3.2252 9.30272 2.96075 9.57064C2.70822 9.82663 2.48293 10.1374 2.2915 10.4943C2.10693 10.8383 1.95752 11.1972 1.84753 11.5609C1.74131 11.9126 1.65247 12.2973 1.58347 12.7043C1.51511 13.1078 1.46868 13.4906 1.44572 13.8421C1.42316 14.1865 1.41174 14.544 1.41174 14.905C1.41174 15.8446 1.69384 16.6053 2.25012 17.1663C2.79953 17.7199 3.5265 18.0007 4.41053 18.0007H12.5961C13.4802 18.0007 14.2069 17.72 14.7564 17.1663C15.3128 16.6057 15.5949 15.8449 15.5949 14.9049C15.5948 14.5422 15.5833 14.1845 15.5606 13.8417Z" fill={fill ? fill : "#fff"}/>
          </g>
          <defs>
            <clipPath id="clip0_1495_796">
              <rect width="17" height="18" fill="white" transform="translate(0.0172119)"/>
            </clipPath>
          </defs>
        </svg>
      )}
      {icon === 'rocket' && (
        <svg id="Grupo_731" data-name="Grupo 731" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <defs>
            <clipPath id="clip-path">
              <rect id="Retângulo_583" data-name="Retângulo 583" width="32" height="32" fill={fill ? fill : "#fff"}/>
            </clipPath>
          </defs>
          <g id="Grupo_702" data-name="Grupo 702" clipPath="url(#clip-path)">
            <path id="Caminho_2137" data-name="Caminho 2137" d="M2.182,19.76c-.565.064-1.136.1-1.692.2-.437.084-.583.022-.432-.445.175-.541.3-1.1.455-1.645l1.374-3.149a2.019,2.019,0,0,0,.46-.789A22.28,22.28,0,0,1,4.3,11.606l1.024-.535a13.062,13.062,0,0,1,3.144-.456c.42,0,.872.258,1.241-.161.533-.606,1.071-1.208,1.607-1.812l.528-.477a39.992,39.992,0,0,1,6.041-4.277,48.136,48.136,0,0,1,6.3-2.6L27.3.508,30.449,0c1.678-.02,1.677-.02,1.558,1.693C32,1.85,32,2,32,2.158L31.442,5.3l-.757,2.866a44.932,44.932,0,0,1-2.809,6.53,3.768,3.768,0,0,0-.606,1.042l-1.42,2.1A23.11,23.11,0,0,1,22.3,21.749a2.079,2.079,0,0,0-.859,2.385c-.034.283-.076.566-.1.85a5.166,5.166,0,0,1-2.01,3.824,21.326,21.326,0,0,1-4.591,2.551,27.585,27.585,0,0,1-2.672.715l.15-1.659a24.421,24.421,0,0,0,.457-3.142c.041-.174.092-.347.122-.524.233-1.408.231-1.408-1.193-1.308a1.952,1.952,0,0,1-1.726-.8c-.895-.965-1.848-1.876-2.786-2.8a1.337,1.337,0,0,1-.492-.844c.016-.2.045-.4.047-.606.013-1.368.011-1.368-1.322-1.083l-3.143.455m18.836-6.49a2.479,2.479,0,0,0,2.861-1.678,2.519,2.519,0,0,0-1.425-3.378,2.409,2.409,0,0,0-2.866.48,2.416,2.416,0,0,0-.646,2.9,2.336,2.336,0,0,0,2.075,1.68" transform="translate(0 0)"  fill={fill ? fill : "#fff"}/>
            <path id="Caminho_2138" data-name="Caminho 2138" d="M22.545,148.3c.474-.162.854.086.789.532a3.328,3.328,0,0,1-.189.57l-.609.764a8.084,8.084,0,0,1-3.126,1.3c-.723.039-1.447.069-2.169.12-.409.028-.55-.142-.526-.543.033-.542.025-1.086.034-1.629a11.405,11.405,0,0,1,.97-3.125,4.539,4.539,0,0,1,1.7-1.395c.8.3.825.394.264,1.207a3.243,3.243,0,0,0-.421,2.44.5.5,0,0,0,.493.494,2.952,2.952,0,0,0,2.788-.734" transform="translate(-14.087 -122.143)"  fill={fill ? fill : "#fff"}/>
          </g>
        </svg>
      )}
      {icon === 'control' && (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.6772 10.224C21.2227 6.93975 18.4958 4.5 15.2805 4.5H8.7195C5.50425 4.5 2.778 6.93975 2.32275 10.224C2.271 10.5982 1.5 16.032 1.5 16.41C1.5 18.1162 2.841 19.5 4.49475 19.5C5.6295 19.5 6.666 18.8385 7.17375 17.7915L9 14.25H15L16.8263 17.7915C17.3333 18.8385 18.3705 19.5 19.5052 19.5C21.159 19.5 22.5 18.1162 22.5 16.41C22.5 16.032 21.729 10.5982 21.6772 10.224ZM16.5 6.75C17.052 6.75 17.4998 7.19775 17.4998 7.74975C17.4998 8.30175 17.052 8.7495 16.5 8.7495C15.948 8.7495 15.5002 8.30175 15.5002 7.74975C15.5002 7.19775 15.948 6.75 16.5 6.75ZM9.75 10.5H8.25V12H6.75V10.5H5.25V9H6.75V7.5H8.25V9H9.75V10.5ZM14.4998 10.7498C13.9478 10.7498 13.5 10.302 13.5 9.75C13.5 9.198 13.9478 8.75025 14.4998 8.75025C15.0518 8.75025 15.4995 9.198 15.4995 9.75C15.5002 10.302 15.0525 10.7498 14.4998 10.7498ZM16.5 12.75C15.948 12.75 15.5002 12.3022 15.5002 11.7502C15.5002 11.1982 15.948 10.7505 16.5 10.7505C17.052 10.7505 17.4998 11.1982 17.4998 11.7502C17.4998 12.3022 17.052 12.75 16.5 12.75ZM18.5002 10.7498C17.9482 10.7498 17.5005 10.302 17.5005 9.75C17.5005 9.198 17.9482 8.75025 18.5002 8.75025C19.0522 8.75025 19.5 9.198 19.5 9.75C19.5 10.302 19.0522 10.7498 18.5002 10.7498Z" fill={fill ? fill : 'white'}/>
        </svg>
      )}
    </IconWrapper>
  );
 };
 
 export default Icon;
