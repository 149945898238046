import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const HeroContainer = styled.div`
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: calc(9vw + 80px);
  position: relative;
  z-index: 5;

  @media screen and (max-width: 1440px) {
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    height: max-content;
    padding-bottom: 60px;
  }
`; 

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: calc(6.72vw + 10px);
  z-index: 2;
  width: 50%;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
    padding-top: 100px;
    width: 100%
  }

  @media screen and (max-width: 768px) {
    row-gap: 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

const BrandRow = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  z-index: 2;
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    column-gap: 7px;
  }
`;

const BrandIcon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

const BrandName = styled.h2`
  color: #fff;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.51vw;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }

  span {
    background: #A26BE3;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
  }
`;

const Title = styled.h2`
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 75px;
  color: #F7F1FC;
  margin: 0 0 16px 0;

  @media screen and (max-width: 1440px) {
    font-size: 35px;
    line-height: 42px;
    width: 578px;
    max-width: 50%;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 39px;
    width: 100%;
    max-width: 100%;
  }
`;

const Subtitle = styled.p`
  font-family: 'Oxanium';
  font-size: 20px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: -0.0128em;
  color: #F7F1FC;
  margin: 0 0 32px 0;


  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
    width: 578px;
    max-width: 55%;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    max-width: 100%;
  }
`;

const HeroImg = styled.img`
  position: relative;
  z-index: 1;
  top: -10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const CommerceIntro = ({ isMobile }) => {

  return (
    <HeroContainer>  
      <HeroWrapper className="page-width">
        <TextWrapper>
          <BrandRow>
            <BrandIcon src='/TurboCommerce_2.svg'/>
            <BrandName>
              Turbo <span>Commerce</span>
            </BrandName>
          </BrandRow>
          <Title>
            Aumente suas vendas com a Turbo!
          </Title>
          <Subtitle>
          Escolha o seu plano e tenha um time completo de especialistas em marketing digital turbinando a sua empresa.
          </Subtitle>
          <ContactButton
            className="change-link"
            icon="arrow" height={60} 
            width={360}
            solidButton={true} text="Começar agora" 
            fontsize={16} font="'Oxanium', sans serif" 
            fontWeight={700} textColor="#F2EDF9" 
            background="linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)" 
            hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%);"
            shadow="unset" 
            borderRadius={4}
            link='#redirect'
            innerStyle={{ textDecoration: 'none' }}
            />
        </TextWrapper>
      </HeroWrapper>
      <HeroImg src={isMobile ? '/intro-commercemobile.png' : '/intro-commerce.png'}/>
    </HeroContainer>
  );
};

export default CommerceIntro;
