import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Wrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width ? `${props.width}px` : 'fit-content'};
  max-width: 100%;
  padding: ${props => props.solidButton ? '0 10px' : '1px'};
  box-sizing: border-box;
  height: ${props => props.height ? `${props.height}px` : '52px'};
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : '16px'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : '700'};
  font-family: ${props => props.font};
  letter-spacing: -0.032em;
  color: ${props => props.textColor ? props.textColor : '#fff'};
  background: ${props => props.background};
  box-shadow: ${props => props.shadow ? props.shadow : '0px 16px 50px rgba(120, 107, 227, 0.38)'};
  border-radius: ${props => props.borderRadius ? `${props.borderRadius}px` : '6px'};
  text-decoration: none;
  z-index: 5;

  &:hover {
    text-decoration: ${props => props.solidButton && props.icon !== 'arrow' ? 'underline' : 'none'};
    background: ${props => props.hoverBackground};
  }

  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 32px);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.innerBackground ? props.innerBackground : '#08031A'};
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  transition: all 0.7s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  
  &:hover {
    background: unset; 
    color: #fff;
  }
`;

const Arrow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid #F7F1FC;
  height: 28px;
  width: fit-content;
  transition: 0.5s all ease-out;

  span {
    background-color: #F7F1FC;
    width: 19px;
    height: 2px;
    border-radius: 2px;
    transition: 0.5s all ease-out;
  }

  &:before {
    content: '';
    position: absolute;
    top: 9px;
    right: 0px;
    transform: rotate(45deg);
    width: 9px;
    height: 9px;
    border-radius: 2px;
    border-top: 2px solid #F7F1FC;
    border-right: 2px solid #F7F1FC;
    transition: 0.5s all ease-out;
  }

  ${Wrapper}:hover & {
    padding-left: 8px;
    margin-left: 8px;

    span {
      width: 35px;
      transition: 0.5s all ease-out;
    }
  }
`;

const ContactButton = ({ width, height, link, target, text, textColor, font, fontSize, fontWeight, icon, iconHeight, solidButton, background, hoverBackground, innerBackground, shadow, borderRadius, innerStyle }) => {
  
  return (
    <Wrapper
      solidButton={solidButton}
      font={font}
      fontSize={fontSize}
      fontWeight={fontWeight}
      textColor={textColor}
      width={width}
      height={height}
      background={background}
      hoverBackground={hoverBackground}
      innerBackground={innerBackground}
      shadow={shadow}
      borderRadius={borderRadius}
      style={innerStyle}

      href={link ? link : null}
      target={target ? target : null}
      className={link ? 'change-link' : null}
    >
      {solidButton ? (
        <>
          {text}
          {icon === 'arrow' ? (
            <Arrow><span></span></Arrow>
          ) : (
            <Icon height={iconHeight} icon={icon}/>
          )}
        </>
      ) : (
        <InnerWrapper>
          {text}
          {icon === 'arrow' ? (
            <Arrow><span></span></Arrow>
          ) : (
            <Icon height={iconHeight} icon={icon}/>
          )}
        </InnerWrapper>
      )}      
    </Wrapper>
  );
};

export default ContactButton;
