import React from 'react';
import Header from '../sections/Header';
import Hero from '../sections/Hero';
import Brands from '../sections/Brands';
import Numbers from '../sections/Numbers';
import Partners from '../sections/Partners';
import Comments from '../sections/Comments';
import Footer from '../sections/Footer';

const Home = ({ isMobile, isTablet }) => {
  return (
    <div style={{ backgroundColor: '#08031A', position: 'relative' }}>
      <Header isTablet={isTablet}/>
      <Hero isMobile={isMobile}/>
      <Brands isMobile={isMobile}/>
      <Numbers isMobile={isMobile}/>
      <Partners isMobile={isMobile}/>
      <Comments isMobile={isMobile} isTablet={isTablet}/>
      <Footer isMobile={isMobile} background={'#060613'}/>
    </div>
  )
};

export default Home;
