import React from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import DashIntro from '../sections/DashIntro';
import DashInfo from '../sections/DashInfo';
import DashROAS from '../sections/DashROAS';
import DashGrafico from '../sections/DashGrafico';
import DashComments from '../sections/DashComments';
import { Helmet } from 'react-helmet';

const TurboDash = ({ isMobile, isTablet }) => {

  return (
    <div style={{ backgroundColor: '#030917' }}>
      <Helmet>
        <title>Turbo Dash</title>
        <meta name="description" content="Turbine seu negócio com o poder da tecnologia"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <DashIntro isMobile={isMobile} />
      <DashInfo isMobile={isMobile} />
      <DashROAS isMobile={isMobile} />
      <DashGrafico isMobile={isMobile} />
      <DashComments isMobile={isMobile} />
      <Footer isMobile={isMobile}  />
    </div>
  )
};

export default TurboDash;
