import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HowWrapper = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  row-gap: 62px;

  @media screen and (max-width: 768px) {
    background: linear-gradient(180deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 57px 30px;

  @media screen and (max-width: 768px) {
    gap: 46px 30px; 
  }
`;

const HowCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  gap: 16px;
  padding: ${props => props.index < 3 ? '51px 10px' : '32px 10px'};
  width: calc((100% - 60px)/3);
  min-width: 335px;
  min-height: 284px;
  background: ${props => props.index === 5 ? 'linear-gradient(#fff, #fff) padding-box, linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%) border-box' : '#fff'};
  border: ${props => props.index === 5 ? '2px solid transparent' : '2px solid #342A41'};
  border-radius: 16px;
  text-align: center;
  color: #342A41;
  transform: translate(0, 300px);
  opacity: 0;
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};

  @media screen and (max-width: 768px) {
    height: 266px;
    min-height: unset;
    width: 358px;
    min-width: unset;
    max-width: 100%; 
    padding: ${props => props.index < 3 ? '51px 20px' : '32px 20px'};
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  box-decoration-break: clone;
	-webkit-box-decoration-break: clone;
  font-weight: 700;
`;

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 2.5vw;
  line-height: 2.92vw;
  font-weight: 700;
  font-family: 'Paralucent';
  max-width: 100%;
  color: #fff;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

const HowTitle = styled.h4`
  margin: 0;
  font-size: 1.67vw;
  line-height: 1.98vw;
  font-weight: 700;
  font-family: 'Paralucent';
  max-width: ${props => props.index < 3 ? '16.77vw' : '13.13vw'};

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 29px;
    max-width: ${props => props.index < 3 ? '242px' : '190px'};
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
    max-width: ${props => props.index < 3 ? '100%' : '251px'};
  }
`;

const HowText = styled.p`
  margin: 0;
  font-size: 1.04vw;
  line-height: 1.3vw;
  font-weight: 400;
  max-width: ${props => props.index < 3 ? '15.3vw' : '100%'};

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 19px;
    max-width: ${props => props.index < 3 ? '220px' : '100%'};
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    max-width: ${props => props.index < 3 ? '296px' : '100%'};
  }
`;

const HowIcon = styled.img`
  width: 4.17vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 60px;
  }

  @media screen and (max-width: 768px) {
    
  }
`;

const HowNumber = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #342A41;
  color: #fff;
  font-family: 'Paralucent';
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
`;

const WorksWrapper = styled.div`
  width: 100%;
  padding: 100px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .swiper {
    overflow: hidden;
    width: 100vw;
    padding: 0 1px;
    cursor: grab;
    position: relative;
  }

  .swiper-wrapper {
    display: flex;
    transition-timing-function : linear;
  }
`; 

// const ReviewsWrapper = styled.div`
//   width: 100%;
//   padding: 58px 0;
//   box-sizing: border-box;
//   display: flex;
//   flex-direction: column;
//   row-gap: 32px;
//   background: url('/creators/review-background.png');
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;

//   .swiper {
//     overflow: hidden;
//     width: 100vw;
//     padding: 0 1px;
//     cursor: grab;
//     position: relative;
//   }

//   .swiper-wrapper {
//     display: flex;
//     transition-timing-function : linear;
//   }

//   @media screen and (max-width: 768px) {  
//     background: url('/creators/review-background_mobile.png');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//   }
// `; 

const WorkCard = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 330px;
  height: 577px;
  border: 2px solid #342A41;
  border-radius: 16px;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 466px;
    width: 267px;
  }
`; 

const WorkVideo = styled.video`
  width: 100%;
  border-radius: inherit;
  cursor: pointer;
`;

// const ReviewCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   width: 450px;
//   height: 425px;
//   padding: 35px 27px 80px;
//   box-sizing: border-box;
//   background: url('/creators/review-chat.svg');
//   background-size: contain;
//   color: #342A41;
//   text-align: left;
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 24px;
//   transform: translate(0, 300px);
//   opacity: 0;
//   transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
//   transition-delay: ${props => `calc(${props.index}*0.1s)`};

//   p {
//     margin: 0;
//     letter-spacing: -0.0216em;
//   }

//   @media screen and (max-width: 768px) {
//     width: 265px;
//     height: 368px;
//     padding: 33px 16px 55px;
//     background: url('/creators/review-chat_mobile.svg');
//     font-size: 16px;
//     line-height: 19px;
//   }
// `;

// const ReviewText = styled.p`
//   width: 100%;
//   background: linear-gradient(91.25deg, #27F5B0 -20.05%, #6163F0 43.51%, #BD49FF 101.69%);
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   background-clip: text;
//   text-fill-color: transparent;
//   box-decoration-break: clone;
// 	-webkit-box-decoration-break: clone;
// `;

// const ReviewInfo = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   width: 100%;
// `;

// const ReviewPhoto = styled.img`
//   width: 47px;
//   height: 47px;

//   @media screen and (max-width: 768px) {
//     width: 45px;
//     height: 45px;
//   }
// `;

// const InfoWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const ReviewName = styled.h4`
//   font-size: 24px;
//   line-height: 28px;
//   font-weight: 500;
//   margin: 0;
//   font-family: 'Saira', sans-serif;
//   letter-spacing: -0.0216em;

//   @media screen and (max-width: 768px) {
//     font-size: 20px;
//     line-height: 23px;
//   }
// `;

const CreatorsHow = ({ isMobile }) => {

  const [initAutoplay, setInitAutoplay] = React.useState(false);

  const howCards = [
    {
      icon: '/creators/package.png',
      title: 'Escolha seu pacote',
      text: 'Identifique aquele que melhor te atende'
    },
    {
      icon: '/creators/brainstorm.png',
      title: 'Estratégia Criativa',
      text: 'Nossa equipe roteiriza os anúncios com ganchos atrativos.'
    },
    {
      icon: '/creators/video.png',
      title: 'Produção e Filmagem',
      text: 'Identificamos um(a) criador(a)  e solicitamos que grave baseado no roteiro.'
    },
    {
      icon: '/creators/design.png',
      title: 'Design focado em Conversão',
      text: 'Nossos designers e editores têm ampla experiência na confecção de criativos em vídeo 100% focados em vendas.'
    },
    {
      icon: '/creators/star.png',
      title: 'Revisão e Aprovação',
      text: 'É claro que seu criativo só vai pro ar mediante a sua aprovação. Relaxa.'
    },
    {
      icon: '/creators/sales.png',
      title: 'Anúncio pronto para vender',
      text: 'Prontinho! A criação do seu UGC foi concluída. Agora, é só aproveitar aquele barulhinho gostoso das vendas :)'
    }
  ];

  const workCards = [
    {
      preview: '/creators/preview-peppy.png',
      video: require("../assets/peppy.mp4")
    },
    {
      preview: '/creators/preview-vita.png',
      video: require("../assets/vita.mp4")
    },
    {
      preview: '/creators/preview-bready.png',
      video: require("../assets/bready.mp4")
    },
    {
      preview: '/creators/preview-mariapaixao.png',
      video: require("../assets/mariapaixao.mp4")
    },
    {
      preview: '/creators/preview-lovelife.png',
      video: require("../assets/lovelife.mp4")
    },
    {
      preview: '/creators/preview-peppy2.png',
      video: require("../assets/peppy2.mp4")
    },
    {
      preview: '/creators/preview-shopcao.png',
      video: require("../assets/shopcao.mp4")
    },
    {
      preview: '/creators/preview-terezas.png',
      video: require("../assets/terezas.mp4")
    },
    {
      preview: '/creators/preview-gioye.png',
      video: require("../assets/gioye.mp4")
    }
  ];

  // const reviewCards = [
  //   {
  //     text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
  //     image: '/creators/review-1.png',
  //     name: 'Nome Completo',
  //     job: 'CEO da Empresa X'
  //   },
  //   {
  //     text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
  //     image: '/creators/review-2.png',
  //     name: 'Nome Completo',
  //     job: 'CEO da Empresa X'
  //   },
  //   {
  //     text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
  //     image: '/creators/review-3.png',
  //     name: 'Nome Completo',
  //     job: 'CEO da Empresa X'
  //   },
  //   {
  //     text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
  //     image: '/creators/review-4.png',
  //     name: 'Nome Completo',
  //     job: 'CEO da Empresa X'
  //   },
  //   {
  //     text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
  //     image: '/creators/review-5.png',
  //     name: 'Nome Completo',
  //     job: 'CEO da Empresa X'
  //   }
  // ];

  function videosPause(e) {
    const videos = document.getElementsByClassName('video');    

    Array.from(videos).forEach((video) => {
      if(video !== e) {
        video.pause();
      } else {
        if(!video.controls) {
          video.setAttribute("controls", "true");
          video.play();
        }
      }
    })
  }

  function handleWindowScroll() {
    const nextSectionTop = document.getElementById('next-section').getBoundingClientRect().top;

    if(nextSectionTop < 0.9*window.innerHeight) {
      setInitAutoplay(true);
      document.getElementById('works-cards').style.transform = 'translateX(0)';
    }

    // const reviewCards = document.getElementsByClassName('review-cards');

    // Array.from(reviewCards).forEach((card) => {
    //   if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
    //     card.style.transform = 'translate(0, 0)';
    //     card.style.opacity = '1';
    //   }
    // })

    const howCards = document.getElementsByClassName('how-cards');

    Array.from(howCards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <SectionWrapper>
      <HowWrapper>
        <SectionTitle>
          Como funciona?
        </SectionTitle>
        <CardsWrapper className='page-width'>
          {howCards.map((card, index) => (
            <HowCard key={index} index={index} className="how-cards">
              <HowNumber>{index + 1}</HowNumber>
              <HowIcon src={card.icon}/>
              <HowTitle index={index} style={isMobile && index === 5 ? { width: '312px', maxWidth: 'unset' } : {}}>
                {index === 5 ? 
                  <GradientTitle>{card.title}</GradientTitle>
                : 
                  card.title
                } 
              </HowTitle>   
              <HowText index={index}>
                {index === 5 ? 
                  <GradientTitle style={{ fontWeight: 600 }}>{card.text}</GradientTitle>
                : 
                  card.text
                }
              </HowText>    
            </HowCard>
          ))}
        </CardsWrapper>
      </HowWrapper>
      <WorksWrapper id="next-section">
        <SectionTitle style={{ color: '#342A41' }}>
          Trabalhos Recentes
        </SectionTitle>
        <div style={{ height: isMobile ? '466px' : '576px' }}>
          {initAutoplay &&
            <Swiper
              modules={[Autoplay]}
              id="works-cards"
              spaceBetween={isMobile ? 24 : 30}
              slidesPerView={"auto"}
              autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
              freeMode={true}
              speed={isMobile ? 4000 : 2000}
              slidesOffsetBefore={isMobile ? 16 : (window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
              slidesOffsetAfter={isMobile ? 16 : (window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
              style={{ transform: 'translateX(100vw)', transition: 'all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88)' }}
            >
              {workCards.map((work, index) => (
                <SwiperSlide key={index}>
                  <WorkCard>
                    <WorkVideo preload='none' className="video" onClick={(e) => videosPause(e.currentTarget)} poster={work.preview}>
                      <source src={work.video} type="video/mp4"/>
                    </WorkVideo>
                  </WorkCard>
                </SwiperSlide>
              ))}
            </Swiper>
          }
        </div>
      </WorksWrapper>
      {/* <ReviewsWrapper>
        <SectionTitle>
          <GradientTitle>O que dizem de nós</GradientTitle> 👀
        </SectionTitle>
        <Swiper
          spaceBetween={isMobile ? 24 : 30}
          slidesPerView={"auto"}
          initialSlide={1}
          slidesOffsetBefore={isMobile ? 16 : (window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
          slidesOffsetAfter={isMobile ? 16 : (window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
        >
          {reviewCards.map((review, index) => (
            <SwiperSlide key={index}>
              <ReviewCard className="review-cards" index={index}>
                <ReviewText>{review.text}</ReviewText>
                <ReviewInfo>
                  <ReviewPhoto src={review.image}/>
                  <InfoWrapper>
                    <ReviewName>{review.name}</ReviewName>
                    <p>{review.job}</p>
                  </InfoWrapper>
                </ReviewInfo>
              </ReviewCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </ReviewsWrapper> */}
    </SectionWrapper>
  );
};

export default CreatorsHow;
