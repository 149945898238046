import React from 'react';
import styled from 'styled-components';

const ColumnWrapper = styled.div`
  width: calc((73.96vw - 90px)/4);
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media screen and (max-width: 1440px) {
    width: 245px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    row-gap: 30px;
    margin: 0 auto;
  }
`; 

const Title = styled.h3`
  font-size: 2.08vw;
  line-height: 2.55vw;
  font-weight: 700;
  letter-spacing: -0.0216em;
  text-align: left;  
  color: #FFFFFF;
  width: fit-content;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 36px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 49px;
  }
`; 

const Line = styled.div`
  background: transparent linear-gradient(90deg, #0E11DB 0%, #E15C83 100%) 0% 0% no-repeat padding-box;
  width: calc(100% - 30px);
  height: 5px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Text = styled.p`
  font-size: 1.04vw;
  line-height: 1.56vw;
  font-weight: 400;
  text-align: left;  
  color: #FFFFFF;
  margin: 10px 0 0 0;
  width: 100%;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 22px;
  }

@media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    margin: 0;
  }
`;


const NumbersColumn = ({ info, index }) => {

  function countTo() {
    let from = 0;
    let to = info.number;
    let step = 1;
    let interval = 2000/info.number;

    if(from === to) {
      document.getElementById('counter' + index).textContent = from;
      return;
    }

    let counter = setInterval(function(){
      from += step;
      document.getElementById('counter' + index).textContent = from;

      if(from === to) {
        clearInterval(counter)
      }
    }, interval);
  }

  function handleWindowScroll() {
    const counter = document.getElementById('counter' + index);
    const counterTop = counter.getBoundingClientRect().top;
    if (counterTop < 0.9*window.innerHeight && counter.textContent === ' ' ) {
      countTo();
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <ColumnWrapper>
      <Title>
        +{info.type === 'money' && 'R$'}<span id={'counter' + index}> </span> {info.unit}
      </Title>
      <Line> </Line>
      <Text>
        {info.text}
      </Text>
    </ColumnWrapper>
  );
};

export default NumbersColumn;

