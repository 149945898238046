import React from 'react';

const FooterIcon = ({ icon, fill }) => {
  
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {icon === 'tel' &&
        <path d="M16.1433 13.1339C16.0533 12.7049 15.7713 12.3209 15.3663 12.0809C14.6163 11.6399 13.8393 11.2139 13.0893 10.8029L12.5763 10.5209C11.9223 10.1639 11.4753 10.2299 11.2143 10.3499C10.9653 10.4639 10.6383 10.7369 10.4973 11.3939C10.4943 11.4029 10.4913 11.4149 10.4913 11.4269C10.4463 11.5979 10.3203 11.6669 10.2483 11.6939C10.0833 11.7509 9.88832 11.7119 9.7263 11.5859C8.47531 10.6019 7.39231 9.52191 6.41431 8.28891C6.28832 8.13291 6.24932 7.9409 6.3093 7.77591C6.33631 7.70091 6.41131 7.5659 6.62131 7.52091C6.6303 7.51791 6.64231 7.51791 6.6513 7.51491C7.31731 7.36791 7.58132 7.02591 7.68931 6.76491C7.80632 6.47091 7.8453 5.96692 7.3623 5.2409C6.99931 4.6979 6.69631 4.10391 6.4023 3.52791C6.26731 3.26691 6.13531 3.00592 5.99731 2.7479C5.72131 2.2379 5.3523 1.9199 4.93231 1.8269C4.5513 1.74591 4.14932 1.85391 3.76831 2.14191C3.39031 2.4299 2.9913 2.7479 2.61332 3.11391C1.80031 3.90592 1.61431 4.90791 2.0763 6.0089C4.03531 10.6979 7.41031 14.0489 12.1113 15.9719C12.4773 16.1249 12.8373 16.1999 13.1793 16.1999C13.7973 16.1999 14.3643 15.9539 14.8563 15.4679C15.2553 15.0749 15.6063 14.6219 15.9063 14.2229C16.1373 13.9079 16.2213 13.5209 16.1433 13.1339Z" fill={fill ? fill : '#B5B5B5'}/> 
      }
      {icon === 'email' &&
        <>
          <path d="M10.606 8.08208L16 11.4921V4.52808L10.606 8.08208Z" fill={fill ? fill : '#B5B5B5'}/>
          <path d="M0 4.52808V11.4921L5.394 8.08208L0 4.52808Z" fill={fill ? fill : '#B5B5B5'}/>
          <path d="M15 2.5H1.00003C0.501029 2.5 0.105029 2.872 0.0300293 3.351L8.00003 8.602L15.97 3.351C15.895 2.872 15.499 2.5 15 2.5Z" fill={fill ? fill : '#B5B5B5'}/>
          <path d="M9.68998 8.68606L8.27498 9.61806C8.19098 9.67306 8.09598 9.70006 7.99998 9.70006C7.90398 9.70006 7.80898 9.67306 7.72498 9.61806L6.30998 8.68506L0.0319824 12.6561C0.108982 13.1311 0.502982 13.5001 0.999982 13.5001H15C15.497 13.5001 15.891 13.1311 15.968 12.6561L9.68998 8.68606Z" fill={fill ? fill : '#B5B5B5'}/>
        </>
      }
    </svg>
  );
 };
 
 export default FooterIcon;
