import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';
import {ReactComponent as EngineImg} from '../assets/click-image.svg';

const pulse1 = keyframes`
  0% { transform: scale(1);}
  40% { transform: scale(1.4);}
  100% { transform: scale(1.4);}
`;

const pulse2 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  10% { transform: scale(1); opacity: 1; }
  20% { transform: scale(1.3); opacity: 0; }
  100% { transform: scale(1.3); opacity: 0; }
`;

const pulse3 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  18% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.25); opacity: 0; }
  100% { transform: scale(1.25); opacity: 0; }
`;

const pulse4 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  28% { transform: scale(1); opacity: 1; }
  40% { transform: scale(1.2); opacity: 0; }
  100% { transform: scale(1.2); opacity: 0; }
`;

const pulse5 = keyframes`
  0% { transform: scale(1);}
  40% { transform: scale(1.2);}
  100% { transform: scale(1.2);}
`;

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const TextWrapper = styled.div`
  width: ${props => props.width};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Transform3D = styled.div`
  position: absolute;
  top: ${props => props.item.top};
  right: ${props => props.item.right};
  left: ${props => props.item.left};
  bottom: ${props => props.item.bottom};
  width: ${props => props.item.width};
  height: ${props => props.item.height};
  z-index: ${props => props.index + 1};
  animation: ${pulse5} 2.5s ease-out infinite;
`

const ShapeImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => props.item.iconPosition};
  width: ${props => props.item.iconWidth};
  height: auto;
  z-index: ${props => props.index + 1};
`;

const Shape3D = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7.3px;
  transform: ${props => props.item.transform};
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: ${props => `blur(${props.item.blur})`};
  position: relative;
`;

const EngineWrapper = styled.div`
  padding: 100px 0 70px 0;

  .flex {
    padding: 80px 0 calc(80px + 5.5%);
  }

  .click-svg {
    z-index: 1;
    width: 100%;
    height: 100%;
    
    circle, path {
      animation: 2.5s ease-out infinite;
      transform-origin: center;
    }
  }

  .click-svg :nth-child(-n + 2) {
    animation-name: ${pulse1};
    animation-delay: -0.1s;
  }

  .click-svg :nth-child(3) {
    animation-name: ${pulse2};
  }

  .click-svg :nth-child(4) {
    animation-name: ${pulse3};
  }

  .click-svg :nth-child(5) {
    animation-name: ${pulse4};
  }

  .image-wrapper {
    position: relative;
    z-index: 0;
    margin: 0 -1.4% 0 -6.1%;
    width: 58.2%;
    height: 37.65%;
    perspective: 870px;
    perspective-origin: 51% 57%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      width: 43.3%;
      height: 67%;
      background: linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%);
      filter: blur(100px);
      z-index: -1;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 60px 0;

    .flex {
      padding: 0;
    }

    .image-wrapper {
      width: 115%;
      margin: 0 -2.7% 0 -12.3%;

      &:before {
        width: 43.3%;
        height: 67%;
        filter: blur(50px);
      }
    }
  }
`;

const CommerceCresc = ({ isMobile }) => {
  
  const cardsSVG = [
    {
      width: '20.2%',
      height: '32.9%',
      top: '31%',
      right: '8.1%',
      icon: '/commerce/card-icon-1.svg',
      iconWidth: '163%',
      iconPosition: 'translate(-19.3%,-18%)',
      blur: '9px',
      transform: 'rotateX(0deg) rotateY(357deg) rotateZ(34deg) skewX(10deg) skewY(6deg)'
    },
    { 
      width: '29.5%',
      height: '44.2%',
      top: '9.4%',
      right: '13.8%',
      icon: '/commerce/card-icon-2.svg',
      iconWidth: '105.8%',
      iconPosition: 'translate(-3.2%,-13.7%)',
      blur: '13px',
      transform: 'rotateX(347deg) rotateY(3deg) rotateZ(21deg) skewX(26deg) skewY(-1deg)'
    },
    {
      width: '16.5%',
      height: '26.7%',
      bottom: '26.7%',
      left: '20.8%',
      icon: '/commerce/card-icon-3.svg',
      iconWidth: '135%',
      iconPosition: 'translate(-13%,-14%)',
      blur: '8px',
      transform: 'rotateX(-8deg) rotateY(-1deg) rotateZ(10deg) skewX(5deg) skewY(11deg)'
    },
    {
      width: '19.5%',
      height: '32.7%',
      bottom: '12.7%',
      left: '21.8%',
      icon: '/commerce/card-icon-4.svg',
      iconWidth: '158%',
      iconPosition: 'translate(-18%,-16%)',
      blur: '9px',
      transform: 'rotateX(0deg) rotateY(360deg) rotateZ(329deg) skewX(363deg) skewY(13deg)'
    }
  ];

  const [,setInitAutoplay] = React.useState(false);

  function countTo() {
    let from = 0;
    let to = 70;
    let step = 1;
    let interval = 2000/70;

    if(from === to) {
      document.getElementById('counter').textContent = from;
      return;
    }

    let counter = setInterval(function(){
      from += step;
      document.getElementById('counter').textContent = from;

      if(from === to) {
        clearInterval(counter)
      }
    }, interval);
  }

  function handleEffects() {
    // Efeito aparecer carrossel
    const casesSectionTop = document.getElementById('cases-section').getBoundingClientRect().top;

    if (casesSectionTop <= 0.8 * window.innerHeight) {
      setInitAutoplay(true);
      if (!isMobile && document.getElementById('cases-cards')) {
        document.getElementById('cases-cards').style.transform = 'translateX(0)';
      }
    };

    const counter = document.getElementById('counter');
    const counterTop = counter.getBoundingClientRect().top;
    if (counterTop < 0.9*window.innerHeight && counter.textContent === ' ' ) {
      countTo();
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleEffects);

    return () => {
      window.removeEventListener('scroll', handleEffects);
    }
  });

  return (
    <PageWrapper className='flex column align-center'>
      <EngineWrapper className='page-width flex row gap-32 align-center justify-between wrap-reverse'>
        <TextWrapper width='44.4%' className='flex column gap-16 text-left'>
          <Title font={isMobile ? 28 : 40}>
            Faça da internet o principal <GradientText background='linear-gradient(90.8deg, #4100C8 -16.08%, #C052F3 85.96%)'>motor de crescimento</GradientText> do seu negócio
          </Title>
          <Subtitle font={isMobile ? 16 : 20}>
            Leve sua marca para onde está a atenção das pessoas: a ”rua” mais movimentada do mundo.
          </Subtitle>
          <ContactButton className="change-link" icon="arrow" width={321} height={60} link='#redirect' solidButton={true} text="Turbine minhas vendas" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '16px', width: isMobile && '100%' }}/>
        </TextWrapper>
        <div className='image-wrapper'>
          <EngineImg className='click-svg'/>
          {cardsSVG.map((item, index) => (
            <Transform3D item={item} index={index}>
              <ShapeImg src={item.icon} item={item} index={index}/>    
              <Shape3D item={item}/>
            </Transform3D>
          ))}
        </div>
      </EngineWrapper>
    </PageWrapper>
  );
};

export default CommerceCresc;
