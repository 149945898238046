import React from 'react';
import styled, { keyframes } from 'styled-components';


const marqueeAnimationLeft = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-33.33%); }
`;

const marqueeAnimationRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(33.33%); }
`;

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const CompaniesWrapper = styled.div`
  gap: 74px;
  padding: 100px 0 0 0;

  @media screen and (max-width: 768px) {
    padding: 60px 0 34px;
    gap: 61px;
    max-width: 420px;
  }
`;

const CompaniesContainer = styled.div`
  position: relative;
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 119px;
  overflow-x: hidden;
  background: rgba(13, 3, 26, 0.5);
  border-top: 1px solid #1F1C3C;
  border-bottom: 1px solid #1F1C3C;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.left ? 'start' : 'end')};
  z-index: 2;

  @media screen and (max-width: 768px) {
    height: 58px;
  }
`;

const MarqueeTrackLeft = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationLeft} 16s linear infinite;
`;

const MarqueeTrackRight = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationRight} 16s linear infinite;
`;

const GradientEffect = styled.div`
  width: 27.3vw;
  height: 391px;
  position: absolute;
  top: -14px;
  left: ${props => props.right ? 'unset' : 0};
  right: ${props => props.right ? 0 : 'unset'};
  transform: ${props => props.right ? 'rotate(180deg)' : 'unset'};
  z-index: 5;
  background: linear-gradient(90deg, #08031A 0%, #08031A 55%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: max(calc(160px + (100vw - 1065px)/2), 210px);
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 180px;
    top: 0;
    background: linear-gradient(90deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);
  }
`;

const LogoWrapper = styled.a`
  height: 65px;
  padding: 0 40px;
  border-right: 1px solid #1F1C3C;

  @media screen and (max-width: 768px) {
    padding: 0 26px;
    height: 39px;
  }
`;

const CommerceClientes = ({ isMobile }) => {
  
  const companies = [
    [
      {
        desktop: '/logo_brota.png',
        mobile: '/logo-mobile_brota.png',
        url: 'https://brotasubitem.com.br/'
      },
      {
        desktop: '/logo_soul-zen.png',
        mobile: '/logo-mobile_soul-zen.png',
        url: 'https://soulzenshop.com/'
      },
      {
        desktop: '/logo_martha-campos.png',
        mobile: '/logo-mobile_martha-campos.png',
        url: 'https://shopmarthacampos.com.br/'
      },
      {
        desktop: '/logo_vividus.png',
        mobile: '/logo-mobile_vividus.png',
        url: 'https://www.vividus.com.br/'
      },
      {
        desktop: '/logo_nutsco.png',
        mobile: '/logo-mobile_nutsco.png',
        url: 'https://nutsco.com/'
      },
      {
        desktop: '/logo_grupo-v3.png',
        mobile: '/logo-mobile_grupo-v3.png',
        url: 'https://v3grupo.com.br/'
      }
    ],
    [
      {
        desktop: '/logo_luma.png',
        mobile: '/logo-mobile_luma.png',
        url: 'https://lumaensino.com.br/'
      },
      {
        desktop: '/logo_byr.png',
        mobile: '/logo-mobile_byr.png',
        url: 'https://lojabyr.com.br/'
      },
      {
        desktop: '/logo_it-style.png',
        mobile: '/logo-mobile_it-style.png',
        url: 'https://itstyle.store/'
      },
      {
        desktop: '/logo_ninasaúde.png',
        mobile: '/logo-mobile_ninasaúde.png',
        url: 'https://ninasaude.com.br/'
      },
      {
        desktop: '/logo_get-power.png',
        mobile: '/logo-mobile_get-power.png',
        url: 'https://getpowersolar.com.br/'
      },
      {
        desktop: '/logo_cale.png',
        mobile: '/logo-mobile_cale.png',
        url: 'https://ecale.com.br/'
      },
      {
        desktop: '/logo_peppy-berry.png',
        mobile: '/logo-mobile_peppy-berry.png',
        url: 'https://peppyberry.com.br/'
      }
    ],
    [
      {
        desktop: '/logo_gula-fitfood.png',
        mobile: '/logo-mobile_gula-fitfood.png',
        url: 'https://gulafit.com.br/'
      },
      {
        desktop: '/logo_bready.png',
        mobile: '/logo-mobile_bready.png',
        url: 'https://meubready.com.br/'
      },
      {
        desktop: '/logo_total-health.png',
        mobile: '/logo-mobile_total-health.png',
        url: '/'
      },
      {
        desktop: '/logo_tucan.png',
        mobile: '/logo-mobile_tucan.png',
        url: 'https://tucan.coffee/'
      },
      {
        desktop: '/logo_divn.png',
        mobile: '/logo-mobile_divn.png',
        url: 'https://divn.com.br/'
      },
      {
        desktop: '/logo_cooltiva.png',
        mobile: '/logo-mobile_cooltiva.png',
        url: 'https://cooltiva.com.br/'
      },
      {
        desktop: '/logo_gkbrand.png',
        mobile: '/logo-mobile_gkbrand.png',
        url: 'https://gkbrand.com.br/'
      }
    ]
  ];

  return (
    <PageWrapper className='flex column align-center'>
      <CompaniesWrapper className='flex column align-center'>
        <Title font={isMobile ? 40 : 64} width={isMobile ? null : 846}>
          + de <GradientText background='linear-gradient(90.15deg, #4100C8 18.1%, #C052F3 72.2%)'>R$<span id='counter'> </span> milhões</GradientText> em vendas para nossos clientes 
        </Title>
        <CompaniesContainer>
          <GradientEffect/>
          {companies.map((item, index) => (
            <MarqueeWrapper left={index !== 1} key={index}>
              {index === 1 ? 
                <MarqueeTrackRight>
                  <div className='flex row align-center'>
                    {[...Array(3)].map((e, i) => (
                      <div key={i} className='flex row align-center'>
                        {item.map((subitem, idx) => (
                          <LogoWrapper className='flex align-center' href={subitem.url} key={idx} target="_blank">
                            <img src={isMobile ? subitem.mobile : subitem.desktop} alt=""/>
                          </LogoWrapper>
                        ))}
                      </div>
                    ))}
                  </div>
                </MarqueeTrackRight>  
              :
                <MarqueeTrackLeft>
                  <div className='flex row align-center'>
                    {[...Array(3)].map((e, i) => (
                      <div key={i} className='flex row align-center'>
                        {item.map((subitem, idx) => (
                          <LogoWrapper className='flex align-center' href={subitem.url} key={idx} target="_blank">
                            <img src={isMobile ? subitem.mobile : subitem.desktop} alt=""/>
                          </LogoWrapper>
                        ))}
                      </div>
                    ))}
                  </div>
                </MarqueeTrackLeft>    
              }
            </MarqueeWrapper>
          ))}
          <GradientEffect right={true}/>
        </CompaniesContainer>
      </CompaniesWrapper>
    </PageWrapper>
  );
};

export default CommerceClientes;
