import React from 'react';
import styled, { keyframes } from 'styled-components';

const marqueeAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: ${props => `${props.height}px`};
  overflow-x: hidden;
  background: ${props => props.background ? props.background : 'unset'};
  border-top: ${props => props.border ? props.border : 'unset'};
  border-bottom: ${props => props.border ? props.border : 'unset'};
  display: flex;
  align-items: center;
  margin-top: ${props => props.marginTop ? props.marginTop : 'unset'};
  z-index: 5;
`;

const MarqueeTrack = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimation} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 65px;
  padding: ${props => props.spaceBetween ? `0 ${props.spaceBetween/2}px` : '0 40px'};
  border-right: ${props => props.borderBetween ? props.borderBetween : 'unset'};

  @media screen and (max-width: 768px) {
    height: 38px;
  }
`;

const PartnersLogo = styled.img`
  height: auto;
  width: auto;
`;

const ImageMarquee = ({ logos, background, border, spaceBetween, borderBetween, height, marginTop, isMobile }) => {

  return (
    <MarqueeWrapper background={background} border={border} height={height} marginTop={marginTop}>
      <MarqueeTrack>
        <MarqueeContent>
          {logos.map((logo, index) => (
            <LogoWrapper href={logo.url} key={index} spaceBetween={spaceBetween} borderBetween={borderBetween}>
              <PartnersLogo src={isMobile ? logo.mobile : logo.desktop} />  
            </LogoWrapper>
          ))}
          {logos.map((logo, index) => (
            <LogoWrapper href={logo.url} key={index} spaceBetween={spaceBetween} borderBetween={borderBetween}>
              <PartnersLogo src={isMobile ? logo.mobile : logo.desktop} />  
            </LogoWrapper>
          ))}
        </MarqueeContent>
      </MarqueeTrack>
    </MarqueeWrapper>
  );
};

export default ImageMarquee;
    