import React from 'react';
import Header from '../sections/Header';
import CommerceIntro from '../sections/CommerceIntro';
import CommerceQuadros from '../sections/CommerceQuadros';
import CommerceMotor from '../sections/CommerceMotor';
import ImageMarquee from '../components/ImageMarquee';
import CommerceCards from '../sections/CommerceCards';
import CommerceClientes from '../sections/CommerceClientes';
import CommerceGanhos from '../sections/CommerceGanhos';
import CommerceCases from '../sections/CommerceCases';
import CommerceComments from '../sections/CommerceComents';
import CommerceCresc from '../sections/CommerceCresc';
import CommerceCancele from '../sections/CommerceCancele';
import CommerceFAQ from '../sections/CommerceFAQ';
import Footer from '../sections/Footer';
import { Helmet } from 'react-helmet';

const LPCommerce2 = ({ isMobile, isTablet }) => {

  const logos = [
    {
      desktop: '/logo_shopify.png',
      mobile: '/logo-mobile_shopify.png',
      url: 'https://www.shopify.com/br/parcerias'
    },
    {
      desktop: '/logo_tiktok.png',
      mobile: '/logo-mobile_tiktok.png',
      url: 'https://www.tiktok.com/business/pt-BR'
    },
    {
      desktop: '/logo_google.png',
      mobile: '/logo-mobile_google.png',
      url: 'https://www.google.com/intl/pt-BR/partners/become-a-partner/'
    },
    {
      desktop: '/logo_meta.png',
      mobile: '/logo-mobile_meta.png',
      url: 'https://www.facebook.com/business/marketing-partners/become-a-partner'
    },
    {
      desktop: '/logo_pagarme.png',
      mobile: '/logo-mobile_pagarme.png',
      url: 'https://pagar.me/'
    }
  ];

  return (
    <div style={{ backgroundColor: '#0D031A' }}>
      <Helmet>
        <title>Turbo Commerce</title>
        <meta name="description" content="Turbine o faturamento do seu negócio com o poder da internet"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <CommerceIntro isMobile={isMobile} />
      <ImageMarquee logos={logos} isMobile={isMobile}  background={isMobile ? 'rgba(13, 3, 26, 0.5)' : '#0D031A'} border={'1px solid #1B2F4A'} borderBetween={'1px solid #1B2F4A'} spaceBetween={isMobile ? 52 : 80} height={isMobile ? 70 : 121} marginTop={'-2.3vw'}/>
      <CommerceCards isMobile={isMobile} />
      <CommerceClientes isMobile={isMobile} />
      <CommerceGanhos isMobile={isMobile} />
      <CommerceQuadros isMobile={isMobile} />
      <CommerceCases isMobile={isMobile} />
      <CommerceMotor isMobile={isMobile} />
      <CommerceComments isMobile={isMobile} />
      <CommerceCresc isMobile={isMobile} />
      <CommerceCancele isMobile={isMobile} />
      <CommerceFAQ isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </div>
  )
};

export default LPCommerce2;
