import React from 'react';
import styled, {keyframes} from 'styled-components';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
  0%, 100% {
    transform: translate(0, -50px)
  }
  50% {
    transform: translate(0, 50px)
  }
`;

const ImpactWrapper = styled.div`
  background: ${props => `url(${props.background})`};
  background-size: contain;
  width: 100vw;
  height: 13.75vw;
  text-align: center;
  padding: 2.5vw 0 0 0;
  font-size: 6.67vw;
  line-height: 6.98vw;
  font-weight: 200;
  color: #ffffff;
  font-family: 'Saira', sans-serif;
  box-sizing: border-box;
  margin: 14.58vw 0;

  @media screen and (max-width: 1440px) {
    padding-top: 36px;
    margin: 210px 0;
    font-size: 96px;
    line-height: 100px;
    height: 200px;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 768px) {
    margin: 100px 0;
    padding-top: 10px;
    font-size: 48px;
    line-height: 50px;
    height: 88px;
  }
`;

const JourneyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.2vw;

  @media screen and (max-width: 1440px) {
    gap: 60px;
  }

  @media screen and (max-width: 768px) {
    gap: 46px;
  }
`;

const ReviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding: 369px 0 145px;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    padding: 277px 0 136px;
  }

  @media screen and (max-width: 768px) {
    padding: 231px 0 79px;
  }
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.59vw;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.015em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 43.3vw;
  gap: 24px;
  
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 52px;
    max-width: 624px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    gap: 18px;
  }
`;

const PhasesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 20px 0;

  @media screen and (max-width: 768px) {
    gap: 38px;
    justify-content: center;
    margin-top: 54px;
    max-width: 600px;
  }
`;

const PhasesContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.order === 3 || props.order === 6) ? '25%' : '37.3%'};
  flex-direction: ${props => (props.order === 3 || props.order === 6) ? 'column' : 'row'};
  align-items: center;
  justify-content: start; 
  margin-bottom: ${props => props.order < 3 ? 'auto' : '0'};
  order: ${props => props.order};

  @media screen and (max-width: 768px) {
    width: 74%;
    min-width: 248px;
    max-width: 390px;
    gap: 38px;
    flex-direction: column;
    order: unset;
  }
`;

const PhasesCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 16px;
  width: ${props => (props.order === 3 || props.order === 6) ? '100%' : 'calc(100% - 7.81vw - 25px)'};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Arrow = styled.img`
  width: ${props => props.order === 3 ? 'auto' : '7.81vw'};
  height: ${props => props.order === 3 ? '5.625vw' : 'auto'};
  margin: ${props => props.order === 3 ? '10px 0 0 -140px' : '0 0 0 -30px'};
  transform: ${props => props.order > 3 ? 'rotate(180deg)' : ''};

  @media screen and (max-width: 1165px) {
    width: ${props => props.order === 3 ? 'auto' : '90px'};
    height: ${props => props.order === 3 ? '73px' : 'auto'};
    margin-left: ${props => props.order > 3 ? '16px' : ''};
  }

  @media screen and (max-width: 768px) {
    width: 16px;
    height: auto;
    margin: 0;
    transform: unset;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(-90deg, #4AA6F7 -20%, #1141BD 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`; 

const Subtitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.015em;
  color: #B8B8B8;
  text-align: center;
  
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${props => `calc(${props.width}*100vw/1920)`};
  height: ${props => props.order < 4 ? 'calc(105*100vw/1920)' : 'calc(81*100vw/1920)'};

  @media screen and (max-width: 1440px) {
    width: ${props => `calc(${props.width}px*0.75)`};
    height: ${props => props.order < 4 ? 'calc(105px*0.75)' : 'calc(81px*0.75)'};
  }

  @media screen and (max-width: 768px) {
    width: ${props => `${props.width}px`};
    height: auto;
  }
`;

const Icon = styled.img`
  width: 100%;
  height: auto;
`;  

const Stars = styled.img`
  width: 12.24vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 177px;
  }
`;  

const PhasesTitle = styled.p`
  font-size: 1.67vw;
  line-height: 1.77vw;
  font-family: 'Saira', sans-serif;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-align: left;
  max-width: 17.2vw;
  min-height: 2.5vw;

  background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 26px;
    max-width: 248px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const PhasesText = styled.p`
  font-size: 1.04vw;
  line-height: 1.46vw;
  margin: 0;
  color: #DEDEDE;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-align: left;
  max-width: 17.2vw;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 21px;
    max-width: 248px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 28px;
    max-width: 100%;
  }
`; 

const SuperpowerImage = styled.img`
  width: 6.04vw;
  height: auto;
  position: absolute;
  top: ${props => `calc(${props.position.top}*100vw/1920)`};
  left: ${props => props.position.left && `calc(${props.position.left}*100vw/1920)`};
  right: ${props => props.position.right && `calc(${props.position.right}*100vw/1920)`};
  animation: ${floatation} 8s ease-in-out infinite;
  animation-delay: ${props => `calc(${props.index}*1s)`};

  @media screen and (max-width: 1440px) {
    width: 87px;
    top: ${props => `calc(${props.position.top}px*0.75)`};
    left: ${props => props.position.left && `calc(${props.position.left}px*0.75)`};
    right: ${props => props.position.right && `calc(${props.position.right}px*0.75)`};  
  }

  @media screen and (max-width: 768px) {
    width: 80px;
    top: ${props => `${props.position.top}px`};
    left: ${props => props.position.left && `${props.position.left}px`};
    right: ${props => props.position.right && `${props.position.right}px`};  
  }
`;

const ReviewContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; 
  gap: 30px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc((100% - 60px)/3);
  box-sizing: border-box;
  padding: 1.875vw 1.4vw;
  background: rgba(45, 35, 56, 0.1);
  border: 2px solid #12192B;
  border-radius: 8px;
  gap: 38px;
  font-weight: 400;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: -0.0216em;
  text-align: left;

  p {
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    padding: 27px 20px; 
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 36px 28px; 
    font-size: 16px;
    line-height: 19px;
    gap: 48px;
    margin: 0 auto;
  }
`;

const ReviewText = styled.p`
  width: 100%;
  background: linear-gradient(259.7deg, #4AA6F7 17.74%, #4AA6F7 28.03%, #1141BD 121.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const ReviewPhoto = styled.img`
  width: 44px;
  height: 44px;

  @media screen and (max-width: 768px) {
    width: 51px;
    height: 51px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ReviewName = styled.h4`
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 500;
  color: #ffffff;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

const TechImpact = ({ isMobile }) => {

  const phases = [
    {
      icon: '/phases-character.png',
      title: 'Escolha seu personagem',
      text: 'Escolha entre Landing Page, Website ou E-Commerce',
      width: 105,
      order: 1
    },
    {
      icon: '/phases-prototyping.png',
      title: 'Etapa de Prototipagem',
      text: 'Nesta fase, nossos copywriters e UX Writers vão desenvolver a estrutura textual do site',
      width: 93,
      order: 2
    },
    {
      icon: '/phases-design.png',
      title: 'UI/UX Design',
      text: 'Tendo os textos em mãos, o nosso time de UI/UX construirá o visual do site',
      width: 102,
      order: 3
    },
    {
      icon: '/phases-development.png',
      title: 'Desenvolvimento',
      text: 'Em seguida, nossos desenvolvedores darão vida ao protótipo de alta-fidelidade',
      width: 72,
      order: 6
    },
    {
      icon: '/phases-bugs.png',
      title: 'Integrações e correção de bugs',
      text: 'Nesta fase, são realizados os ajustes finais do projeto',
      width: 70,
      order: 5
    },
    {
      icon: '/phases-complete.png',
      title: 'Mission complete',
      text: 'Seu site tá pronto! Agora, é hora de curtir o barulhino das vendas ;)',
      width: 81,
      order: 4
    }
  ];

  const superpowers = [
    {
      image: '/superpowers-1.png',
      1920: {
        left: 311,
        top: 224
      },
      390: {
        left: -23,
        top: 91
      }
    },    {
      image: '/superpowers-2.png',
      1920: {
        left: 815,
        top: 183
      },
      390: {
        left: 113,
        top: 144
      }
    },
    {
      image: '/superpowers-3.png',
      1920: {
        left: 186,
        top: 456
      },
      390: {
        left: 0,
        top: 388
      }
    },
    {
      image: '/superpowers-4.png',
      1920: {
        left: 544,
        top: 696
      },
      390: {
        left: 73,
        top: 524
      }
    },
    {
      image: '/superpowers-5.png',
      1920: {
        top: 600,
        right: 550
      },
      390: {
        top: 470,
        right: 65
      }
    },
    {
      image: '/superpowers-6.png',
      1920: {
        top: 479,
        right: 263
      },
      390: {
        top: 367,
        right: -28
      }
    },
    {
      image: '/superpowers-7.png',
      1920: {
        top: 309,
        right: 493
      },
      390: {
        top: 133,
        right: -20
      }
    },
    {
      image: '/superpowers-8.png',
      1920: {
        top: 218,
        right: 130
      },
      390: {
        top: 55,
        right: 85
      }
    }
  ];

  const reviews = [
    {
      text: 'O time da Turbo fez um trabalho absolutamente incrível quando construiu nosso site. Tanto a home page quanto a product page ficaram lindas! E conseguimos uma taxa de conversão média de 2,36%.',
      image: '/review-1.png',
      name: 'Pedro Menezes',
      job: 'CEO da Bready'
    },
    {
      text: 'A Turbo foi fundamental no processo de escala dos nossos infoprodutos. Toda a expertise deles no digital, em tráfego, copy, design, desenvolvimento... definitivamente elevou o nível do nosso negócio.',
      image: '/review-2.png',
      name: 'Augusto Chieppe',
      job: 'CEO da Academia do Sol'
    },
    {
      text: 'Nosso site ficou muito mais intuitivo e rápido! O cliente consegue navegar rapidamente facilitando a finalização da compra. Nossa taxa de conversão tem evoluido ✨',
      image: '/review-3.png',
      name: 'Lara Fernandes',
      job: 'CEO da Calê'
    }
  ];

  return (
    <>
      <ImpactWrapper background={'/waves.png'}>
        Impacto vende
      </ImpactWrapper>
      <JourneyWrapper className="page-width">
        <Title>
          As fases da sua jornada
        </Title>
        <PhasesWrapper>
          {phases.map((phase, index) => (
            <PhasesContent order={phase.order} key={index}>
              <PhasesCard order={phase.order}>
                <IconWrapper width={phase.width} order={phase.order}>
                  <Icon src={phase.icon}/>
                </IconWrapper>
                <PhasesTitle>{phase.title}</PhasesTitle>
                <PhasesText>{phase.text}</PhasesText>
              </PhasesCard>
              {(isMobile ? (index !== phases.length - 1) : (phase.order !== phases.length)) && 
                <Arrow src={isMobile ? '/arrow-down_mobile.png' : (phase.order === 3 ? '/arrow-down.png' : '/arrow-right.png')}  order={phase.order}/>
              }
            </PhasesContent>
          ))}
        </PhasesWrapper>
        <ContactButton width={235} height={64} link='https://form.respondi.app/SOMTDxh7' target="_blank" text='Começar agora' fontSize={20} fontWeight={500} solidButton={true} background='linear-gradient(263.91deg, #4AA6F7 -35.57%, #4AA6F7 -22.82%, #1141BD 92.97%)' shadow='0px 4px 30px rgba(0, 136, 255, 0.38)'/>
      </JourneyWrapper>
      <ReviewWrapper className='page-width'>
        <Title>
          <div>
            Com <GradientTitle>superpoderes</GradientTitle>, vêm superresponsabilidades
          </div>
          <Subtitle>
            Esteja preparado para atender às expectativas de toda essa galera.
          </Subtitle>
          <Stars src={'/superpower-stars.png'}/>
          <Arrow src={'/arrow-down.png'} order={3} style={{ margin: isMobile ? '16px 0 98px 0' : '18px 0 72px 0' }}/>
        </Title>
        {superpowers.map((superpower, index) => (
          <SuperpowerImage index={index} key={index} src={superpower.image} position={isMobile ? superpower[390] : superpower[1920]}/>
        ))}
        <ReviewContent>
          {reviews.map((review, index) => (
            <ReviewCard key={index}>
              <ReviewText>{review.text}</ReviewText>
              <ReviewInfo>
                <ReviewPhoto src={review.image}/>
                <InfoWrapper>
                  <ReviewName>{review.name}</ReviewName>
                  <p>{review.job}</p>
                </InfoWrapper>
              </ReviewInfo>
            </ReviewCard>
          ))}
        </ReviewContent>
      </ReviewWrapper>
    </>
  );
};

export default TechImpact;
