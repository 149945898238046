import React from 'react';
import Header from '../sections/Header';
import TalentsHero from '../sections/TalentsHero';
import TalentsContract from '../sections/TalentsContract';
import TeamTurbo from '../sections/TeamTurbo';
import Footer from '../sections/Footer';
import { Helmet } from 'react-helmet';

const Talents = ({ isMobile, isTablet }) => {
  return (
    <div style={{ backgroundColor: '#08031A', overflow: 'hidden' }}>
      <Helmet>
        <title>Faça parte do time</title>
        <meta name="description" content="Participe do nosso processo seletivo e entre para o foguete Turbo"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <TalentsHero isMobile={isMobile} />
      <TalentsContract isMobile={isMobile} />
      <TeamTurbo isMobile={isMobile} />
      <Footer isMobile={isMobile}  background={'#060613'}/>
    </div>
  )
};

export default Talents;
