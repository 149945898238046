import React from 'react';
import styled, { keyframes } from 'styled-components';
import ImageMarquee from '../components/ImageMarquee';
import ContactButton from '../components/ContactButton';

const HeroContainer = styled.div`
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 153px;

  @media screen and (max-width: 768px) {
    padding-bottom: 80px;
  }
`; 

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 16px;
  padding-top: 162px;
  z-index: 2;
  font-family: 'Montserrat', sans serif;
  width: 29.69vw;
  
  @media screen and (max-width: 1440px) {
    width: 428px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-top: 436px;
    box-sizing: border-box;
    align-items: center;
  }
`;

const BrandRow = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  z-index: 2;

  @media screen and (max-width: 768px) {
    column-gap: 7px;
  }
`;

const BrandIcon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

const GradientText = styled.span`
  background: ${props => props.index === 2 ? 'linear-gradient(89.74deg, #27F5B0 -50.22%, #6163F0 0%, #BD49FF 99.77%)' : 'linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`;

const BrandName = styled.h2`
  color: #342A41;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.51vw;
  margin: 0;

  span {
    font-weight: 400;
  }

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Title = styled.h2`
  font-size: 3.33vw;
  line-height: 3.9vw;
  font-weight: 700;
  font-family: 'Paralucent';
  width: 100%;
  color: #342A41;
  margin: 0 0 -10px 0;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 1.04vw;
  line-height: 1.25vw;
  font-weight: 400;
  width: fit-content;
  max-width: 90%;
  margin: 0;
  color: #342A41;
  z-index: 2;
  border-top: 1px solid #342A41;
  padding: 16px 0;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const HeroImg = styled.div`
  position: absolute;
  top: 0;
  right: -13.02vw;
  height: 43.18vw;
  width: 54.79vw;
  display: flex;
  gap: 1.72vw;
  padding-left: 6.25vw;
  box-sizing: border-box;
  z-index: 1;
  overflow: hidden;
  background: url('/creators/hero.png') no-repeat;
  background-size: cover;

  @media screen and (max-width: 1440px) {
    right: -187px;
    height: 621px;
    width: 789px;
    gap: 25px;
    padding-left: 90px;
  }

  @media screen and (max-width: 768px) {
    right: 50%;
    transform: translateX(50%);
    width: 390px;
    height: 401px;
    background: url('/creators/hero_mobile.png') no-repeat;
    padding: 0;
    justify-content: center;
    gap: 12px; 
  }
`;

const GifWrapper = styled.div`
  padding: 2px;
  display: flex;
  background: linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  border-radius: 16px;

  @media screen and (max-width: 768px) {
    border-radius: 6px;
  }
`;

const HeroGif = styled.img`
  height: 29.9vw;
  width: 16.81vw;
  z-index: 2;
  border-radius: 16px;

  @media screen and (max-width: 1440px) {
    height: 431px;
    width: 242px;
  }

  @media screen and (max-width: 768px) {
    height: 231px;
    width: 130px;
    border-radius: 6px;
  }
`;

const SocialRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 60px;
  width: 100%;
  margin-top: 42px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    column-gap: 42px;
    margin-top: -24px;
    margin-bottom: 8px;
  }
`;

const SocialImg = styled.img`
  
  @media screen and (max-width: 768px) {
    transform: scale(0.7);
  }
`;

const PartnersBanner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 458px;
  box-sizing: border-box;
  padding-top: 50px;
  background: linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  margin-bottom: 246px;

  @media screen and (max-width: 768px) {
    padding-top: 44px;
    height: 324px;
    margin-bottom: 270px;
    background: linear-gradient(72.5deg, #27F5B0 -46.61%, #6163F0 43.84%, #BD49FF 126.62%);
  }
`;

const SectionTitle = styled.h3`
  margin: 0 0 16px 0;
  font-size: 2.5vw;
  line-height: 2.92vw;
  font-weight: 500;
  font-family: 'Paralucent';
  text-align: center;
  width: 100%;
  color: #fff;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
    padding: 0 34px;
    box-sizing: border-box;
  }
`;

const SectionSubtitle = styled.p`
  margin: 0 0 30px 0;
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: #342A41;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const PartnersRow = styled.div`
  padding: 0 1.35vw 50px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;

  @media screen and (max-width: 1165px) {
    padding: 0 0 50px;
  }

  @media screen and (max-width: 768px) {
    height: 49px;
    padding: 0 0 35px 0;
  }
`;

const Row = styled.div`
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    height: 49px;
  }
`;

const PartnerImg = styled.img`
  width: ${props => `calc(${props.width}*100vw/1920)`};
  height: auto;

  @media screen and (max-width: 1440px) {
    width: ${props => `calc(${props.width}px*0.75)`};
  }
`;

const StrategyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 92px 177px;
  margin-bottom: -214px;

  @media screen and (max-width: 768px) {
    padding: 30px 15px;
    margin-bottom: -223px;
  }
`;

const BlurEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.34);
  backdrop-filter: blur(157px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
`;

const marqueeAnimation = keyframes`
  from { transform: translateY(0); }
  to { transform: translateY(-50%); }
`;

const MarqueeWrapper = styled.div`
  position: relative;
  padding: 0 2px;
  width: 16.88vw;
  height: 43.18vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 243px;
    height: 621px;
  }

  @media screen and (max-width: 768px) {
    width: 131px;
    height: 401px;
  }
`;

const MarqueeTrack = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimation} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const CreatorsHero = ({ isMobile }) => {

  const socials = [
    {
      img: '/creators/meta.svg',
      url: ''
    },
    {
      img: '/creators/tiktok.svg',
      url: ''
    },
    {
      img: '/creators/snapchat.svg',
      url: ''
    },
    {
      img: '/creators/youtube.svg',
      url: ''
    }
  ];

  const partners = [
    {
      mobile: '/creators/lzfitness_mobile.png',
      desktop: '/creators/lzfitness.png',
      url: '',
      width: 69
    },
    {
      mobile: '/creators/refrescadog_mobile.png',
      desktop: '/creators/refrescadog.png',
      url: '',
      width: 146
    },
    {
      mobile: '/creators/bready_mobile.png',
      desktop: '/creators/bready.png',
      url: '',
      width: 113
    },
    {
      mobile: '/creators/marthacampos_mobile.png',
      desktop: '/creators/marthacampos.png',
      url: '',
      width: 137
    },
    {
      mobile: '/creators/gkbrand_mobile.png',
      desktop: '/creators/gkbrand.png',
      url: '',
      width: 203
    },
    {
      mobile: '/creators/gioye_mobile.png',
      desktop: '/creators/gioye.png',
      url: '',
      width: 140
    },
    {
      mobile: '/creators/terezas_mobile.png',
      desktop: '/creators/terezas.png',
      url: '',
      width: 114
    },
    {
      mobile: '/creators/mariapaixao_mobile.png',
      desktop: '/creators/mariapaixao.png',
      url: '',
      width: 174
    }
  ];

  const gifs = [
    [
      {
        mobile: require('../assets/bready_mobile.gif'),
        desktop: require('../assets/bready.gif')
      },
      {
        mobile: require('../assets/lovelife_mobile.gif'),
        desktop: require('../assets/lovelife.gif')
      },
      {
        mobile: require('../assets/peppy_mobile.gif'),
        desktop: require('../assets/peppy.gif')
      },
      {
        mobile: require('../assets/peppy2_mobile.gif'),
        desktop: require('../assets/peppy2.gif')
      }
    ],
    [
      {
        mobile: require('../assets/shopcao_mobile.gif'),
        desktop: require('../assets/shopcao.gif')
      },
      {
        mobile: require('../assets/terezas_mobile.gif'),
        desktop: require('../assets/terezas.gif')
      },
      {
        mobile: require('../assets/vita_mobile.gif'),
        desktop: require('../assets/vita.gif')
      },
      {
        mobile: require('../assets/mariapaixao_mobile.gif'),
        desktop: require('../assets/mariapaixao.gif')
      }
    ]
  ]

  return (
    <>
      <HeroContainer className="page-width">  
        <HeroWrapper>
          <TextWrapper>
            <BrandRow>
              <BrandIcon src={'/creators/TurboCreators_new.svg'}/>
              <BrandName>
                Turbo <GradientText>Creators</GradientText>
              </BrandName>
            </BrandRow>
            <Title>
              Faça seu CPA <GradientText>despencar</GradientText> com anúncios <GradientText index={2}>sem</GradientText> cara de anúncios
            </Title>
            <Subtitle>
              Ajudamos marcas a maximizarem o Retorno Sobre Investimento em tráfego
            </Subtitle>
            {isMobile &&
              <SocialRow>
                {socials.map((social, index) => (
                  <a href={social.url} key={index}>
                    <SocialImg src={social.img}/>
                  </a>
                ))}
              </SocialRow>
            }
            <ContactButton width={isMobile ? 298 : 330} height={56} link='#packages' text='Começar agora' font="'Paralucent'" fontSize={20} solidButton={true} background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' borderRadius={12} icon='rocket' iconHeight={17}/>
            {!isMobile &&
              <SocialRow>
                {socials.map((social, index) => (
                  <a href={social.url} key={index}>
                    <SocialImg src={social.img}/>
                  </a>
                ))}
              </SocialRow>
            }
          </TextWrapper>
          <HeroImg>
            <MarqueeWrapper>
              <MarqueeTrack>
                <MarqueeContent>
                  {gifs[0].map((gif, index) => (
                    <GifWrapper key={index}>
                      <HeroGif loading="lazy" src={isMobile ? gif.mobile : gif.desktop}/>
                    </GifWrapper>
                  ))}
                  {gifs[0].map((gif, index) => (
                    <GifWrapper key={index}>
                      <HeroGif loading="lazy" src={isMobile ? gif.mobile : gif.desktop}/>
                    </GifWrapper>
                  ))}
                </MarqueeContent>
              </MarqueeTrack>
            </MarqueeWrapper>
            <MarqueeWrapper>
              <MarqueeTrack style={{ animationDirection: 'reverse' }}>
                <MarqueeContent>
                  {gifs[1].map((gif, index) => (
                    <GifWrapper key={index}>
                      <HeroGif loading="lazy" src={isMobile ? gif.mobile : gif.desktop}/>
                    </GifWrapper>
                  ))}
                  {gifs[1].map((gif, index) => (
                    <GifWrapper key={index}>
                      <HeroGif loading="lazy" src={isMobile ? gif.mobile : gif.desktop}/>
                    </GifWrapper>
                  ))}
                </MarqueeContent>
              </MarqueeTrack>
            </MarqueeWrapper>
          </HeroImg>
        </HeroWrapper>
      </HeroContainer>
      <PartnersBanner>
        <SectionTitle>
          Marcas que criam com a gente
        </SectionTitle>
        <PartnersRow>
          {window.innerWidth < 1165 ? (
            <ImageMarquee logos={partners} isMobile={isMobile} spaceBetween={isMobile ? 20 : 39} height={isMobile ? 49 : 93}/>
          ) : (
            <Row className="page-width">
              {partners.map((partner, index) => (
                <a href={partner.url} key={index}>
                  <PartnerImg src={partner.desktop} width={partner.width}/>
                </a>
              ))}
            </Row>
          )
          }
        </PartnersRow>
        <div className="page-width">
          <StrategyWrapper>
            <BlurEffect/>
            <SectionTitle style={{ fontWeight: 700, color: '#342A41', padding: 0 }}>
              Estratégia Criativa Orientada a Dados
            </SectionTitle>
            <SectionSubtitle>
              Anúncios instigantes e criativos são bem-vindos.<br/> 
              Mas, no final do dia, o que dita o sucesso do negócio são as <b><GradientText index={2}>CONVERSÕES</GradientText></b>.
            </SectionSubtitle>
            <ContactButton width={323} height={56} link='#packages' text='Quero pra minha empresa' font="'Paralucent'" fontSize={20} solidButton={true} background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' borderRadius={12} icon='rocket' iconHeight={17}/>
          </StrategyWrapper>
        </div>
      </PartnersBanner>
    </>
  );
};

export default CreatorsHero;
