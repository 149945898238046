import React from 'react';
import styled from 'styled-components';
import QeA from '../components/QeA';

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const TextWrapper = styled.div`
  width: ${props => props.width};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const FAQWrapper = styled.div`
  padding: 100px 0 100px; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 43px 0 75px; 
  }
`;

const FAQContent = styled.div`
  width: 825px;
  max-width: calc(100% - 374px);
  box-sizing: border-box; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const CommerceFAQ = ({ isMobile }) => {

  const FAQInfo = [
    {
      question: 'Vocês garantem resultado?',
      answer: 'Só faríamos isso se fôssemos desonestos. Na verdade, nós garantimos a você um serviço de alta qualidade. O resultado é consequência. Essa é a mesma resposta que nós demos para empreendedores parceiros em que nós ajudamos a escalar em mais de 10x o faturamento.'  
    },
    {
      question: 'Quanto custa?',
      answer: 'O investimento no serviço depende do escopo do projeto. Os valores se iniciam em R$1997. Você pode receber uma proposta personalizada para o seu negócio preenchendo o formulário agora.'  
    },
    {
      question: 'Quais serviços vocês prestam?',
      answer: 'O nosso carro chefe é a aceleração de negócios a partir dos anúncios online. Nós temos um time completo de especialistas no digital, como copywriters, UI/UX Designers, Designers Gráficos , Gestores de Performance e Desenvolvedores, prontos para escalar suas vendas a partir da internet. Nesse sentido, nós confeccionamos para você criativos sob medida, otimizamos suas campanhas, trabalhamos estratégias de remarketing e também construímos sites do zero.'  
    },
    {
      question: 'Posso cancelar a qualquer momento?',
      answer: 'Se, por qualquer motivo, você não estiver 100% satisfeito com o nosso serviço, basta solicitar o cancelamento do contrato em 30 dias'  
    },
    {
      question: 'Quanto devo investir em tráfego?',
      answer: 'Nós recomendamos aos nossos clientes um investimento mínimo de R$35 por dia. Este valor é mais do que suficiente para os testes iniciais. À medida que você for obtendo seus primeiros resultados, você pode aumentar o seu investimento mensal.'  
    },
    {
      question: 'Vocês fazem site?',
      answer: 'Sim. Temos um time de UX Writing, UI Design e Desenvolvimento que está pronto para turbinar seu negócio com  sites e Landing Pages de alta conversão.'  
    }
  ];

  return (
    <PageWrapper className='flex column align-center'>
      <FAQWrapper className='page-width flex row justify-between wrap gap-32'>
        <TextWrapper width='27.6%' className={`flex column gap-16 ${isMobile ? 'text-center' : 'text-left'}`} style={{ minWidth: 'min-content' }}>
          <Subtitle font={isMobile ? 28 : 32}>
            <GradientText background='linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)'>F A Q</GradientText>
          </Subtitle>
          <Title font={isMobile ? 40 : 64} style={{ borderTop: '1px solid #1F1C3C', paddingTop: '16px' }}>
            Dúvidas Frequentes
          </Title>
        </TextWrapper>
        <FAQContent className='flex column'>
          {FAQInfo.map((item, index) => (
            <QeA qea={item} separatorColor='#1F1C3C' key={index}/>
          ))}
        </FAQContent>
      </FAQWrapper>
    </PageWrapper>
  );
};

export default CommerceFAQ;
