import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 100px 0 145px 0;
  gap: 75px;

  @media screen and (max-width: 768px) {
    padding: 80px 0;
    gap: 87px;
  }
`;

const TurbineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  border-radius: 32px;
  background: radial-gradient(91.34% 504.65% at 8.66% 0%, #6AE3CC 0%, #995FF8 59.55%, #BC51FF 100%);

  @media screen and (max-width: 768px) {
    padding: 86px 23px 30px;
  }
`;

const PackagesWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PackageCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 16px;
  padding: 32px 25px;
  width: calc((100% - 60px)/3);
  background: ${props => props.colored ? 'linear-gradient(182.42deg, #27F5B0 -34.08%, #6163F0 48.56%, rgba(189, 73, 255, 0) 124.19%)' : 'linear-gradient(180deg, #FFFFFF 33.33%, rgba(255, 255, 255, 0) 100%)'};
  border-radius: 16px;
  color: ${props => props.colored ? '#fff' : '#342A41'};
  font-size: 1.04vw;
  line-height: 1.25vw;
  font-weight: 400;
  transform: translate(0, 300px);
  opacity: 0;
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
  
  h3, h4 {
    font-family: 'Paralucent';
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.875vw;
    line-height: 2.19vw;
    margin: 0;
  }

  h4 {
    font-size: 1.67vw;
    line-height: 1.98vw;
  }

  div div {
    padding: 16px;
    max-width: 94%;
    box-sizing: border-box;
    border: ${props => props.colored ? '1px solid #fff' : '1px solid #000'};
    border-radius: 8px;
    margin: 0;
  }

  p {
    align-self: start;
    text-align: left;
    margin: 0;
    display: flex;
    gap: 12px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 18px;

    h3 {
      font-size: 27px;
      line-height: 32px;
    }

    h4 {
      font-size: 24px;
      line-height: 29px;
    }

    @media screen and (max-width: 1000px) {
      width: 338px;
    }

    @media screen and (max-width: 768px) {
      width: 358px;
      max-width: 100%;
      font-size: 16px;
      line-height: 19px;
      gap: 8px;
      padding: 32px 17px 70px;

      h3 {
        font-size: 36px;
        line-height: 42px;
      }

      h4 {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
`;

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 2.5vw;
  line-height: 2.92vw;
  font-weight: 700;
  font-family: 'Paralucent';
  width: 100%;
  z-index: 2;
  box-sizing: border-box;
  color: ${props => props.color ? props.color : '#342A41'};
  text-align: center;
  max-width: 42.29vw;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 42px;
    max-width: 609px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

const SectionText = styled.p`
  margin: 16px 0 32px 0;
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 400;
  width: 100%;
  text-align: center;
  max-width: 36.46vw;
  color: #fff;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
    max-width: 525px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
    margin: 16px 0 24px 0;
  }
`;

const TurbineImage = styled.img`
  width: 19.69vw;
  height: auto;
  margin-bottom: -14px;

  @media screen and (max-width: 1440px) {
    width: 283px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const TurbineDetails = styled.img`
  width: 1415px;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  @media screen and (max-width: 1440px) {
    
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 360px;
  }
`;

const Star = styled.img`
  position: absolute;
  right: -2.08vw;
  top: -2.08vw;
  width: 8.54vw;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 123px;
    right: -30px;
    top: -30px;
  }

  @media screen and (max-width: 768px) {
    width: 130px;
    top: -44px;
    right: -17px;
  }
`;

const PackageImage = styled.img`
  width: 4.17vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 60px;
  }

  @media screen and (max-width: 768px) {
    
  }
`;

const CreatorsTurbine = ({ isMobile }) => {
  
  const packages = [
    {
      title: 'Starter',
      price: 'R$ 1498,90',
      link: 'https://api.whatsapp.com/send?phone=5527996259461&text=Ol%C3%A1,%20tenho%20interesse%20em%20adquirir%20o%20pacote%20Starter%20da%20Turbo%20Creators!%20Quais%20seriam%20os%20pr%C3%B3ximos%20passos',
      info: '4 Criativos UGC (em vídeo) + 3 Criativos estáticos',
      items: [
        '1 Criador de conteúdo',
        '4 Anúncios em vídeo (2 conteúdos x 2 variações de ganchos)',
        '2 Imagens do creator com o produto',
        'Pesquisa do melhor creator & estratégia',
        'Copywriting otimizado para venda direta.'
      ],
    },
    {
      title: 'Medium',
      price: 'R$ 2998,90',
      link: 'https://api.whatsapp.com/send?phone=5527996259461&text=Ol%C3%A1,%20tenho%20interesse%20em%20adquirir%20o%20pacote%20Medium%20da%20Turbo%20Creators!%20Quais%20seriam%20os%20pr%C3%B3ximos%20passos',
      info: '12 Criativos UGC (em vídeo) + 6 Criativos estáticos',
      items: [
        '3 Criadores de conteúdo',
        '12 Anúncios em vídeo (6 conteúdos x 2 variações de ganchos)',
        '4 Imagens do creator com o produto',
        'Pesquisa dos melhores creators & estratégia',
        'Copywriting otimizado para venda direta.'
      ],
    },
    {
      title: 'Prime',
      price: 'R$ 4498,90',
      link: 'https://api.whatsapp.com/send?phone=5527996259461&text=Ol%C3%A1,%20tenho%20interesse%20em%20adquirir%20o%20pacote%20Prime%20da%20Turbo%20Creators!%20Quais%20seriam%20os%20pr%C3%B3ximos%20passos',
      info: '21 Criativos UGC (em vídeo) + 10 Criativos estáticos',
      items: [
        '5 Criador de conteúdo ',
        '21 Anúncios em vídeo (7 conteúdos x 3 variações de ganchos)',
        '7 Imagens do creator com o produto',
        'Pesquisa dos melhores creators & estratégia',
        'Copywriting otimizado para venda direta.'
      ],
    }
  ]
  
  function handleWindowScroll() {
    const packageCards = document.getElementsByClassName('package-cards');

    Array.from(packageCards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <SectionWrapper className="page-width">
      <TurbineWrapper>
        <TurbineDetails src='/creators/turbine-back-details.png'/>
        <TurbineImage src='/creators/turbine-graphic.png' loading='lazy'/>
        <SectionTitle color='#fff'>
          Turbine suas vendas com anúncios sem cara de anúncios
        </SectionTitle>
        <SectionText>
          Fale a mesma língua da plataforma e desbloqueie o <b><i>potencial máximo</i></b> dos  anúncios online.
        </SectionText>
        <ContactButton width={217} height={56} link="#packages" text="Iniciar projeto" textColor="#342A41" font="'Paralucent'" fontSize={20} solidButton={true} background='#fff' borderRadius={12}/>
      </TurbineWrapper>
      <SectionTitle id="packages" style={{ maxWidth: 'unset', marginBottom: '-55px' }}>
        Nossos Pacotes
      </SectionTitle>
      <PackagesWrapper>
        {packages.map((pack, index) => (
          <PackageCard key={index} colored={index === 1} index={index} className="package-cards">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: isMobile ? 8 : 16 }}>
              {index === 1 &&
                <Star src='/creators/packages-star.png'/>
              }
              <PackageImage src={index === 1 ? '/creators/star-white.png' : '/creators/star.png'}/>
              <h3>{pack.title}</h3>
              <h4>{pack.price}</h4>
              <div>{pack.info}</div>
              {pack.items.map((item, idx) => (
                <p key={idx}>
                  <img src={index === 1 ? '/creators/check-white.png' : '/creators/check.png'} alt="check icon" width={isMobile ? "22px" : "25px"} height={isMobile ? "16px" : "18px"}/>
                  <span>{item}</span>
                </p>
              ))}
            </div>  
            <ContactButton width={252} height={56} link={pack.link} target="_blank" text="Começar agora" textColor={index !== 1 ? "#fff" : "#342A41"} font="'Paralucent'" fontSize={20} solidButton={true} background={index !== 1 ? 'linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' : '#fff'} borderRadius={12} innerStyle={{ marginTop: 16 }}/>
          </PackageCard>  
        ))}
      </PackagesWrapper>
    </SectionWrapper>
  );
};

export default CreatorsTurbine;
