import React from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import CreatorsHero from '../sections/CreatorsHero';
import CreatorsContent from '../sections/CreatorsContent';
import CreatorsUGC from '../sections/CreatorsUGC';
import CreatorsHow from '../sections/CreatorsHow';
import CreatorsTurbine from '../sections/CreatorsTurbine';
import { Helmet } from 'react-helmet';

const TurboCreators = ({ isMobile, isTablet }) => {
  
  return (
    <div style={{ backgroundColor: '#F0F1F3' }}>
      <Helmet>
        <title>Turbo Creators</title>
        <meta name="description" content="Faça seu CPA despencar com anúncios sem cara de anúncios"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <CreatorsHero isMobile={isMobile} />
      <CreatorsContent isMobile={isMobile} />
      <CreatorsUGC isMobile={isMobile} />
      <CreatorsHow isMobile={isMobile} />
      <CreatorsTurbine isMobile={isMobile} />
      <Footer background={'rgba(255, 255, 255, 1)'} isMobile={isMobile} />
    </div>
  )
};

export default TurboCreators;
