import React from 'react';
import styled, { keyframes } from 'styled-components';

const marqueeAnimationLeft = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-33.33%); }
`;

const marqueeAnimationRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(33.33%); }
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 101vw;
  height: 90px;
  overflow-x: hidden;
  background: ${props => props.left ? 'linear-gradient(264.21deg, #4AA6F7 23.53%, #4AA6F7 27.04%, #1141BD 58.95%)' : '#081021'};
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.1) transparent rgba(255, 255, 255, 0.1) transparent;
  display: flex;
  align-items: center;
  justify-content: ${props => props.left ? 'start' : 'end'};
  margin-top: ${props => props.left ? '' : '-70px'};
  z-index: 2;
  transform: ${props => props.left ? 'rotate(0.27deg)' : 'rotate(-3.93deg)'};
  
  @media screen and (max-width: 768px) {
    height: 70px;
    margin-top: ${props => props.left ? '' : '-20px'};
  }
`;

const MarqueeTrackLeft = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationLeft} 16s linear infinite;
`;

const MarqueeTrackRight = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationRight} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 40px;
  gap: 80px;
  font-size: 24px;
  line-height: 25px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 0.015em;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 17px;
    height: 17px;
    gap: 54px;
    padding: 0 27px;
  }
`;

const TagImage = styled.img`
  height: 100%;
  width: auto;
`;

const TechMarquee = ({ isMobile }) => {

  const languages = [
    'LIQUID', 'REACT', 'HYDROGEN', 'JAVA SCRIPT', 'ANGULAR', 'HTML', 'CSS', 'VUE', 'UI/UX', 'DESIGN'
  ]

  return (
    <div style={{ overflow: 'hidden', height: isMobile ? '140px' : '180px', paddingTop: isMobile ? '' : '40px' }}>
      <MarqueeWrapper left={true}>
        <MarqueeTrackLeft>
          <MarqueeContent>
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-1.png'}/>
              </TextWrapper>
            ))}
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-1.png'}/>
              </TextWrapper>
            ))}
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-1.png'}/>
              </TextWrapper>
            ))}
          </MarqueeContent>
        </MarqueeTrackLeft>
      </MarqueeWrapper>
      <MarqueeWrapper>
        <MarqueeTrackRight>
          <MarqueeContent>
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-2.png'}/>
              </TextWrapper>
            ))}
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-2.png'}/>
              </TextWrapper>
            ))}
            {languages.map((language, index) => (
              <TextWrapper key={index}>
                { language }
                <TagImage src={'/tag-image-2.png'}/>
              </TextWrapper>
            ))}
          </MarqueeContent>
        </MarqueeTrackRight>
      </MarqueeWrapper>
    </div>
  );
};

export default TechMarquee;
    