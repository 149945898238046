import React from 'react';
import Header from '../sections/Header';
import ErrorMessage from '../sections/ErrorMessage';
import Footer from '../sections/Footer';

const NotFound = ({ isMobile, isTablet }) => {
  return (
    <div style={{ backgroundColor: '#08031A', overflowX: 'hidden' }}>
      <Header  isTablet={isTablet}/>
      <ErrorMessage isMobile={isMobile} />
      <Footer isMobile={isMobile}  background={'#060613'}/>
    </div>
  )
};

export default NotFound;
