import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import TurboCommerce from './pages/TurboCommerce';
import LPCommerce from './pages/LPCommerce';
import LPCommerce2 from './pages/LPCommerce2';
import Bye from './pages/Bye';
import ThankYou2 from './pages/ThankYou2';
import TurboCreators from './pages/TurboCreators';
import TurboTech from './pages/TurboTech';
import TurboDashboard from './pages/TurboDashboard';
import ThankYou from './pages/ThankYou';
import Talents from './pages/Talents';
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import UseTerms from './pages/UseTerms';

function App() {
  
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  function collectUTM() {
    const { href } = window.location;
    href.split('#');
    const queryString = (href.includes('?')) ? href.slice(href.indexOf('?')) : '';
    
    if (queryString !== '') {
      const changeLinks = document.querySelectorAll('.change-link');
      changeLinks.forEach(link => {
        console.log(link.href);
        if (!link.href.includes('#packages') && !link.href.includes('#redirect') && !link.href.includes('wa.me')) {
          const { href } = link;
          link.href = `${href}${queryString}`;
        }
      });
    }
  };

  React.useEffect(() => {
    collectUTM();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;
  const isTablet = width <= 900;
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/commerce" element={ <LPCommerce2 isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/commerce-1" element={ <TurboCommerce isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/commerce-2" element={ <LPCommerce isMobile={isMobile}/> } />
        <Route path="/creators" element={ <TurboCreators isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/tech" element={ <TurboTech isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/dash" element={ <TurboDashboard isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/obrigado-empresas" element={ <ThankYou2 isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/tchau-empresas" element={ <Bye isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/obrigado-empresas2" element={ <ThankYou isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/talento" element={ <Talents isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/politica-de-privacidade" element={ <PrivacyPolicy isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path="/termos-de-uso" element={ <UseTerms isMobile={isMobile} isTablet={isTablet}/> } />
        <Route path='*' element={ <NotFound isMobile={isMobile} isTablet={isTablet}/> }/>
      </Routes>
    </div>
  );
}

export default App;
