import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const MessageWrapper = styled.div`
  width: 100%;
  height: 34.79vw;
  padding-top: 12.55vw;
  box-sizing: border-box;
  background: url('/error-background.png') no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1440px) {
    height: 500px;
    padding-top: 180px;
  }

  @media screen and (max-width: 768px) {
    background: url('/error-background_mobile.png') no-repeat;
    background-size: cover;
    height: 794px;
    padding-top: 214px;
  }
`;

const Title = styled.h2`
  font-size: 2.5vw;
  line-height: 3.02vw;
  margin: 0 0 2.14vw 0;
  color: #FFF;
  letter-spacing: -0.0216em;
  text-align: center;
  font-weight: 700;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 30px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 64px 0;
  }
`;

const ErrorMessage = () => {

  return (
    <MessageWrapper className='page-width'>
      <Title>
        Ops!<br/>
        Não encontramos essa página :(
      </Title>
      <ContactButton width={258} link="/" text="Retonar para HOME" solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 76.86%, #EC894B 97.89%)' icon='rocket' iconHeight={16.5}/>
    </MessageWrapper>
  );
};

export default ErrorMessage;

