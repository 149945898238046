import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const DashContainer = styled.div`
  overflow: hidden;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
`;
const DashWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    padding: 50px 0;
    height: auto;
  }
`; 

const TextWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  font-family: 'Sora';
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 150%;
  align-items: Left;
  vertical-align: Top;
  width: 100%;
  margin: 0 0 24px 0;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
    max-width: 90%;
    margin: 0;
  }
`;
const Subtitle = styled.p`
  font-family: 'Graphik', sans-serif;
  font-size: 22px;
  line-height: 130%;
  font-weight: 400;
  width: 70%;
  margin: 0 0 42px 0;
  color: #fff;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 130%;
    width: 90%;
    margin: 0;
    letter-spacing: -0.005em;
  }
`;


const ReviewContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; 
  gap: 30px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc((100% - 100px)/4);
  box-sizing: border-box;
  padding: 1.875vw 1.4vw;
  background: rgba(45, 35, 56, 0.1);
  border: 2px solid #12192B;
  border-radius: 8px;
  gap: 10px;
  font-weight: 400;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: -0.0216em;
  text-align: left;

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};

  p {
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    padding: 27px 20px; 
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 36px 28px; 
    font-size: 16px;
    line-height: 19px;
    gap: 48px;
    margin: 0 auto;
  }
`;

const ReviewText = styled.p`
  width: 100%;
  font-family: 'Graphik', sans-serif;
  background: #F2EDF9;
  font-size: 20px;
  line-height: 130%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }

`;

const ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Graphik', sans-serif;
  width: 100%;
  border-image: linear-gradient(88.92deg, #5C4FED -15.8%, #3C368A 125.72%) 30;
  border-top: 1px solid;
  padding-top: 20px;
`;

const ReviewPhoto = styled.img`
  width: 44px;
  height: 44px;

  @media screen and (max-width: 768px) {
    width: 51px;
    height: 51px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const ReviewName = styled.h4`
  font-size: 1.25vw;
  font-family: 'Graphik', sans-serif;
  line-height: 1.46vw;
  font-weight: 500;
  color: #ffffff;
  margin: 0;  

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`;


const DashComments = ({ isMobile }) => {

  const reviews = [
    {
      text: '“A verdade é que o Turbo Dash nasceu pra sanar dores internas nossas. Por isso, ele é uma ferramenta que uso diariamente.”',
      image: '/review-5.png',
      name: 'Andre Voskanian',
      job: 'Head de Ventures'
    },
    {
      text: '“O Turbo Dash é o tipo de ferramenta que, depois que você começa a usar, simplesmente não consegue mais ficar sem.”',
      image: '/review-6.png',
      name: 'Caio Massaroni',
      job: 'Gestor nível IV'
    },
    {
      text: '“É muito comum ferramentas como Facebook Ads e Google Ads não trackearam algumas vendas. Com o Turbo Dash, ganhamos muito mais clareza.”',
      image: '/review-7.png',
      name: 'Rafael Vilella',
      job: 'Gestor nível II'
    },
    {
      text: '“O que eu mais gosto nele é a segurança que ele traz. Saber o ROAS com exatidão foi game-changer pra acelerar nosso processo de escala.”',
      image: '/review-8.png',
      name: 'Rodrigo Padrão',
      job: 'Gestor nível II'
    }
  ];

  function handleWindowScroll() {
    const Cards = document.getElementsByClassName('fade');

    Array.from(Cards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <DashContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <DashWrapper>
        <TextWrapper>
          <Title>
          Feito <b style={{color: '#5B4EEE'}}>por quem entende</b> de números
          </Title>
          <Subtitle>
            As funcionadades do <b>Turbo Dash </b>foram cuidadosamentes projetadas 
            pelos nosso próprios 
            gestores de performance: Preenchendo as lacunas identificadas por quem 
            já investiu <b>milhões de reais</b> em tráfego pago!
          </Subtitle>
        </TextWrapper>
        <ReviewContent>
          {reviews.map((review, index) => (
            <ReviewCard key={index} className="fade">
              <ReviewText>{review.text}</ReviewText>
              <ReviewInfo>
                <ReviewPhoto src={review.image}/>
                <InfoWrapper>
                  <ReviewName>{review.name}</ReviewName>
                  <p>{review.job}</p>
                </InfoWrapper>
              </ReviewInfo>
            </ReviewCard>
          ))}
        </ReviewContent>
        <ContactButton link="https://apps.shopify.com/turbodash" target="_blank" icon="arrow" height={60} 
        width={360}
        solidButton={true} text="Iniciar teste gratuito agora" 
        fontSize={16} font="'Sora', sans serif" 
        fontWeight={700} textColor="#F2EDF9" 
        background="#5B4EEE" 
        hoverBackground="#3C368A" 
        shadow="unset" 
        borderRadius={4} 
        innerStyle={{textDecoration: 'none',marginTop: '50px', padding: '10px 10px' }}/>
      </DashWrapper>
    </DashContainer>
  )
};

export default DashComments;
