import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import ResultCard from '../components/ResultCard';


const BrandsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 70px;
  overflow-x: hidden;
  position: relative;
  z-index: 2;

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    cursor: grab;
    padding-bottom: 8.85vw;
  }

  @media screen and (max-width: 1440px) {
    padding-top: 127px;
    row-gap: 50px;

    .swiper-wrapper {
      padding-bottom: 127px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 58px;
  }
`;

const SwiperManual = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1px 0;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  padding: 0 16px 58px;
  gap: 23px;
`;

const Title = styled.h2`
  color: #FEFEFE;
  font-family: Sora;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  align-self: center;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
  }
`;

// const GradientEffect = styled.div`
//   background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);
//   height: 380px;
//   width: 13.02vw;
//   position: absolute;
//   top: calc(11.39vw + 60px);
//   right: 0;
//   z-index: 2;

//   @media screen and (max-width: 1440px) {
//     width: calc((100vw - 1065px)/2);
//     min-width: 50px;
//     top: 203px;
//   }

//   @media screen and (max-width: 768px) {
//     min-width: unset;
//     width: 80px;
//     height: 473px;
//     top: 90px;
//   }
// `;

const Brands = ({ isMobile }) => {

  const brandsInfo = [
    {
      title: '700%',
      subtitle: 'Aumento de faturamento',
      title2: '30%',
      subtitle2: 'Aumento de recorrência',
      img: '/ninasaude.png',
    },
    {
      title: 'R$500k',
      subtitle: 'Receita criada ',
      title2: '103%',
      subtitle2: 'Aumento na taxa de conversão',
      img: '/bready.png',
    },
    {
      title: 'R$5M',
      subtitle: 'Em vendas mensais',
      title2: '180%',
      subtitle2: 'De novos leads criados',
      img: '/getpower.png',
    },
    {
      title: '200%',
      subtitle: 'Aumento de faturamento em 9 meses',
      title2: '70%',
      subtitle2: 'Aumento de novos clientes',
      img: '/shopcao.png',
    },
    {
      title: '170%',
      subtitle: 'Aumento de faturamento em 4 meses',
      title2: '50%',
      subtitle2: 'De redução de CAC',
      img: '/solvee.png',
    },
    {
      title: '900%',
      subtitle: 'Aumento de faturamento em 1 ano',
      title2: '30k',
      subtitle2: 'De novos seguidores',
      img: '/byr.png',
    }
  ];

  return (
    <BrandsWrapper>
      <Title>
        <span style={{ color: '#4E62EE' }}>O que nossos parceiros</span> ganham
      </Title>
      {isMobile ? (
        <SwiperManual>
          <SwiperWrapper>
            {brandsInfo.map((result, index) => (
               <ResultCard result={result} isMobile={isMobile} key={index}/>
            ))}
          </SwiperWrapper>
        </SwiperManual>
      ) : (
        <Swiper
          id="result-cards"
          modules={[FreeMode]}
          spaceBetween={30}
          slidesPerView={"auto"}
          freeMode={true}
          slidesOffsetBefore={(window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
          slidesOffsetAfter={(window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
          breakpoints={{
            0: {
              spaceBetween: 23
            },
            768: {
              spaceBetween: 30
            }
          }}
        >
          {brandsInfo.map((result, index) => (
            <SwiperSlide key={index}>
              <ResultCard result={result} isMobile={isMobile}/>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </BrandsWrapper>
  );
};

export default Brands;

