import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const DashContainer = styled.div`
  overflow: hidden;
  background-size: cover;
  position: relative;
  background-position: bottom center;
  background-repeat: no-repeat;
`;

const DashWrapper = styled.div`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    padding: 50px 0 0 0;
    height: auto;
    margin-bottom: 80px;
  }
`; 
const Gradient3 = styled.div`
  position: absolute;
  z-index: 1;
  width: 999px;
  height: 885px;
  top: -130px;
  left: 41px;
  opacity: 0.4;
  background: radial-gradient(circle,rgba(92,79,238,1) -7%,rgba(0,0,0,0) 45%);

  @media screen and (max-width: 768px) {
    width: 500px;
    height: 600px;
    top: -140px;
    left: -90px;
  }
`;
const TextWrapper = styled.div`
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  z-index: 2;
  width: 50%;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-family: 'Sora';
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 150%;
  align-items: Left;
  vertical-align: Top;
  width: 100%;
  margin: 0 0 24px 0;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
    max-width: 100%;
    margin: 0 0 32px 0;
  }
`;

const Subtitle = styled.p`
  font-size: 20px;
  line-height: 130%;
  font-weight: 400;
  width: 95%;
  margin: 0 0 24px 0;
  color: #fff;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    width: 98%;
    margin: 0 0 32px 0;
    letter-spacing: -0.005em;
  }
`;

const ImgWrapper = styled.div`
  width: 48%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    height: auto;
    width: 100%;
  }
`
const SpaceImg = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    height: auto;
  }
`
const DashImg = styled.img`
  width: 70%;
  z-index: 1;
  position: relative;
  right: 20%;

  @media screen and (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
    right: 0;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const DashRelativeImg = styled.img`
  position: relative;
  z-index: 2;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
`;
const Col2Img = styled.div`
  display: flex; 
  flex-direction: column; 
  position: relative; 
  left: -260px; 
  justify-content: space-between;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const DashRelativeImg2 = styled.img`
  position: relative;
  z-index: 2;
  top: -50px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
`;
const DashRelativeImg3 = styled.img`
  position: relative;
  z-index: 2;
  bottom: -40px;

  @media screen and (max-width: 768px) {
    display: none;
  }

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
`;

const DashROAS = ({ isMobile }) => {


  function handleWindowScroll() {
    const Cards = document.getElementsByClassName('fade');

    Array.from(Cards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });


  return (
    <DashContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <DashWrapper>
        <ImgWrapper>
          <Gradient3/>
          <SpaceImg>
            <DashRelativeImg src={'circulo-shopify.png'} className="fade"/>
            <DashImg src={isMobile ? 'tablet-mobile.png' : 'tablet-desktop.png'}/>
            <Col2Img>
              <DashRelativeImg2 src={'circulo-facebook.png'} className="fade"/>
              <DashRelativeImg3 src={'circulo-googleads.png'} className="fade"/>
            </Col2Img>
          </SpaceImg>
        </ImgWrapper>
        <TextWrapper>
          <Title>
            Acompanhe seu ROAS<b style={{color: '#5B4EEE'}}> na vírgula.</b>
          </Title>
          <Subtitle>
            Reúna em <b>um único local</b>  seu investimento em tráfego pago, a receita de suas vendas e seu Retorno Sobre Investimento em Anúncios Online.
          </Subtitle>
          <ContactButton link="https://apps.shopify.com/turbodash" target="_blank" icon="arrow" height={60} 
            width={360}
            solidButton={true} text="Escalar com segurança" 
            fontSize={16} font="'Sora', sans serif" 
            fontWeight={700} textColor="#F2EDF9" 
            background="#5B4EEE" 
            hoverBackground="#3C368A" 
            shadow="unset" 
            borderRadius={4} 
            innerStyle={{textDecoration: 'none',marginTop: '50px' }}
          />      
        </TextWrapper>
      </DashWrapper>
    </DashContainer>
  );
};

export default DashROAS;
