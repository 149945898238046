import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const HeroContainer = styled.div`
  overflow: hidden;
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
`;

const HeroWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 5;
`; 

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  row-gap: 1.56vw;
  padding: calc(8.18vw + 80px) 0 15.2vw;
  z-index: 2;
  font-family: 'Montserrat', sans serif;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
    padding: 198px 0 218px;
  }

  @media screen and (max-width: 768px) {
    row-gap: 16px;
    width: 100%;
    padding: 291px 0 198px;
  }
`;

const BrandRow = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;

  @media screen and (max-width: 768px) {
    column-gap: 7px;
  }
`;

const BrandIcon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

const BrandName = styled.h2`
  color: #fff;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.51vw;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }

  span {
    background: linear-gradient(-90deg, #4AA6F7 50%, #1141BD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
  }
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.49vw;
  font-weight: 400;
  letter-spacing: 0.015em;
  width: 48.34vw;
  color: #fff;
  margin: 0;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
    max-width: 100%;
  }
`;

const Subtitle = styled.p`
  font-size: 1.67vw;
  line-height: 1.9vw;
  font-weight: 500;
  width: 48.34vw;
  margin: 0 0 20px 0;
  color: #fff;
  opacity: 0.7;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    margin: 0 0 8px 0;
    letter-spacing: -0.005em;
  }
`;

const HeroImg = styled.img`
  position: absolute;
  top: calc(7.08vw + 80px);
  right: calc(6.82vw - 13.02vw);
  height: 21.04vw;
  width: auto;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    right: -105px;
    top: 182px;
    height: 303px;
  }

  @media screen and (max-width: 1165px) {
    right: calc((100vw - 1165px)/2 - 105px);
  }

  @media screen and (max-width: 768px) {
    top: 120px;
    right: 0px;
    height: 205px;
  }
`;

const TechHero = ({ isMobile }) => {

  return (
    <HeroContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <HeroWrapper>
        <TextWrapper>
          <BrandRow>
            <BrandIcon src={'TurboTech.svg'}/>
            <BrandName>
              Turbo <span>Tech</span>
            </BrandName>
          </BrandRow>
          <Title>
            Tenha um <b>Site-Turbo</b>: 3x mais rápido, funcional e conversivo que os tradicionais.
          </Title>
          <Subtitle>
            Sites, lojas virtuais e landing pages de alta performance para o seu negócio.
          </Subtitle>
          <ContactButton width={230} height={64} link='https://form.respondi.app/SOMTDxh7' target="_blank" text='Turbinar agora' fontSize={20} fontWeight={500} solidButton={true} background='linear-gradient(247.26deg, #4AA6F7 -59.54%, #4AA6F7 -44.66%, #1141BD 90.48%)' shadow='0px 4px 30px rgba(0, 136, 255, 0.1)'/>
        </TextWrapper>
        <HeroImg src={isMobile ? 'banner-tech_mobile.png' : 'banner-tech.png'}/>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default TechHero;
