import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const mouseMove = keyframes`
  0%, 50%, 100% { right: var(--right1); bottom: var(--bottom1); }
  25% { right: var(--right2); bottom: var(--bottom2); }
  75% { right: var(--right3); bottom: var(--bottom3); }
`;

const DevelopmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 8.02vw;
  height: 31.35vw;
  width: 100%;
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  letter-spacing: 0.015em;
  text-align: center;
  font-weight: 400;
  margin-bottom: 3.12vw;
  
  span {
    font-size: 6.67vw;
    line-height: 6.98vw;
    font-weight: 200;
    font-family: 'Saira', sans-serif;

    background: linear-gradient(263.07deg, #4AA6F7 13.1%, #4AA6F7 22.31%, #1141BD 105.97%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  i {
    font-size: 1.25vw;
    line-height: 1.46vw;
    color: #B8B8B8;
    margin: 12px 0 24px 0;
  }

  p {
    font-size: 1.875vw;
    line-height: 1.46vw;
    color: #ffffff;
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    height: 452px;
    padding-top: 116px;
    margin-bottom: 45px;

    span {
      font-size: 96px;
      line-height: 100px;
    }

    i {
      font-size: 18px;
      line-height: 21px;
    }

    p {
      font-size: 27px;
      line-height: 21px;
    }
  }

  @media screen and (max-width: 768px) {
    height: 230px;
    padding-top: 54px;
    margin-bottom: 46px;
    background-size: contain;
    
    span {
      font-size: 48px;
      line-height: 50px;
    }

    i {
      font-size: 14px;
      line-height: 17px;
      margin: 8px 0;
    }

    p {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;

const BiggerTitle = styled.h2`
  max-width: 59.48vw;
  font-family: 'Saira', sans-serif;
  font-weight: 200;
  font-size: 5vw;
  line-height: 5.52vw;
  text-align: center;
  letter-spacing: 0.015em;
  color: #FFFFFF;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 72px;
    line-height: 80px;
    max-width: 857px;
  }

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 120px;
  margin-top: -120px;

  @media screen and (max-width: 768px) {

  }
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.59vw;
  font-weight: 400;
  margin: 0 0 4.9vw 0;
  letter-spacing: 0.015em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 52px;
    margin: 0 0 70px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    margin: 0 0 58px 0;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(-90deg, #4AA6F7 -20%, #1141BD 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`; 

const Subtitle = styled.p`
  font-family: 'Saira', sans-serif;
  font-size: 1.875vw;
  line-height: 1.97vw;
  font-weight: 400;
  margin: 0 0 16px 0;
  letter-spacing: 0.015em;
  
  @media screen and (max-width: 1440px) {
    font-size: 27px;
    line-height: 29px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 21px;
    margin: 0 0 8px 0;
  }
`;

const ProjectsContent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 11.51vw;

  @media screen and (max-width: 1440px) {
    margin-bottom: 166px;
  }

  @media screen and (max-width: 768px) {
    gap: 20px;
    margin-bottom: 87px;
  }
`;

const ProjectCard = styled.div`
  width: calc((100% - 60px)/3);
  height: max-content;
  gap: 20px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-sizing: border-box;
  background: rgba(45, 35, 56, 0.1);
  border: 2px solid #12192B;
  border-radius: 4px;
  min-width: 320px;
  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 12px;
    min-width: unset;
    gap: 12px;
    max-width: 390px;
    margin: 0 auto;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: auto;
`;

const ProjectTitle = styled.p`
  font-size: 1.67vw;
  line-height: 1.72vw;
  font-family: 'Saira', sans-serif;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.015em;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const ProjectType = styled.p`
  font-size: 1.04vw;
  line-height: 1.09vw;
  margin: 0;
  width: fit-content;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 16px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 17px;
    padding: 8px;
  }
`;

const ProjectText = styled.p`
  margin: 0;
  font-size: 1.04vw;
  line-height: 1.46vw;
  font-weight: 400;
  letter-spacing: 0.015em;
  color: #A0A0A0;
  text-align: left;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const ProjectLink = styled.a`
  font-size: 1.25vw;
  line-height: 1.3vw;
  font-weight: 600;
  letter-spacing: 0.015em;
  text-decoration: none;
  width: fit-content;
  position: relative;

  background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &:after {
    background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);  
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
  }

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const Mouse = styled.img`
  width: 10vw;
  height: auto;
  position: absolute;
  animation: ${mouseMove} 6s ease-in-out infinite;
  --right1: -15.625vw;
  --right2: -26.04vw;
  --right3: -2.6vw;
  --bottom1: 0px;
  --bottom2: -5.21vw;
  --bottom3: -2.6vw;

  @media screen and (max-width: 1440px) {
    width: 144px;
  }

  @media screen and (max-width: 768px) {
    width: 191px;
    --right1: calc(40vw - 65px);
    --right2: -65px;
    --right3: -65px;
    --bottom1: -170px;
    --bottom2: -220px;
    --bottom3: -60px;
    animation-duration: 8s;
  }
`;

const TechDevelopment = ({ isMobile }) => {

  const projects = [
    {
      image: '/projects-ninasaude.png',
      title: 'Nina Saúde',
      type: 'Ecommerce',
      text: 'Ecommerce de vacinação domiciliar para todos os públicos.',
      link: 'https://ninasaude.com.br/home-2022/'
    },
    {
      image: '/projects-tc.png',
      title: 'TC Academy',
      type: 'Landing Page',
      text: 'Empresa do mercado financeiro listada na bolsa de valores.',
      link: 'https://www.figma.com/proto/KB3FW0mFdOvl91uG5eJo88/TC?page-id=2%3A5&node-id=2%3A7&viewport=426%2C305%2C0.24&scaling=scale-down&starting-point-node-id=2%3A7&show-proto-sidebar=1'
    },
    {
      image: '/projects-getpower.png',
      title: 'Getpower',
      type: 'Web Site',
      text: 'Maior empresa de energia solar no Espirito Santo.',
      link: 'https://getpowersolar.com.br/'
    },
    {
      image: '/projects-soulzen.png',
      title: 'Soulzen',
      type: 'Ecommerce',
      text: 'Ecommerce de moda feminina, focados em produtos handmade.',
      link: 'https://soulzenshop.com/'
    },
    {
      image: '/projects-gulafit.png',
      title: 'Gula Fit',
      type: 'Ecommerce',
      text: 'Ecommerce de alimentos e bebidas saudáveis.',
      link: 'https://gulafit.com.br/lp/kombucha/v1.html'
    },
    {
      image: '/projects-vividus.png',
      title: 'Vividus',
      type: 'Landing Page',
      text: 'Empresa de assinatura de plano em atenção primária à saúde.',
      link: 'https://www.vividus.com.br/lp/'
    }
  ];

  function handleWindowScroll() {
    const projectCards = document.getElementsByClassName('project-cards');
    
    Array.from(projectCards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <>
      <DevelopmentWrapper background={isMobile ? '/development-background_mobile.png' : '/development-background.png'}>
        <span>des.en.vol.ver</span>
        <i>transitivo direto e pronominal</i>
        <p>fazer crescer, tornar(-se) maior, mais forte.</p>
      </DevelopmentWrapper>
      <SectionWrapper id="projects-section" className='page-width'>
        <Subtitle>
          <GradientTitle>NOSSOS PROJETOS</GradientTitle>
        </Subtitle>
        <Title>
          <div>Uma galera que já <GradientTitle>zerou</GradientTitle> o jogo</div>
        </Title>
        <ProjectsContent>
          {projects.map((project, index) => (
            <ProjectCard key={index} index={index} className="project-cards">
              <ImageWrapper>
                <ProjectImage src={project.image}/>
              </ImageWrapper>
              <ProjectTitle>
                {project.title}
                <ProjectType>{project.type}</ProjectType>
              </ProjectTitle>
              <ProjectText>{project.text}</ProjectText>
              <ProjectLink href={project.link} target="_blank">
                Visualizar projeto
              </ProjectLink>
            </ProjectCard>
          ))}
        </ProjectsContent>
        <BiggerTitle>
          Aqui está seu controle. Agora, é a <GradientTitle>sua vez.</GradientTitle>
        </BiggerTitle>
        <div style={{ position: 'relative', margin: isMobile ? '24px 0 225px 0' : '40px 0 206px 0' }}>
          <ContactButton width={251} height={64} link='https://form.respondi.app/SOMTDxh7' target="_blank" text='PRESS START' fontSize={20} fontWeight={500} solidButton={true} background='linear-gradient(263.91deg, #4AA6F7 -35.57%, #4AA6F7 -22.82%, #1141BD 92.97%)' shadow='0px 4px 30px rgba(0, 136, 255, 0.38)' icon='control' iconHeight={24}/>
          <Mouse src={'development-mouse.png'}/>
        </div>
      </SectionWrapper>
    </>
  );
};

export default TechDevelopment;
