import React from 'react';
import styled from 'styled-components';
import { FaYoutube, FaInstagram, FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';
import FooterIcon from '../components/FooterIcon';

const FooterWrapper = styled.div`
  background: ${props => props.background};
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  padding: 6.82vw 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  column-gap: 6.25vw;
  overflow-x: hidden;
  z-index: 2;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 40px 16px 226px 16px;
  }
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: left;
  color: ${props => props.white ? '#342A41' : '#fff'};
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.032em;

  @media screen and (max-width: 1165px) and (min-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const Link = styled.a`
  font-weight: 400;
  text-decoration: none;
  cursor: ${props => props.block ? 'not-allowed' : 'pointer'};
  width: fit-content;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.white ? '#342A41' : '#B5B5B5'};
  display: flex;
  align-items: center;
  gap: 6px;
  
  &:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width: 1165px) and (min-width: 768px) {
    font-size: 14px;;
    line-height: 17px;
  }
`;

const Info = styled.p`
  font-weight: 400;
  margin: 0;
  width: 100%;
  max-width: 300px;
  width: fit-content;
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.white ? '#342A41' : '#B5B5B5'};

  @media screen and (max-width: 1165px) and (min-width: 768px) {
    font-size: 14px;
    line-height: 17px;
    max-width: 231px;
  }
`;

const Logo = styled.img`
  width: 330px;
  max-width: 100%;
  height: auto;
  margin: 16px 0;

  @media screen and (max-width: 920px) and (min-width: 768px) {
    width: 220px;
  }
`;

const PartnersWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media screen and (max-width: 920px) and (min-width: 768px) {
    gap: 10px;
  }
  
  @media screen and (max-width: 768px) {
    padding-top: 12px;
    gap: 10px;
    width: 100%;

    a {
      width: calc((100% - 20px)/3);
    }
  }
`;

const PartnersImg = styled.img`
  width: 90px;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 50px;
  border-bottom: ${props => props.white ? '1px solid #DAD3E3' : '1px solid rgba(255, 255, 255, 0.2)'};
  gap: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    width: 100%;
    row-gap: 44px;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  width: max-content;
  padding: 0 0 4.84vw 0;
  box-sizing: border-box;
  column-gap: 12px;

  @media screen and (max-width: 1440px) {
    padding: 0 0 85px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 6px 0 36px 0;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    justify-content: center;
  }
`;

const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 57px;
  border-radius: 8px;
  background: #170F34;
  transition: background 1s linear;

  &:hover {
    background: linear-gradient(84.4deg, #0E11DB 21.9%, #DA41A5 62.59%, #EC894B 95.31%);
  }

  @media screen and (max-width: 920px) and (min-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const TurboInfo = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${props => props.white ? '#342A41' : '#fff'};
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: -0.032em;
  padding: 50px 0;
  margin: 0;
  row-gap: 14px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 32px 0 0 0;
    max-width: 210px;
    align-self: center; 
  }
`;

const Footer = ({ isMobile, background }) => {

  const menuFooter = [
    {
      title: 'Soluções',
      content: [
        {
          text: 'Turbo Commerce',
          url: '/commerce',
          active: true
        },
        {
          text: 'Turbo Class',
          url: 'https://lp.turbopartners.com.br/class/cv2',
          active: true
        },
        {
          text: 'Turbo Tech',
          url: '/tech',
          active: true
        },
        {
          text: 'Turbo Dash',
          url: '/Dash',
          active: true
        },
        {
          text: 'Turbo Creators',
          url: '/creators',
          active: true
        },
        {
          text: 'Turbo Ventures',
          url: '/ventures'
        },
        {
          text: 'Turbo Bank',
          url: '/bank'
        },
        // {
        //   text: 'Turbo Fulfillment',
        //   url: '/fulfillment'
        // }
      ]
    },
    {
      title: 'Sobre',
      content: [
        {
          text: 'Blog',
          url: 'https://www.blog.turbopartners.com.br',
          active: true
        },
        {
          text: 'Trabalhe Conosco',
          url: '/talento',
          active: true
        },
        {
          text: 'Política de privacidade',
          url: '/politica-de-privacidade',
          active: true
        },
        {
          text: 'Termos de uso',
          url: '/termos-de-uso',
          active: true
        }
      ]
    },
    {
      title: 'Contato',
      content: [
        {
          text: 'R. Maria de Lourdes García, 228 - Ilha de Santa Maria, Vitória - ES, 29053-310',
        },
        {
          text: 'contato@turbopartners.com.br',
          icon: 'email'
        },
        {
          text: '(27) 99796-9628',
          icon: 'tel'
        }
      ]
    }
  ];

  const white = (background === 'rgba(255, 255, 255, 1)');

  return (
    <FooterWrapper className="page-width" background={background}>
      <Row white={white}>
        <SectionsWrapper white={white} style={isMobile ? { alignItems: 'center', width: '100%' } : {}}>
          <a href="/">
            <Logo src={white ? '/TurboPartners_dark.png' : '/TurboPartners.png'}/>
          </a>
          <SocialMedia>
            <Social href="https://www.instagram.com/turbo.partners/" target="_blank">
              <FaInstagram size={window.innerWidth > 920 ? "38px" : "28px"} color="#fff"/> 
            </Social>
            <Social href="https://www.facebook.com/Turbo-Partners-111691498031338/" target="_blank">
              <FaFacebookSquare size={window.innerWidth > 920 ? "36px" : "26px"} color="#fff"/> 
            </Social>
            <Social href="https://www.linkedin.com/company/turbo-partners" target="_blank">
              <FaLinkedin size={window.innerWidth > 920 ? "36px" : "26px"} color="#fff"/> 
            </Social>
            <Social href="https://www.youtube.com/@TurboPartners?sub_confirmation=1" target="_blank">
              <FaYoutube size={window.innerWidth > 920 ? "40px" : "30px"} color="#fff"/> 
            </Social>
          </SocialMedia>
          <PartnersWrapper>
            <a href="https://www.shopify.com/partners">
              <PartnersImg src='/shopify-partners.png'/>
            </a>
            <a href="https://www.google.com/partners/agency?id=8034224365" target="_blank" rel="noreferrer">
              <PartnersImg src='/footer-google-partners.png'/>
            </a>
            <a href="https://www.facebook.com/business/marketing-partners">
              <PartnersImg src='/footer-meta-partners.png'/>
            </a>
          </PartnersWrapper>
        </SectionsWrapper>
        {menuFooter.map((section, idx) => (
          <SectionsWrapper key={idx} white={white} style={isMobile ? {} : { marginTop: 24 }}>
            {section.title}
            {menuFooter[idx].content.map((info, idx) => (
              info.url ? (
                <Link className='change-link' key={idx} white={white} href={info.active && info.url} block={!info.active} target={(info.text === 'Blog' || info.text === "Turbo Class") ? '_blank' : '_self'} rel="noreferrer">
                  {info.text}
                  {!info.active && <AiFillLock size="18px" color={white ? '#342A41' : '#B5B5B5'}/>}
                </Link>
              ) : (
                <Info key={idx} white={white}>
                  {info.icon && <FooterIcon icon={info.icon} fill={white ? '#342A41' : ''}/>}
                  {info.text}
                </Info>
              )            
            ))}
          </SectionsWrapper>
        ))}
      </Row>      
      <TurboInfo white={white}>
        <span><b>Turbo</b> Partners 2023 - CNPJ: 42.100.292/0001-84</span>
        <span>Todos os direitos reservados</span>
      </TurboInfo>
    </FooterWrapper>
  );
};


export default Footer;
