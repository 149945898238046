import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(2.61vw) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(2.61vw) rotate(0deg); }
`

const floatation2 = keyframes`
	0%. 100% { transform: translateY(-2.61vw); }
  50%   { transform: translateY(2.61vw); }
`

const floatation2mobile = keyframes`
	0%. 100% { transform: translateY(-20px); }
  50%   { transform: translateY(20px); }
`

const HopesWrapper = styled.div`
  width: 100vw;
  padding: 2.92vw 0 3.75vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1440px) {
    padding: 42px 0 54px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 0 107px;
  }
`;

const Title = styled.h2`
  font-size: 1.875vw;
  line-height: 2.29vw;
  font-weight: 400;
  margin: 0;
  letter-spacing: -0.0216em;
  color: #f0f0f0;
  width: fit-content;

  @media screen and (max-width: 1440px) {
    font-size: 26px;
    line-height: 32px;
  }
`; 

const Subtitle = styled.div`
  margin: 1.59vw 0 3.7vw 0;

  @media screen and (max-width: 1440px) {
    margin: 22px 0 53px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`;

const HopesContent = styled.div`
  margin: 3.75vw auto 0;
  width: 54.69vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 22px;
  z-index: 4;
  transform: translateY(50vh);
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);

  @media screen and (max-width: 1440px) {
    margin: 54px auto 0;
    width: 787px;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    gap: 8px;
    margin: 38px auto 0;
  }
`; 

const HopesCard = styled.div`
  width: calc((100% - 46px)/3);
  height: 9.69vw;
  padding: 1.3vw;
  border-radius: 0.83vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    height: 140px;
    border-radius: 12px;
    padding: 19px;
  }

  @media screen and (max-width: 768px) {
    width: calc((100% - 8px)/2);
    max-width: 180px;
    height: 100px;
    padding: 12px 0;
    border-radius: 8px;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(45, 35, 56, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    border-radius: inherit;
    box-sizing: border-box;
    z-index: -1;
  }
`;  

const HopesText = styled.p`
  font-size: 1.25vw;
  line-height: 1.43vw;
  height: 2.86vw;
  margin: 0;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.0216em;
  text-align: center;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 20.5px;
    height: 41px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
    line-height: 15px;
    height: 30px;
  }
`;

const HopesIcon = styled.img`
  width: ${props => props.widthItem ? `calc(${props.widthItem}*100vw/1920)` : '5vw'};
  height: auto;

  @media screen and (max-width: 1440px) {
    width: ${props => props.widthItem ? `calc(${props.widthItem}px*0.75)` : '72px'};  
  }

  @media screen and (max-width: 768px) {
    width: ${props => props.widthItem ? `calc(${props.widthItem}px*0.53)` : '51px'};  
  }
`;

const HopesBackground = styled.img`
  width: 29.9vw;
  height: auto;
  position: absolute;
  top: 10.1vw;
  right: -9.89vw;
  animation: ${floatation} 14s linear infinite;

  @media screen and (max-width: 1440px) {
    width: 430px;
    top: 145px;
    right: -145px;
  }
  
  @media screen and (max-width: 1165px) {
    width: 37vw;
    top: 12.5vw;
    right: -12.5vw;
  }

  @media screen and (max-width: 768px) {
    width: 167px;
    bottom: 71px;
    z-index: 2;
  }
`;

const Line = styled.div`
  width: 4px;
  height: 90px;
  background: linear-gradient(180deg, rgba(120, 107, 227, 1) 0%, rgba(120, 107, 227, 0) 100%);
  margin: 0 auto;
`;

const ContractContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  column-gap: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: -90px 0 0 0;
  }
`;

const ContractImg = styled.img`
  width: 47.34vw;
  margin: -8vw 0 0 -5vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 682px;
    margin: -115px 0 0 -72px;
  }

  @media screen and (max-width: 1165px) {
    width: 58.54vw;
    margin: -9.9vw 0 0 -6.2vw;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: 458px;
  }
`;

const TextWrapper = styled.div`
  width: 23.5vw;
  margin-bottom: 4vw;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1.04vw;
  line-height: 1.28vw;
  font-weight: 400;
  letter-spacing: -0.0108em;
  color: #fff;

  @media screen and (max-width: 1440px) {
    width: 338px;
    margin-bottom: 58px;
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    margin: -91px 0 0 0;
    width: 100%;
    max-width: 420px;
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(84.4deg, #0E11DB 21.9%, #DA41A5 62.59%, #EC894B 95.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
  margin: 0;
`;

const VacanciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6.5vw 0 11.2vw 0;
  width: 100vw;
  box-sizing: border-box;
  row-gap: 3vw;
  position: relative;

  @media screen and (max-width: 1440px) {
    padding: 94px 0 161px 0;
    row-gap: 43px;
  }
  
  @media screen and (max-width: 768px) {
    padding: 192px 0 128px 0;
    row-gap: 53px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: nowrap;
  width: 54.69vw;
  column-gap: 3.65vw;
  
  @media screen and (max-width: 1440px) {
    width: 787px;
    max-width: calc(100% - 200px);
    min-width: 600px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 18px;
    flex-wrap: wrap;
    align-items: start;
    width: 70%; 
    min-width: unset;
    max-width: unset;
  }

  @media screen and (max-width: 572px) {
    width: 266px;
  }
`;

const HorizontalLine = styled.div`
  width: 17.15vw;
  height: 1px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(120, 107, 227, 0) 100%);
  margin: 0 0 7px 0;
  
  @media screen and (max-width: 1440px) {
    width: 245px;
    max-width: calc(100vw - 860px);
    min-width: calc(100% - 489px - 3.65vw);
  }
  
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
    z-index: 0;
    min-width: unset;
    max-width: unset;
  }
`;

const AstronautImg = styled.img`
  width: 67.81vw;
  height: auto;
  position: absolute;
  z-index: 2;
  top: -24.79vw;
  right: 0;
  animation: ${floatation2} 8s ease-in-out infinite;
  
  @media screen and (max-width: 1440px) {
    width: 976px;
    top: -357px;
    right: calc((100vw - 1440px)/2);
  }

  @media screen and (max-width: 1165px) {
    width: 83.78vw;
    top: calc(-30.64vw + (1065px - 100vw)/10);
    right: calc(-13.73vw - (1065px - 100vw)/10);
  }

  @media screen and (max-width: 768px) {
    width: 390px;
    top: -18px;
    right: 0;
    z-index: 1;
    animation: ${floatation2mobile} 8s ease-in-out infinite;
  }
`;

const JobsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 54.69vw;
  gap: 22px;
  padding: 1px 0;
  z-index: 1;
  transform: translateX(-100vw);
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);

  @media screen and (max-width: 1440px) {
    width: 787px;
    max-width: calc(100% - 200px);
    min-width: 600px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    gap: 8px;
    z-index: 2;
    min-width: unset;
    max-width: unset;
  }
`;

const TalentsContract = ({ isMobile }) => {

  const hopes = [
    {
      icon: '/have-fun.png',
      text: 'Se divirta',
      width: 68
    },
    {
      icon: '/team-work.png',
      text: 'Trabalhe em equipe',
      width: 58
    },
    {
      icon: '/responsibility.png',
      text: 'Responsabilidade Individual',
      width: 60
    },
    {
      icon: '/proactivity.png',
      text: 'Tenha Proatividade',
      width: 76
    },
    {
      icon: '/organization.png',
      text: 'Seja Organizado',
      width: 76
    },
    {
      icon: '/work-hard.png',
      text: 'Trabalhe Duro',
      width: 73
    }
  ];

  const jobs = [
    {
      icon: '/job-dev.png',
      text: 'Desenvolvedor',
    },
    {
      icon: '/job-uiux.png',
      text: 'UI/UX Designer',
    },
    {
      icon: '/job-designer.png',
      text: 'Designer',
    },
    {
      icon: '/job-perfomance.png',
      text: 'Gestor de Perfomance',
    },
    {
      icon: '/job-financeiro.png',
      text: 'Financeiro',
    },
    {
      icon: '/job-copywriter.png',
      text: 'Copywriter',
    }
  ];

  function handleWindowScroll() {
    const hopesCards = document.getElementById('hopes-cards').getBoundingClientRect();
    const jobsCards = document.getElementById('jobs-cards').getBoundingClientRect();
    
    if(hopesCards.top < 1.5*window.innerHeight) {
      document.getElementById('hopes-cards').style.transform = 'translateY(0)';
    }
    if(jobsCards.top < 1.5*window.innerHeight) {
      document.getElementById('jobs-cards').style.transform = 'translateX(0)';
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <>
      <HopesWrapper className='page-width'>
        <Title>
          O que <b>esperamos</b> de você
        </Title>
        <HopesContent id="hopes-cards">
          {hopes.map((item, index) => (
            <HopesCard key={index}>
              <HopesIcon src={item.icon} widthItem={item.width}/>
              <HopesText>{item.text}</HopesText>
            </HopesCard>
          ))}
        </HopesContent>
        {!isMobile && <HopesBackground src='/hopes-background.png'/>}
      </HopesWrapper>
      <Line/>
      <ContractContent className='page-width'>
        <ContractImg src={isMobile ? '/contract-image_mobile.png' : '/contract-image.png'}/>
        <TextWrapper>
          <Title style={{ textAlign: 'left', fontSize: isMobile ? '31px' : '', lineHeight: isMobile ? '38px' : '' }}>
            Contratamos <GradientTitle>caráter</GradientTitle>, treinamos <GradientTitle>habilidades</GradientTitle>
          </Title>
          <Subtitle>
            Claro... habilidades técnicas são, sim, importantes. Mas pode ter certeza: estamos muito mais interessados nos seus <b>valores</b> e nas suas <b>competências comportamentais</b> ;)
          </Subtitle>
          <ContactButton width={232} link="https://turbopartners.vagas.solides.com.br/" target="_blank" text="Quero fazer parte" solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 92.58%, #EC894B 173.78%)' icon='rocket' iconHeight={16.5}/>
        </TextWrapper>
      </ContractContent>
      <VacanciesWrapper className='page-width'>
        <TitleRow>
          <Title style={isMobile ? { textAlign: 'left', fontSize: '32px', lineHeight: '39px', zIndex: 2 } : { width: 'max-content' }}><b>{isMobile ? 'Vagas' : 'Algumas vagas'}</b> em que contratamos</Title>
          <HorizontalLine/>
        </TitleRow>
        <JobsWrapper id="jobs-cards">
          {jobs.map((job, index) => (
            <HopesCard key={index}>
              <HopesIcon src={job.icon}/>
              <HopesText style={{ height: 'unset', fontWeight: 500, fontSize: isMobile ? '14px' : '' }}>{job.text}</HopesText>
            </HopesCard>
          ))}
        </JobsWrapper>
        <AstronautImg src={isMobile ? '/astronaut-moon_mobile.png' : '/astronaut-moon.png'}/>
      </VacanciesWrapper>
    </>
  );
};

export default TalentsContract;

