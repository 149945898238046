import React from 'react';
import styled, { keyframes } from 'styled-components';
import CasesCard from "../components/CasesCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';

const swipe = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`;

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const CasesWrapper = styled.div`
  padding: 100px 0;
  box-sizing: border-box;
  row-gap: 50px;
  overflow: hidden;
    
  .swiper {
    overflow: hidden;
    width: 100vw;
    cursor: grab;
    position: relative;
  }

  .swiper-wrapper {
    display: flex;
    transition-timing-function : linear;
  }

  @media screen and (max-width: 768px) {
    row-gap: 32px;
  }
`;

const SwiperManual = styled.div`
  overflow: auto;
  padding: 1px 0;
  position: relative;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  width: max-content;
  padding: 0 16px;
  gap: 15px;
  animation: ${swipe} 32s linear infinite;
`;

const FadeOutEffect = styled.div`
  width: 13.02vw;
  height: 520px;
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 5;
  background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: calc((100vw - 1065px)/2);
    min-width: 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CommerceCases = ({ isMobile }) => {

  const cases = [
    {
      img: '/get-power_card-image.png',
      logo: '/logo_get-power.png',
      content: '0 a 5 milhões em vendas mensais'
    },
    {
      img: '/academia-do-sol_card-image.png',
      logo: '/logo_academia-do-sol.png',
      content: '100 mil em vendas em apenas 1 dia'
    },
    {
      img: '/brota_card-image.png',
      logo: '/logo_brota.png',
      content: '+200k de receita na black friday'
    },
    {
      img: '/carla-lorenzon_card-image.png',
      logo: '/logo_carla-lorenzon.png',
      content: 'De 3.8k seguidores para 78k em um mês e meio'
    },
    {
      img: '/luma_card-image.png',
      logo: '/logo_luma.png',
      content: 'Redução de 60% no CAC com triplo de investimento mensal'
    },
    {
      img: '/byr_card-image.png',
      logo: '/logo_byr.png',
      content: 'Aumento de 900% do faturamento'
    },
    {
      img: '/nina-saude_card-image.png',
      logo: '/logo_nina-saúde.png',
      content: 'Aumento do faturamento em 158% em um semestre'
    }
  ];

  const [initAutoplay, setInitAutoplay] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  function countTo() {
    let from = 0;
    let to = 70;
    let step = 1;
    let interval = 2000/70;

    if(from === to) {
      document.getElementById('counter').textContent = from;
      return;
    }

    let counter = setInterval(function(){
      from += step;
      document.getElementById('counter').textContent = from;

      if(from === to) {
        clearInterval(counter)
      }
    }, interval);
  }

  function handleEffects() {
    // Efeito aparecer carrossel
    const casesSectionTop = document.getElementById('cases-section').getBoundingClientRect().top;

    if (casesSectionTop <= 0.8 * window.innerHeight) {
      setInitAutoplay(true);
      if (!isMobile && document.getElementById('cases-cards')) {
        document.getElementById('cases-cards').style.transform = 'translateX(0)';
      }
    };

    const counter = document.getElementById('counter');
    const counterTop = counter.getBoundingClientRect().top;
    if (counterTop < 0.9*window.innerHeight && counter.textContent === ' ' ) {
      countTo();
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleEffects);

    return () => {
      window.removeEventListener('scroll', handleEffects);
    }
  });

  return (
    <PageWrapper className='flex column align-center'>
      <CasesWrapper id="cases-section" className='flex column full-width'>
        <Title font={isMobile ? 28 : 40}>
          Cases de Sucesso
        </Title>
        <div style={{ height: isMobile ? '370px' : '450px' }}>
          {initAutoplay &&
            (isMobile ? (
            <SwiperManual className='full-width'>
              <SwiperWrapper className='flex nowrap' onTouchStart={() => setClicked(true)} style={clicked ? { animationPlayState: 'paused', animationName: 'none', animationFillMode: 'none' } : {}}>
                {cases.map((item, index) => (
                  <div key={index}>
                    <CasesCard cases={item} gradient='linear-gradient(179.75deg, rgba(11, 4, 27, 0) -31.38%, #0B041B 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid #1F1C3C' line='#1F1C3C'/>
                  </div>
                ))}
              </SwiperWrapper>
              <FadeOutEffect />
            </SwiperManual>
          ) : (
            <Swiper
              modules={[Autoplay]}
              id="cases-cards"
              spaceBetween={32}
              slidesPerView={"auto"}
              autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
              freeMode={true}
              speed={2000}
              slidesOffsetBefore={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              slidesOffsetAfter={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              style={{ transform: 'translateX(100vw)', transition: 'all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88)' }}
            >
              {cases.map((item, index) => (
                <SwiperSlide key={index}>
                  <CasesCard cases={item} gradient='linear-gradient(179.75deg, rgba(11, 4, 27, 0) -31.38%, #0B041B 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid #1F1C3C' line='#1F1C3C'/>
                </SwiperSlide>
              ))}
              <FadeOutEffect />
            </Swiper>
          ))
          }
        </div>
      </CasesWrapper>
    </PageWrapper>
  );
};

export default CommerceCases;
