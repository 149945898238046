import React from 'react';
import Header from '../sections/Header';
import ThanksMessage from '../sections/ThanksMessage';
import Footer from '../sections/Footer';

const ThankYou = ({ isMobile, isTablet }) => {
  return (
    <div style={{ backgroundColor: '#08031A', overflowX: 'hidden' }}>
      <Header  isTablet={isTablet}/>
      <ThanksMessage isMobile={isMobile} />
      <Footer isMobile={isMobile}  background={'#060613'}/>
    </div>
  )
};

export default ThankYou;
