import React from 'react';
import styled  from 'styled-components';
import ContactButton from '../components/ContactButton';

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const TextWrapper = styled.div`
  width: ${props => props.width};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const CancelWrapper = styled.div`
  padding: 81px 0; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 90px 0 43px; 
  }
`;

const CancelBanner = styled.div`
  gap: 56px; 
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #4100C8 0%, #C052F3 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
  }

  @media screen and (max-width: 1440px) {
    gap: 0px;
  }
`;

const CancelImg = styled.img`
  width: 32.6%;
  height: auto;
  align-self: end;
  margin-top: -5.9%;

  @media screen and (max-width: 1440px) {
    width: 335px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: -8%;
    padding: 0 10px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #4100C8 0%, #C052F3 100%);
    background-size: 100% 2px;
    background-position-y: 100%;
    background-repeat: no-repeat;     
  }
`;

const CommerceCancele = ({ isMobile }) => {
  
  return (
    <PageWrapper className='flex column align-center'>
      <CancelWrapper className='page-width'>
        <CancelBanner className='flex row wrap align-center justify-center'>
          <CancelImg src='/commerce/cancel-image.png'/>
          <TextWrapper width='637px' className='flex column text-center align-center' style={{ padding: isMobile ? '20px 27px 27px' : '20px 20px 30px 0', maxWidth: isMobile ? '' : 'calc(100% - 355px)' }}>
            <Title font={isMobile ? 28 : 40}>
              Cancele quando quiser
            </Title>
            <Subtitle font={isMobile ? 16 : 20}>
              Se, por qualquer motivo, você não estiver 100% satisfeito com o nosso serviço, basta solicitar o cancelamento do contrato em 30 dias
            </Subtitle>
            <ContactButton className="change-link" icon="arrow" width={338} height={60} link='#redirect' solidButton={true} text="Começar agora sem risco" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '32px', width: isMobile && '100%' }}/>
          </TextWrapper>
        </CancelBanner>
      </CancelWrapper>
    </PageWrapper>
  );
};

export default CommerceCancele;
