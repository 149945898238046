import React from 'react';
import styled from 'styled-components';

const Subitem = styled.li`
  list-style: none;
  width: 100%;
`;

const SubitemLink = styled.a`
  text-decoration: none;
  color: ${props => props.white ? '#08031A' : '#E8E8E8'};
  display: flex;
  align-items: start;
  gap: 16px;
  cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};

  @media screen and (max-width: 768px) {
    align-items: center;
    gap: 22px;
  }
`;

const SubitemIcon = styled.img`
  width: 29.5%;
  height: auto;

  @media screen and (max-width: 768px) {
    width: 84px;
  }
`;

const SubitemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-family: 'Graphik', sans-serif;
  color: rgba(200, 197, 204, 0.8);

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const SubitemTitle = styled.p`
  font-weight: 700;
  line-height: 120%;
  font-size: 20px;
  font-family: 'Sora', sans-serif;
  margin: 0;
  color: rgba(254, 254, 254, 1);

  ${SubitemLink}:hover && {
    text-decoration: ${props => (props.active ? 'underline' : 'none')};
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const SubmenuItem = ({ submenu }) => {

    return (
        <Subitem>
            <SubitemLink className='change-link' active={submenu.url} href={submenu.url ? submenu.url : undefined} target={submenu.targetOut ? '_blank' : '_self'}>
                <SubitemIcon src={submenu.icon} alt={submenu.title}/>
                <SubitemText>
                    <SubitemTitle active={submenu.url}>{submenu.title} {!submenu.url && '🔒'}</SubitemTitle>
                    <span>{submenu.text}</span>
                </SubitemText>
            </SubitemLink>
        </Subitem>
    )

}

export default SubmenuItem;