import React from 'react';
import styled from 'styled-components';
import BrandsCard from '../components/BrandsCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";


const BrandsWrapper = styled.div`
  border-bottom: 1px solid #181336;
  padding-top: 8.85vw;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 70px;
  overflow-x: hidden;
  position: relative;
  z-index: 2;

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    cursor: grab;
    padding-bottom: 8.85vw;
  }

  @media screen and (max-width: 1440px) {
    padding-top: 127px;
    row-gap: 50px;

    .swiper-wrapper {
      padding-bottom: 127px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 58px;
  }
`;

const SwiperManual = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1px 0;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  padding: 0 16px 58px;
  gap: 23px;
`;

const Title = styled.h2`
  align-self: center;
  font-size: 2.08vw;
  line-height: 2.54vw;
  font-weight: 800;
  margin: 0;
  color: #fff;
  letter-spacing: -0.032em;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
  }
`;

const GradientEffect = styled.div`
  background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);
  height: 380px;
  width: 13.02vw;
  position: absolute;
  top: calc(11.39vw + 60px);
  right: 0;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: calc((100vw - 1065px)/2);
    min-width: 50px;
    top: 203px;
  }

  @media screen and (max-width: 768px) {
    min-width: unset;
    width: 80px;
    height: 473px;
    top: 90px;
  }
`;

const Brands = ({ isMobile }) => {

  const brandsInfo = [
    {
      title: 'Commerce',
      content: 'Aceleramos as vendas do seu negócio. Somamos dados, tecnologia e marketing para construção e escala da sua máquina de vendas.',
      url: '/commerce',
      color: '#A26BE3',
      shadow: 'rgba(162, 107, 227, 0.2)',
      icon: 'TurboCommerce_2.svg',
      button: 'Acelerar Vendas',
      active: true
    },
    {
      title: 'Shopify',
      content: 'Combinamos as melhores estratégias e práticas de performance digital para criar uma loja virtual Shopify altamente conversiva para o seu negócio.',
      url: 'https://lp.turbopartners.com.br/shopify/home',
      color: '#95BF47',
      shadow: 'rgba(149, 191, 71, 0.38)',
      icon: 'turbo_shopify.svg',
      button: 'Veja mais',
      active: true
    },
    {
      title: 'Tech',
      content: 'Desenvolvemos lojas virtuais, landing pages, websites e soluções on-demand para de marcas de alto crescimento.',
      url: '/tech',
      color: '#0E5CC9',
      shadow: 'rgba(14, 92, 201, 0.2)',
      icon: 'TurboTech_2.svg',
      button: 'Iniciar Projeto',
      active: true
    },
    {
      title: 'Dash',
      content: 'Tenha fácil acesso às principais métricas do seu negócio e maximize o seu retorno sobre investimento em anúncios online.',
      url: '/Dash',
      color: '#5B4EEE',
      shadow: 'rgba(91, 78, 238, 0.2)',
      icon: 'TurboDashboard_2.svg',
      button: 'Visualizar Dashboard',
      active: true
    },
    {
      title: 'Creators',
      content: 'Conectamos marcas e criadores de conteúdo, assim construímos criativos que convertem!',
      url: '/creators',
      color: 'linear-gradient(90deg, #27F5B0 0%, #A26BE3 100%)',
      shadow: 'rgba(162, 107, 227, 0.2)',
      icon: '/TurboCreators_2.svg',
      button: 'Obter Criativos',
      active: true
    },
    {
      title: 'Class',
      content: 'A escola de negócios mais completa do país. Alavancamos pessoas e negócios através de conhecimento.',
      url: 'https://lp.turbopartners.com.br/class/cv2',
      color: '#B25956',
      shadow: 'rgba(178, 89, 86, 0.2)',
      icon: 'TurboClass_2.svg',
      button: 'Matricule-se',
      active: true
    },
    {
      title: 'Ventures',
      content: 'Transformamos bons negócios em extraordinários. Se você tem um negócio fora de série, queremos ser sócios!',
      url: '/ventures',
      color: '#159B81',
      shadow: 'rgba(39, 245, 176, 0.2)',
      icon: 'TurboVentures_2.svg',
      button: 'Em Breve',
      active: false
    },
    // {
    //   title: 'Fulfillment',
    //   content: 'Terceirize a logística do seu e-commerce, reduzindo os seus custos e tempo de envio.',
    //   url: '/fulfillment',
    //   color: '#B25956',
    //   shadow: 'rgba(178, 89, 86, 0.2)',
    //   icon: 'menu_fulfillment.svg',
    //   button: 'Em Breve',
    //   active: false
    // },
    {
      title: 'Bank',
      content: 'Crédito descomplicado para exponencializar o crescimento do seu negócio',
      url: '/bank',
      color: '#FFD780',
      shadow: 'rgba(255, 215, 128, 0.2)',
      icon: 'TurboBank_2.svg',
      button: 'Em Breve',
      active: false
    }
  ];

  return (
    <BrandsWrapper>
      <Title>
        Braços da Turbo
      </Title>
      {isMobile ? (
        <SwiperManual>
          <SwiperWrapper>
            {brandsInfo.map((brand, index) => (
              <BrandsCard brand={brand} isMobile={isMobile} key={index}/>
            ))}
          </SwiperWrapper>
        </SwiperManual>
      ) : (
        <Swiper
          id="brands-cards"
          modules={[FreeMode]}
          spaceBetween={30}
          slidesPerView={"auto"}
          freeMode={true}
          slidesOffsetBefore={(window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
          slidesOffsetAfter={(window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50))}
          breakpoints={{
            0: {
              spaceBetween: 23
            },
            768: {
              spaceBetween: 30
            }
          }}
        >
          {brandsInfo.map((brand, index) => (
            <SwiperSlide key={index}>
              <BrandsCard brand={brand} isMobile={isMobile}/>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <GradientEffect/>
    </BrandsWrapper>
  );
};

export default Brands;

