import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const rotation = keyframes`
	0% { transform: rotate(0deg); }
  100%   { transform: rotate(360deg); }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const UGCWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: ${props => props.index === 1 ? 'end' : 'start'};
  width: 100%;
  padding: ${props => props.index === 1 ? '26px 0 0 0' : '0 0 105px 0'};

  @media screen and (max-width: 1440px) {
    max-height: 611px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: ${props => props.index === 1 ? 'column-reverse' : 'column'};
    align-items: center;
    justify-content: start;
    max-height: unset;
    padding: ${props => props.index === 1 ? '38px 0 0 0' : '0 0 63px 0'};
  }
`;

const StopWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #08031A;
  border-radius: 32px;
  padding-bottom: 62px;
  margin: 50px 0 100px;

  @media screen and (max-width: 768px) {
    margin: 118px 0 80px;
  }
`;

const StopDots = styled.div`
  display: flex;
  column-gap: 12px;
  box-sizing: border-box;
  width: 100%;
  padding: 23px 0 30px 30px;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 50px;

  div {
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }

  div:nth-child(1) {
    background: #D64036;
  }

  div:nth-child(2) {
    background: #FFC64D;
  }

  div:nth-child(3) {
    background: #53D248;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.stop ? 'center' : ''};
  box-sizing: border-box;
  align-self: ${props => props.stop ? 'center' : ''};
  width: ${props => props.stop ? '42.86vw' : '34.94vw'};
  text-align: ${props => props.stop ? 'center' : 'left'};
  color: ${props => props.stop ? '#fff' : '#342A41'};
  padding: ${props => props.stop ? '' : (props.index === 1 ? '0 0 13.02vw' : '6.09vw 0 0')};
  max-width: 100%;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: ${props => props.stop ? '617px' : '506px'};
  }

  @media screen and (max-width: 936px) {
    padding: ${props => props.stop ? '' : (props.index === 1 ? '0 0 5vw' : '0')};
  }

  @media screen and (max-width: 768px) {
    padding: ${props => props.stop ? '0 18px' : '0'};
    align-items: center;
  }
`;

const GradientTitle = styled.span`
  background: ${props => props.index === 1 ? 'linear-gradient(89.74deg, #27F5B0 0%, #6163F0 99%, #BD49FF 102%)' : 'linear-gradient(89.74deg, #27F5B0 0%, #6163F0 202%, #BD49FF 205%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
`;

const SectionTitle = styled.h3`
  margin: 0;
  font-size: 2.5vw;
  line-height: 2.92vw;
  font-weight: 700;
  font-family: 'Paralucent';
  width: 100%;
  z-index: 2;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;

const SectionText = styled.p`
  margin: 16px 0 32px 0;
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 400;
  width: 100%;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const UGCImage = styled.img`
  width: 32.55vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 470px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 360px;
  }
`;

const UGCTests = styled.div`
  width: 31.25vw;
  height: 31.93vw;
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 0.99vw;
  background: url('/creators/testes-image.png') no-repeat;
  background-size: contain;
  box-sizing: border-box;

  @media screen and (max-width: 1440px) {
    width: 450px;
    height: 460px;
    gap: 14px;
  }

  @media screen and (max-width: 768px) {
    width: 358px;
    height: 366px;
    max-height: calc((100vw - 32px)*1.022);
    max-width: calc(100vw - 32px);
    background: url('/creators/testes-image_mobile.png') no-repeat;
    gap: 8px;
  }
`;

const GifContainer = styled.div`
  border: 2px solid #342A41;
  border-radius: 16px;
  margin-bottom: ${props => props.index === 2 ? '2.97vw' : ''};
  padding: 2.6vw 0;

  @media screen and (max-width: 1440px) {
    margin-bottom: ${props => props.index === 2 ? '43px' : ''};
    padding: 37px 0;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: ${props => props.index === 2 ? '38px' : ''};
    padding: 30px 0;
  }

`;

const GifWrapper = styled.div`
  width: 15.03vw;
  height: 18.25vw;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-top: 2px solid #342A41;
  border-bottom: 2px solid #342A41;

  @media screen and (max-width: 1440px) {
    width: 216px;
    height: 263px;
  }

  @media screen and (max-width: 768px) {
    width: 173px;
    height: 210px;
    max-width: calc((100vw - 44px)/2);
    max-height: calc((100vw - 44px)*1.21/2);
  }
`;

const UGCGif = styled.img`
  width: 100%;
  height: auto;
`;

const Star = styled.img`
  width: ${props => props.index === 1 ? '11.72vw' : '13.91vw'};
  height: auto;
  position: absolute;
  left: ${props => props.index === 1 ? '0' : 'unset'};
  right: ${props => props.index === 2 ? '0' : 'unset'};
  bottom: 0;
  border-radius: ${props => props.index === 1 ? '0 0 0 32px' : '0 0 32px 0'};

  @media screen and (max-width: 1440px) {
    width: ${props => props.index === 1 ? '169px' : '200px'};
  }

  @media screen and (max-width: 768px) {
    width: ${props => props.index === 1 ? '47px' : '93px'};
    top: ${props => props.index === 1 ? '80px' : ''};
    border-radius: ${props => props.index === 1 ? 'unset' : ''};
  }
`;

const SealWrapper = styled.div`
  width: 14.3vw;
  height: 14.3vw;
  padding: 1px;
  border-radius: 100%;
  position: absolute;
  bottom: 3vw;
  left: 28.3%;

  @media screen and (max-width: 1440px) {
    width: 206px;
    height: 206px;
  }

  @media screen and (max-width: 940px) {
    width: 116px;
    height: 116px;
    bottom: unset;
    left: unset;
    right: -50px;
    top: 0;
  }

  @media screen and (max-width: 768px) {
    right: -26px;
  }
`;

const SealWrapperInner = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const CircleText = styled.div`
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: #000;
  letter-spacing: 0.19em;
  animation: ${rotation} 8s linear infinite;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 8.49vw;
  height: 8.49vw;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1440px) {
    width: 122px;
    height: 122px;
  }  

  @media screen and (max-width: 940px) {
    width: 69px;
    height: 69px;
  }
`;

const StarImage = styled.img`
  width: 100%;
  height: auto;
`;

const CreatorsUGC = ({ isMobile }) => {
  
  return (
    <SectionWrapper className="page-width">
      <UGCWrapper index={1}>
        <SealWrapper>
          <SealWrapperInner>
            <CircleText>
              <svg viewBox="0 0 207 207">
                <path 
                  id="curve"
                  d="
                    M 34, 104
                    a 70,70 0 1,1 140,0
                    a 70,70 0 1,1 -140,0
                    "
                  fill="transparent"
                />
                <text fill="#000">
                  <textPath xlinkHref="#curve">
                    ROAS 5X MAIOR • ROAS 5X MAIOR • ROAS 5X MAIOR •&nbsp;
                  </textPath>
                </text>
              </svg>
            </CircleText>
            <ImageWrapper>
              <StarImage src={'/creators/star-image.png'}/>
            </ImageWrapper>
          </SealWrapperInner>
        </SealWrapper>
        <TextWrapper index={1}>
          <SectionTitle style={{ color: '#F0F1F3', webkitTextStroke: '1px #342A41', marginTop: isMobile ? '16px' : '' }}>
            O poder dos UGCs
          </SectionTitle>
          <SectionTitle>
            O poder dos UGCs
          </SectionTitle>
          <SectionText>
            User Generated Content. Ou "Conteúdo Gerado por Usuário — pros mais íntimos.
            <br/><br/>
            O segredo pra maximizar a performance das suas campanhas está na estratégia por trás dos anúncios que <GradientTitle index={1}>não têm cara de anúncio.</GradientTitle>
          </SectionText>
          <ContactButton width={217} height={56} link="#packages" text="Iniciar Projeto" font="'Paralucent'" fontSize={20} solidButton={true} background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' borderRadius={12}/>
        </TextWrapper>   
        <UGCImage loading="lazy" src='/creators/UGC-image.png'/>   
      </UGCWrapper>
      <StopWrapper>
        <StopDots>
          <div></div>
          <div></div>
          <div></div>
        </StopDots>
        <TextWrapper stop={true}>
          <SectionTitle style={{ padding: isMobile ? '0 12px' : '' }}>
            <GradientTitle index={2}>PARE AGORA</GradientTitle> de rolar para baixo! ✋
          </SectionTitle>
          <SectionText>
            Assim como esse título prendeu a sua atenção, o mesmo precisa acontecer com seus <b>criativos em vídeo</b>.
            <br/><br/>
            Os <b>primeiros 3 segundos</b> determinam se ele será visto, ou ignorado. Bora maximizar a retenção e conversão deles? 👇
          </SectionText>
          <ContactButton width={217} height={56} link="#packages" text="Iniciar Projeto" font="'Paralucent'" fontSize={20} solidButton={true} background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' borderRadius={12}/>
        </TextWrapper>
        <Star index={1} src={isMobile ? '/creators/stop-star-1_mobile.png' : '/creators/stop-star-1.png'}/>
        <Star index={2} src={isMobile ? '/creators/stop-star-2_mobile.png' : '/creators/stop-star-2.png'}/>
      </StopWrapper>
      <UGCWrapper index={2} style={{ gap: '20px' }}>
        <UGCTests>
          <GifContainer>
            <GifWrapper>
              <UGCGif loading="lazy" src={isMobile ? require('../assets/peppy_mobile.gif') : require('../assets/peppy.gif')}/>
            </GifWrapper>
          </GifContainer>
          <GifContainer index={2}>
            <GifWrapper>
              <UGCGif loading="lazy" src={isMobile ? require('../assets/peppy2_mobile.gif') : require('../assets/peppy2.gif')}/>
            </GifWrapper>
          </GifContainer>
        </UGCTests>
        <TextWrapper index={2}>
          <SectionTitle style={{ color: '#F0F1F3', webkitTextStroke: '1px #342A41', marginTop: isMobile ? '16px' : '' }}>
            Testes e mais testes
          </SectionTitle>
          <SectionTitle>
            Testes e mais testes
          </SectionTitle>
          <SectionText>
            O que nos fez descobrir os segredos dos criativos de alta-conversão foi: um <GradientTitle index={1}>plano robusto de testes A/B.</GradientTitle> 
            <br/><br/>
            Nosso know-how te garante combinações vencedoras de <b>Gancho + Conteúdo + CTA</b> que farão o seu ROAS explodir.
          </SectionText>
          <ContactButton width={217} height={56} link="#packages" text="Iniciar Projeto" font="'Paralucent'" fontSize={20} solidButton={true} background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 45.92%, #BD49FF 124.27%)' borderRadius={12}/>
        </TextWrapper>      
      </UGCWrapper>
    </SectionWrapper>
  );
};

export default CreatorsUGC;
