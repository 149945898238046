import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const BannerWrapper = styled.div`
  background: linear-gradient(180deg, rgba(8, 3, 26, 0) 58.19%, #08031A 100%);
  width: 100%;
  position: relative;

  &:before {
    width: 100%;
    height: 100%;
    content: ' ';
    position: absolute;
    display: block;
    background-image: url('/banner-principal.png');
    background-size: contain;
    background-repeat: no-repeat;
    
    @media screen and (max-width: 768px) {
      background-image: url('/banner-principal_mobile.png');
      background-position: top;
      background-size: 100vw 89vw;
    }
  }
`; 

const BannerContent = styled.div`
  padding: 15.73vw 0 13.59vw 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1440px) {
    padding: 226px 0 196px 0;
  }

  @media screen and (max-width: 1165px) {
    padding: 120px 0 100px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 0 0 30px 0;
    flex-direction: column-reverse;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 1.67vw;
  width: 34vw;
  color: #f0f0f0;
  z-index: 2;
  letter-spacing: -0.0108em;

  @media screen and (max-width: 1440px) {
    row-gap: 24px;
    width: 490px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 405px;
    row-gap: 12px;
    text-align: center;
    align-items: center;
    margin-top: -150px;
  }
`;

const Title = styled.h2`
  font-size: 2.91vw;
  line-height: 3.54vw;
  font-weight: 800;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 42px;
    line-height: 50px;
  }

  @media screen and (max-width: 768px) {
    font-size: 30px;
    line-height: 39px;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25vw;
  line-height: 1.67vw;
  margin: 0;
  color: #EDEDED;
  font-weight: 400;

  @media screen and (max-width: 1440px) {
    font-size: 17.8px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    opacity: 0.7;
  }
`;

const BannerImgWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100vw;
  height: 138.6%;
  position: absolute;
  top: 0;
  right: 0; 
  overflow: hidden;

  @media screen and (max-width: 768px) {
    height: 541px;
    min-height: 94vw;
    position: relative;
    top: -60px;
  }
`;

const BannerImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: right;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    margin-right: calc((100% - 1440px)/2)
  }

  @media screen and (max-width: 1165px) {
    margin: calc((1065px - 100%)/20) -12% 0 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    object-fit: cover;
    object-position: center;
  }
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 1px;
  background-color: #181336;
  z-index: 1;
`;

const Hero = ({ isMobile }) => {
  
  // React.useEffect(() => {
  //   const words = ['Criamos', 'Investimos em', 'Aceleramos'];
  //   let i = 0;

  //   function typingEffect() {
  //     let word = words[i].split('');
  //     var loopTyping = function () {
  //       if (word.length > 0) {
  //         document.getElementById('word').innerHTML += word.shift();
  //       } else {
          
  //       setTimeout(deletingEffect, 500);
  //         return false;
  //       }
  //       setTimeout(loopTyping, 200);
  //     };
  //     loopTyping();
  //   }

  //   function deletingEffect() {
  //     let word = words[i].split('');
  //     var loopDeleting = function () {
  //       if (word.length > 0) {
  //         word.pop();
  //         document.getElementById('word').innerHTML = word.join('');
  //       } else {
  //         if (words.length > i + 1) {
  //           i++;
  //         } else {
  //           i = 0;
  //         }
  //         typingEffect();
  //         return false;
  //       }
  //       setTimeout(loopDeleting, 100);
  //     };
  //     loopDeleting();
  //   }

  //   typingEffect();
  // }, []);

  return (
    <BannerWrapper>
      <BannerContent className="page-width">
        <TextWrapper>
          <Title>
            {/* <span id="word"></span><span className="blink">|</span><br/> */}
            Criamos, investimos e aceleramos 
            negócios
          </Title>
          <Subtitle>
          Estamos construindo o mais robusto ecossistema de negócios digitais do Brasil
          </Subtitle>
          <ContactButton width={217} link="https://lp.turbopartners.com.br/commerce/lp4-2" target="_blank" text='Turbinar agora' solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 92.58%, #EC894B 173.78%)' icon='rocket' iconHeight={16.5}/>
        </TextWrapper>
        <BannerImgWrapper>
          <BannerImg src={isMobile ? 'hero_image-mobile.png' : 'hero_image.png'}/>
        </BannerImgWrapper>
        {/* <BannerGif src={require('../assets/hero_gif.gif')}/> */}
      </BannerContent>
      <Line> </Line>
    </BannerWrapper>
  );
};

export default Hero;

