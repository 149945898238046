import React from 'react';
import styled from 'styled-components';

const DashContainer = styled.div`
  overflow: hidden;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
`;
const DashWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 0 0;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    padding: 50px 0 0 0;
    height: auto;
  }
`; 

const TextWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  font-family: 'Sora';
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 150%;
  align-items: Left;
  vertical-align: Top;
  width: 100%;
  margin: 0 0 24px 0;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 35px;
    max-width: 90%;
    margin: 0;
  }
`;

const ReviewContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch; 
  gap: 30px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc((100% - 100px)/3);
  box-sizing: border-box;
  padding: 1.875vw 1.4vw;
  background: rgba(45, 35, 56, 0.1);
  border: 2px solid #12192B;
  border-radius: 8px;
  gap: 10px;
  font-weight: 400;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: -0.0216em;
  text-align: left;

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};

  p {
    margin: 0;
  }

  @media screen and (max-width: 1440px) {
    padding: 27px 20px; 
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 36px 28px; 
    font-size: 16px;
    line-height: 19px;
    gap: 48px;
    margin: 0 auto;
  }
`;

const ReviewText = styled.p`
  width: 100%;
  font-family: 'Graphik', sans-serif;
  background: #F2EDF9;
  font-size: 20px;
  line-height: 130%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }

`;

const ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Graphik', sans-serif;
  width: 100%;
  border-image: linear-gradient(88.92deg, #5C4FED -15.8%, #3C368A 125.72%) 30;
  border-top: 1px solid;
  padding-top: 20px;
`;

const ReviewPhoto = styled.img`
  width: 44px;
  height: 44px;

  @media screen and (max-width: 768px) {
    width: 51px;
    height: 51px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
const ReviewName = styled.h4`
  font-size: 1.25vw;
  font-family: 'Graphik', sans-serif;
  line-height: 1.46vw;
  font-weight: 500;
  color: #ffffff;
  margin: 0;  

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }
`;


const DashComments = ({ isMobile }) => {

  const reviews = [
    {
      text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
      image: '/client-1_photo.png',
      name: 'Victor Peixoto',
      job: 'CEO da GetPower Solar'
    },
    {
      text: 'Eu não sabia que a minha empresa precisava tanto da Turbo até conhecer eles, excelência no atendimento e nos resultados que são demonstrados dia após dia! A Byr está em constante crescimento e a Turbo faz parte disso, que seja apenas o começo de uma longa trajetória. Só tenho agradecimentos à toda equipe!!',
      image: '/client-2_photo.png',
      name: 'Raissa de Medeiros',
      job: 'CEO da Loja Byr'
    },
    {
      text: 'Excelente atendimento. Toda a equipe é muito atenciosa, competente e se coloca à disposição sempre que necessário. Por esses e outros motivos, batemos recorde atrás de recorde em faturamento - todo santo mês. Obrigado pela parceria!',
      image: '/client-3_photo.png',
      name: 'Felipe',
      job: 'CEO da Nina Saúde'
    }
  ];

  function handleWindowScroll() {
    const Cards = document.getElementsByClassName('fade');

    Array.from(Cards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <DashContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <DashWrapper>
        <TextWrapper>
          <Title>
            O que nossos clientes falam da Turbo:         
          </Title>
        </TextWrapper>
        <ReviewContent>
          {reviews.map((review, index) => (
            <ReviewCard key={index} className="fade">
              <ReviewText>{review.text}</ReviewText>
              <ReviewInfo>
                <ReviewPhoto src={review.image}/>
                <InfoWrapper>
                  <ReviewName>{review.name}</ReviewName>
                  <p>{review.job}</p>
                </InfoWrapper>
              </ReviewInfo>
            </ReviewCard>
          ))}
        </ReviewContent>
      </DashWrapper>
    </DashContainer>
  )
};

export default DashComments;
