import React from 'react';
import styled from 'styled-components';

const SubitemCard = styled.li`
  list-style: none;
  width: 100%;
  max-width: 292px;
  border-radius: 4px;
  background: linear-gradient(0deg, #170F34 0%, #170F34 100%), #FFF;
  grid-row: 1 / 4;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CardLink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};
`;

const CardImage = styled.img`
  width: auto;
  height: 154px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-family: 'Graphik', sans-serif;
  color: rgba(200, 197, 204, 0.8);
`;

const CardTitle = styled.p`
  font-weight: 700;
  line-height: 120%;
  font-size: 16px;
  font-family: 'Sora', sans-serif;
  margin: 0;
  color: rgba(254, 254, 254, 1);


  ${CardLink}:hover && {
    text-decoration: ${props => (props.active ? 'underline' : 'none')};
  }
`;

const CardButton = styled.a`
  text-decoration: none;
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: center;
  background-color: #4E62EE;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(78, 98, 238, 0.40);
  padding: 16px 24px;
  color: #FEFEFE;
  font-family: Sora;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.205px;
`;

const SubmenuItem = ({ submenu }) => {

    return (
        <SubitemCard>
            <CardLink className='change-link' active={submenu.url} href={submenu.url ? submenu.url : undefined} target={submenu.targetOut ? '_blank' : '_self'}>
                <CardImage src={submenu.icon} alt={submenu.title}/>
                <CardText>
                    <CardTitle active={submenu.url}>{submenu.title} {!submenu.url && '🔒'}</CardTitle>
                    <span>{submenu.text}</span>
                </CardText>
                <CardButton href={submenu.url ? submenu.url : undefined} target={submenu.targetOut ? '_blank' : '_self'}>
                    {submenu.button}
                </CardButton>
            </CardLink>
        </SubitemCard>
    )

}

export default SubmenuItem;