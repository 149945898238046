import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(max(2.61vw, 25px)) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(max(2.61vw, 25px)) rotate(0deg); }
`

const TeamWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6.77vw;

  @media screen and (max-width: 1440px) {
    padding-bottom: 98px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 80px;
  }
`;

const BenefitsWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

const Title = styled.h2`
  font-size: 1.875vw;
  line-height: 2.29vw;
  font-weight: 700;
  margin: 0 auto 2.97vw;
  letter-spacing: -0.0216em;
  color: #f0f0f0;
  width: 41.67vw;
  
  @media screen and (max-width: 1440px) {
    font-size: 26px;
    line-height: 32px;
    width: 600px;
    margin: 0 auto 42px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(84.4deg, #0E11DB 21.9%, #DA41A5 62.59%, #EC894B 95.31%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`; 

const BenefitsContent = styled.div`
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.56vw;
  z-index: 1;
  transform: translateX(100vw);
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);

  @media screen and (max-width: 1440px) {
    width: 1060px;
    gap: 22px;
  }

  @media screen and (max-width: 768px) {
    gap: 16px;
    margin: 58px auto;
    transform: translateY(50vh);
  }
`; 

const BenefitsCard = styled.div`
  width: calc(68.97vw/4);
  height: 7vw;
  padding: 1.4vw 1.56vw;
  border-radius: 0.88vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    width: 248px;
    height: 100px;
    padding: 20px 22px;
    border-radius: 12px;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 358px;
    height: 134px;
    padding: 28px 39px;
    border-radius: 17px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(45, 35, 56, 0.1);
    border: 1px solid rgba(120, 107, 227, 0.6);
    backdrop-filter: blur(34px);
    -webkit-backdrop-filter: blur(34px);
    box-sizing: border-box;
    border-radius: inherit;
    z-index: -1;
  }
`;

const BenefitsIcon = styled.img`
  width: ${props => props.widthItem ? `calc(${props.widthItem}*100vw/1920)` : '2.1vw'};
  height: auto;

  @media screen and (max-width: 1440px) {
    width: ${props => props.widthItem ? `${props.widthItem}px*0.75` : '30px'};
  }

  @media screen and (max-width: 768px) {
    width: ${props => props.widthItem ? `${props.widthItem}px` : '30px'};
  }
`;  

const BenefitsText = styled.p`
  font-size: 1.11vw;
  line-height: 1.02vw;
  margin: 0;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  letter-spacing: -0.0108em;
  text-align: left;
  width: max-content;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 21px;
    line-height: 19.5px;
  }
`;

const TeamImgWrapper = styled.div`
  position: relative;
  width: 82.92vw;
  margin: 5.57vw auto 20px;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 1194px;
    max-width: 100%;
    margin-top: 80px;
  }

  @media screen and (max-width: 768px) {
    z-index: 0;
    width: 98%;
    margin-top: 0;
  }
`;

const TeamImg = styled.img`
  width: 100%;
  height: auto;
`;

const TeamElipseGradient = styled.img`
  width: 44.11vw;
  height: auto;
  position: absolute;
  bottom: 7vw;
  left: 0px;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    width: 635px;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    bottom: 190px;
  }
`;

const TeamElipse = styled.img`
  width: 34.22vw;
  height: auto;
  position: absolute;
  bottom: 30vw;
  left: -10.26vw;
  z-index: 0;
  animation: ${floatation} 14s linear infinite;

  @media screen and (max-width: 1440px) {
    width: 493px;
  }

  @media screen and (max-width: 768px) {
    z-index: 0;
    width: 115.38vw;
    left: -57.18vw;
    bottom: 80px;
  }
`;

const TeamGradient = styled.img`
  width: 65.94vw;
  height: auto;
  position: absolute;
  bottom: 10vw;
  right: -20vw;
  z-index: 3;

  @media screen and (max-width: 768px) {
    bottom: 30px;
    right: -24.36vw;
    width: 77.44vw;
  }
`;

const ImageText = styled.div`
  width: 38.65vw;
  height: 4.74vw;
  position: absolute;
  bottom: 4.2vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  padding-left: 2vw;
  margin-left: 2vw;
  text-align: left;
  color: #F0F0F0;
  font-weight: 700;
  font-size: 2.6vw;
  line-height: 3.15vw;
  letter-spacing: -0.0466em;
  background: url('/team-turbo-line.png') no-repeat bottom;
  background-size: contain;

  @media screen and (max-width: 1440px) {
    width: 557px;
    height: 68px;
    bottom: 60px;
    padding-left: 28px;
    margin-left: 28px;
    font-size: 37px;
    line-height: 45px;
  }

  @media screen and (max-width: 768px) {
    height: 27px;
    width: 237px;
    font-size: 18px;
    line-height: 22px;
    padding-left: 5px;
    margin-left: 5px;
    bottom: 37px;
  }
`;

const TeamTurbo = ({ isMobile }) => {

  const benefits = [
    /*{
      icon: '/benefits-insurance.png',
      text: 'Plano de saúde',
      width: 40
    },
    {
      icon: '/benefits-food.png',
      text: 'Vale Alimentação',
      width: 37
    },
    {
      icon: '/benefits-dentist.png',
      text: 'Plano Odontológico',
      width: 33
    },
    {
      icon: '/benefits-flexibility.png',
      text: 'Local Flexível',
      width: 42
    },*/
    {
      icon: '/benefits-vacation.png',
      text: 'Férias remuneradas',
      width: 44
    },
    {
      icon: '/benefits-transport.png',
      text: 'Vale Transporte',
      width: 37
    },
    {
      icon: '/benefits-birthday.png',
      text: 'Day off Aniversário',
      width: 39
    },
    {
      icon: '/benefits-courses.png',
      text: '+ de 60 cursos disponíveis',
      width: 45
    }
  ];

  function handleWindowScroll() {
    const benefitsCards = document.getElementById('benefits-cards').getBoundingClientRect();

    if(benefitsCards.top < (isMobile ? 1.2*window.innerHeight : 0.7*window.innerHeight)) {
      document.getElementById('benefits-cards').style.transform = 'translateX(0) translateY(0)';
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <TeamWrapper>
      <BenefitsWrapper className='page-width'>
        <Title>
          Além de um <GradientTitle>crescimento supersônico</GradientTitle>, o que você ganha sendo Turbo:
        </Title>
        <BenefitsContent id="benefits-cards">
          {benefits.map((benefit, index) => (
            <BenefitsCard key={index}>
              <BenefitsIcon src={benefit.icon} widthItem={benefit.width}/>
              <BenefitsText>{benefit.text}</BenefitsText>
            </BenefitsCard>
          ))}
        </BenefitsContent>
      </BenefitsWrapper>
      <TeamElipseGradient src={isMobile ? '/team-elipse-gradient_mobile.png' : '/team-elipse-gradient.png'}/>
      <TeamElipse src='/team-elipse.png'/>
      <TeamGradient src='/team-gradient.png'/>
      <TeamImgWrapper>
        <TeamImg src={isMobile ? '/team-turbo_mobile.png' : '/team-turbo.png'}/>
        <ImageText>
          Vem fazer parte da equipe!
        </ImageText>
      </TeamImgWrapper>
      <ContactButton width={264} link="https://turbopartners.vagas.solides.com.br/" target="_blank" text="Entrar para o foguete" solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 92.58%, #EC894B 173.78%)' icon='rocket' iconHeight={16.5}/>
    </TeamWrapper>
  );
};

export default TeamTurbo;

