import React from "react";
import styled, { keyframes } from "styled-components";
import ContactButton from "../components/ContactButton";
import BrandsSteps from "../components/BrandsSteps";
import CasesCard from "../components/CasesCard";
import MarketingCard from "../components/MarketingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';

const swipe = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`

const marqueeAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8.33vw 0 0 0;
  box-sizing: border-box;
  height: max-content;

  .active-image {
    display: flex;
    position: fixed;
    opacity: 1;
    top: calc(50vh - 100px);
    right: 13.02vw;
  }

  .initial-image {
    display: flex;
    opacity: 1;
  }

  @media screen and (max-width: 1440px) {
    padding: 120px 0 0 0;

    .active-image {
      right: calc((100vw - 1065px)/2);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 110px 0 0 0;
    margin-top: -110px;
  }
`;

const Title = styled.h2`
  font-size: 2.08vw;
  line-height: 2.55vw;
  font-weight: 800;
  letter-spacing: -0.032em;
  color: #fff;
  text-align: center;
  align-self: center;
  margin: 0 0 3.38vw 0;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 48px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => props.bigger ? '32px' : '26px'};
    line-height: ${props => props.bigger ? '39px' : '32px'};
    margin: 150px 0 50px 0;
  }
`;

const TeamWrapper = styled.div`
  align-self: center;
  padding: 3.33vw;
  box-sizing: border-box;
  width: 53.65vw;
  height: 27.45vw;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.2vw;

  @media screen and (max-width: 1440px) {
    width: 772px;
    max-width: 100%;
    height: 395px;
    padding: 48px;
    row-gap: 17px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 353px;
    padding: 25px 12px;
    row-gap: 16px;
  }

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 53.65vw;
    height: 27.45vw;
    border-radius: 12px;
    background: linear-gradient(
      95.71deg,
      rgba(255, 255, 255, 0.169) 4.55%,
      rgba(128, 128, 128, 0) 96.36%
    );
    opacity: 0.35;

    @media screen and (max-width: 1440px) {
      width: 772px;
      max-width: 100%;
      height: 395px;
    }

    @media screen and (max-width: 768px) {
      height: 353px;
      width: 100%;
    }
  }
`;

const NumbersRow = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  column-gap: 0.78vw;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  line-height: 1.25vw;
  font-weight: 400;
  letter-spacing: -0.035em;
  text-align: left;

  @media screen and (max-width: 1440px) {
    column-gap: 12px;
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    column-gap: 10px;
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const NumbersText = styled.p`
  width: max-content;
  margin: 0;
  display: inline-flex;
`;

const NumbersLine = styled.div`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  flex-grow: 100;
`;

const Numbers = styled.span`
  width: ${props => props.turbo ? '' : 'max-content'};
  text-decoration: ${props => props.turbo ? '' : 'line-through'}; 
`;

const TeamLogo = styled.img`
  width: 11.09vw;
  height: 1.2vw;

  @media screen and (max-width: 1440px) {
    width: 160px;
    height: 17px;
  }

  @media screen and (max-width: 768px) {
    width: 25.64vw;
    height: 2.77vw;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  width: 60.83vw;
  height: 86px;
  padding: 0 2.08vw;
  box-sizing: border-box;
  margin: 6.35vw 0 0 0;
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 700;
  letter-spacing: -0.032em;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90.4deg, #a26be3 43.75%, #5068e5 133.31%);
  box-shadow: 0px 23px 50px rgba(162, 107, 227, 0.33);
  border-radius: 8px;
  column-gap: 10px;

  @media screen and (max-width: 1440px) {
    width: 876px;
    max-width: 100%;
    height: 86px;
    padding: 0 30px;
    margin: 92px 0 0 0;
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: max-content;
    flex-direction: column;
    row-gap: 12px;
    padding: 24px 14px;
    margin: 53px 0 0 0;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Arrows = styled.img`
  width: 1.98vw;
  height: auto;
  margin: 6.51vw 0 0.89vw 0;
  align-self: center;

  @media screen and (max-width: 768px) {
    width: 9.74vw;
    margin: 17.95vw 0 3.33vw 0;
  }
`;

const Text = styled.p`
  max-width: 41.95vw;
  width: fit-content;
  margin: 0;
  align-self: center;
  font-size: 1.04vw;
  line-height: 1.27vw;
  font-weight: 400;
  color: rgba(254, 253, 255, 0.7);
  text-align: center;

  @media screen and (max-width: 1440px) {
    max-width: 604px;
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    max-width: 91%;
    font-size: 16px;
    line-height: 19.5px;
    margin-bottom: 46px;
  }
`;

const Graphic = styled.img`
  width: 110%;
  height: auto;
  align-self: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const PartnersWrapper = styled.div`
  width: 100vw;
  padding: 9.64vw 0 1.2vw 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 3.96vw;
  overflow: hidden;
    
  .swiper {
    overflow: hidden;
    width: 100vw;
    cursor: grab;
    position: relative;
  }

  .swiper-wrapper {
    display: flex;
    transition-timing-function : linear;
  }

  @media screen and (max-width: 1440px) {
    padding: 138px 0 18px 0;
    row-gap: 57px;
  }

  @media screen and (max-width: 768px) {
    padding: 70px 0 36px 0;
    row-gap: 35px;
  }
`;

const SwiperManual = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1px 0;
  position: relative;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  padding: 0 16px;
  gap: 26px;
  animation: ${swipe} 32s linear infinite;
`;

const FadeOutEffect = styled.div`
  width: 13.02vw;
  height: 520px;
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 5;
  background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: calc((100vw - 1065px)/2);
    min-width: 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Process = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 751px;
  height: 751px;

  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 492px;
    background: unset;
    justify-content: center;
  }
`;

const ProcessImage = styled.div`
  position: absolute;
  width: 751px;
  height: 751px;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 100vw;
    height: 492px;

    path {
      cursor: default;
    }
  }
`;

const MarketingRow = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 30px;
  overflow-x: hidden;
  padding: 5.57vw 2px 4px;

  @media screen and (max-width: 1440px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    gap: 22px;
    padding-top: 65px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: sticky;
  height: 36.46vw;
  width: 36.46vw;
  min-width: 380px;
  min-height: 380px;
  top: calc(50vh - 9.84vw);
  right: 0;
  opacity: 1;
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  z-index: 0;
`;

const StepImage = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: -8.39vw;
  right: 0;

  @media screen and (max-width: 1440px) {
    top: -121px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const PurpleSpan = styled.span`
  color: #a26be3;
`;

const VioletSpan = styled.span`
  color: #cd95ed;
`;

const TurbineSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100vw;
  height: 33.8vw;
  background-image: url("turbine-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  margin: -4vw 0 0 0;
  padding: 13.85vw 0 9.7vw 0;

  @media screen and (max-width: 1440px) {
    margin: -57px 0 0 0;
    padding: 200px 0 140px 0;
    height: 488px;
  }

  @media screen and (max-width: 768px) {
    background-image: url("turbine-background_mobile.png");
    background-position: center;
    margin: 0;
    height: 760px;
    padding: 219px 0 346px 0;
  }
`;

const Gradient = styled.div`
  width: 24.22vw;
  height: 130px;
  position: absolute;
  top: calc(3.38vw - 7px);
  left: ${props => props.left ? '-13.02vw' : ''};
  right: ${props => props.left ? '' : '-13.02vw'};
  background: linear-gradient(90deg, #0D031A 0%, #0D031A 55%, rgba(13, 3, 26, 0) 100%);
  z-index: 3;
  transform: ${props => props.left ? '' : 'rotate(180deg)'};

  @media screen and (max-width: 1440px) {
    left: ${props => props.left ? 'calc((1065px - 100vw)/2)' : ''};
    right: ${props => props.left ? '' : 'calc((1065px - 100vw)/2)'};
  }

  @media screen and (max-width: 1165px) {
    left: ${props => props.left ? '-50px' : ''};
    right: ${props => props.left ? '' : '-50px'};
  }

  @media screen and (max-width: 768px) {
    left: ${props => props.left ? '-36px' : ''};
    right: ${props => props.left ? '' : '-36px'};
  }
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 117px;
  overflow-x: hidden;
  background: rgba(13, 3, 26, 0.5);
  border-top: 1px solid #1b3f71;
  border-bottom: 1px solid #1b3f71;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  display: flex;
  align-items: center;
  z-index: 1;
  margin-top: 3.38vw;

  @media screen and (max-width: 768px) {
    height: 70px;
    background: rgba(13, 3, 26, 0.5);
  }
`;

const MarqueeTrack = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimation} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 40px;
  border-right: 1px solid #1b2f4a;

  @media screen and (max-width: 768px) {
    padding: 0 10px;
    height: 38px;
  }
`;

const PartnersLogo = styled.img`
  height: auto;
  width: auto;

  @media screen and (max-width: 768px) {
    transform: scale(0.605);
  }
`;

const StepBackground = styled.img`
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

const CommerceContent = ({ isMobile }) => {

  const processArray = [
    "Observar dados",
    "Research",
    "Criar as Hipóteses",
    "Testar as Hipóteses",
    "Analisar Resultados",
    "Implementar Sucesso dos Testes"
  ];

  const steps = [
    {
      title: "Criativo (Anúncio)",
      text: "Produzimos anúncios online de alta conversão, feitos sob medida para colocar a sua marca na cabeça das pessoas e dinheiro no seu bolso.",
      button: "Quero anúncios que convertem",
      gif: require("../assets/step-1.gif"),
      image: '/step-1.png',
      mobile: {
        bottom: "-30px"
      }
    },
    {
      title: "Estratégia",
      text: "Isolados, bons vídeos e boas imagens não bastam. É fundamental saber entregar uma mensagem alinhada, para a pessoa correta, no canal mais adequado.",
      button: "Quero um tráfego estratégico",
      gif: require("../assets/step-2.gif"),
      mobile: {
        bottom: "-39px"
      }
    },
    {
      title: "Desenvolvimento",
      text: "Nossos desenvolvedores são especializados em criar sites e Landing Pages de alta conversão. Páginas rápidas, com copy cirúrgica e com uma UI/UX de tirar o fôlego.",
      button: "Quero páginas persuasivas",
      gif: require("../assets/step-3.gif"),
      mobile: {
        bottom: "-39px"
      }
    },
    {
      title: "Retenção",
      text: "Vender várias vezes para os mesmos clientes é o “segredo” dos negócios que escalam. Nesse sentido, implementamos estratégias de retenção, como e-mail & WhatsApp marketing.",
      button: "Quero escalar minhas vendas",
      gif: require("../assets/step-4.gif"),
      mobile: {
        left: "-20px",
        bottom: "-128px",
        top: "-50px",
        width: "650px",
        maxWidth: "130vw"
      },
      desktop: {
        top: "-11.51vw"
      },
    },
  ];

  const teamInfo = [
    {
      name: "Gestor de tráfego",
      value: "R$3.000,00+",
    },
    {
      name: "Social Media",
      value: "R$2.500,00+",
    },
    {
      name: "Webdesigner",
      value: "R$3.000,00+",
    },
    {
      name: "Desenvolvedor",
      value: "R$5.000,00+",
    },
    {
      name: "Copywriter",
      value: "R$3.500,00+",
    },
    {
      name: "Designer",
      value: "R$2.500,00+",
    },
    {
      name: "Editor de Vídeo",
      value: "R$2.500,00+",
    },
    {
      name: "Total",
      value: "R$22.000,00+",
      total: true,
    },
    {
      name: "Gestor de tráfego",
      value: "A partir de R$2.000,00",
      turbo: true,
    },
  ];

  const partnersInfo = [
    {
      img: '/get-power_card-image.png',
      logo: '/logo_get-power.png',
      content: '0 a 5 milhões em vendas mensais'
    },
    {
      img: '/academia-do-sol_card-image.png',
      logo: '/logo_academia-do-sol.png',
      content: '100 mil em vendas em apenas 1 dia'
    },
    {
      img: '/brota_card-image.png',
      logo: '/logo_brota.png',
      content: '+200k de receita na black friday'
    },
    {
      img: '/carla-lorenzon_card-image.png',
      logo: '/logo_carla-lorenzon.png',
      content: 'De 3.8k seguidores para 78k em um mês e meio'
    },
    {
      img: '/luma_card-image.png',
      logo: '/logo_luma.png',
      content: 'Redução de 60% no CAC com triplo de investimento mensal'
    },
    {
      img: '/byr_card-image.png',
      logo: '/logo_byr.png',
      content: 'Aumento de 900% do faturamento'
    },
    {
      img: '/nina-saude_card-image.png',
      logo: '/logo_nina-saúde.png',
      content: 'Aumento do faturamento em 158% em um semestre'
    }
  ];

  const marketingInfo = [
    {
      icon: "/performance.png",
      title: "Gestão de Perfomance",
      text: "Usamos as melhores estratégias de tráfego para te fazer vender mais",
    },
    {
      icon: "/dev.png",
      title: "Desenvolvimento",
      text: "Desenvolvimento de sites, Landing Pages, aplicativos e integrações.",
    },
    {
      icon: "/design.png",
      title: "Design",
      text: "Identidade visual, postagens, flyers, outdoors, entre outros materiais gráficos",
    },
    {
      icon: "/marketing.png",
      title: "E-mail Marketing",
      text: "E-mail, SMS e WhatsApp marketing, a fim de aumentar o seu LTV",
    },
    {
      icon: "/cro.png",
      title: "CRO",
      text: "Otimizamos a taxa de conversão de sites, páginas de captura e de vendas",
    },
    {
      icon: "/criativos.png",
      title: "Produção de Criativos",
      text: "Produzimos criativos estáticos e dinâmicos sob medida para a sua marca",
    },
  ];

  const logos = [
    {
      img: "/logo_get-power.png",
      url: "/",
    },
    {
      img: "/logo_brota.png",
      url: "/",
    },
    {
      img: "/logo_soul-zen.png",
      url: "/",
    },
    {
      img: "/logo_martha-campos.png",
      url: "/",
    },
    {
      img: "/logo_vividus.png",
      url: "/",
    },
    {
      img: "/logo_nutsco.png",
      url: "/",
    },
    {
      img: "/logo_grupo-v3.png",
      url: "/",
    },
    {
      img: "/logo_cale.png",
      url: "/",
    },
    {
      img: "/logo_peppy-berry.png",
      url: "/",
    },
  ];

  const [active, setActive] = React.useState(1);
  const [graphicActive, setGraphicActive] = React.useState(false);
  const [imageActive, setImageActive] = React.useState(0);
  const [activeGif, setActiveGif] = React.useState(false);
  const [initAutoplay, setInitAutoplay] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  const myRef = React.useRef();

  function handleEffects() {
    // Efeito de mudar imagem fixada nos passos
    const step0 = document.getElementById('0').getBoundingClientRect();
    const step1 = document.getElementById('1').getBoundingClientRect();
    const step2 = document.getElementById('2').getBoundingClientRect();
    const step3 = document.getElementById('3').getBoundingClientRect();

    if ((step0.top - (0.0839 * window.innerWidth)) > (0.5 * window.innerHeight - 0.0984 * window.innerWidth)) {
      setActiveGif(false);
    }
    if ((step0.top - (0.0839 * window.innerWidth)) <= (0.5 * window.innerHeight - 0.0984 * window.innerWidth)) {
      setActiveGif(true);
    }
    if (step0.top + 2 * step0.height / 3 < window.innerHeight && step0.top > (window.innerHeight - step1.top)) {
      setImageActive(0);
    }
    if (step1.top + 2 * step0.height / 3 < window.innerHeight && step1.top > (window.innerHeight - step2.top)) {
      setImageActive(1);
    }
    if (step2.top + 2 * step0.height / 3 < window.innerHeight && step2.top > (window.innerHeight - step3.top)) {
      setImageActive(2);
    }
    if (step3.top + 2 * step0.height / 3 < window.innerHeight) {
      setImageActive(3);
    }

    // Efeito começar gif do gráfico
    const graphicTop = document.getElementById('graphic').getBoundingClientRect().top;

    if (graphicTop <= 0.7 * window.innerHeight) {
      setGraphicActive(true);
    }

    // Efeito aparecer carrossel
    const partnersSectionTop = document.getElementById('partners-section').getBoundingClientRect().top;

    if (partnersSectionTop <= 0.8 * window.innerHeight) {
      setInitAutoplay(true);
      if (!isMobile) {
        document.getElementById('partners-cards').style.transform = 'translateX(0)';
      }

    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleEffects);

    return () => {
      window.removeEventListener('scroll', handleEffects);
    }
  });

  React.useEffect(() => {
    const observer = new IntersectionObserver(() => {
      setActive(1);
      setTimeout(function () {
        setActive(2);
      }, 1000);
      setTimeout(function () {
        setActive(3);
      }, 2000);
      setTimeout(function () {
        setActive(4);
      }, 3000);
      setTimeout(function () {
        setActive(5);
      }, 4000);
      setTimeout(function () {
        setActive(6);
      }, 5000);
    });
    observer.observe(myRef.current);
  }, []);


  return (
    <ContentWrapper>
      <div className="page-width" style={{ overflow: isMobile ? 'hidden' : '' }}>
        <StepBackground src={isMobile ? '/background-commerce_mobile.png' : '/background-commerce.png'} />
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {steps.map((step, index) => (
              <div id={index} key={index}>
                <BrandsSteps
                  step={step}
                  index={index}
                  length={steps.length}
                  isMobile={isMobile}
                />
              </div>
            ))}
          </div>
          {!isMobile && 
            <ImageWrapper
              id="step-image"
              width={steps[imageActive].mobile.width}
            >
              <StepImage
                src={
                  isMobile
                    ? steps[imageActive].gif
                    : (activeGif ? steps[imageActive].gif : steps[imageActive].image)
                }
              />
            </ImageWrapper>
          }
        </div>
      </div>
      <div className="page-width" style={{ zIndex: 2, display: 'flex', flexDirection: 'column' }}>
        <Title>
          Uma equipe completa de especialistas em{" "}
          <VioletSpan>digital</VioletSpan>
        </Title>
        <TeamWrapper>
          {teamInfo.map((numbers, index) => (
            <NumbersRow key={index} style={{ color: (numbers.turbo) ? '#CD95ED' : '', fontWeight: (numbers.turbo || numbers.total) ? '700' : '' }}>
              {numbers.turbo ? <TeamLogo src='/TurboPartners.png' /> : <NumbersText>{numbers.name}</NumbersText>}
              <NumbersLine />
              <Numbers turbo={numbers.turbo}>{numbers.value}</Numbers>
            </NumbersRow>
          ))}
        </TeamWrapper>
        <ContactWrapper>
          Pronto pra turbinar seu negócio? Marque uma call com a gente!
          <ContactButton
            width={292}
            height={50}
            link="https://lp.turbopartners.com.br/commerce/lp4-2"
            target="_blank"
            text='Entrar em Contato'
            solidButton={true}
            background='#14191f'
            shadow='0px 6px 16px rgba(0, 0, 0, 0.38)'
          />
        </ContactWrapper>
        <Arrows src={'/arrows.png'} />
        <Title style={{ margin: isMobile ? '0 0 13px 0' : '0 0 1.25vw 0' }}>Conheça o <PurpleSpan>Efeito Turbo</PurpleSpan></Title>
        <Text>Esse é o comportamento mais comum que ocorre com os negócios parceiros ao implementarem o <b>motor Turbo.</b></Text>
        <Graphic id="graphic" src={graphicActive ? require('../assets/graphic.gif') : 'graphic-1.png'} />
      </div>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <Gradient left={true} />
        <MarqueeWrapper>
          <MarqueeTrack>
            <MarqueeContent>
              {logos.map((logo, index) => (
                <LogoWrapper href={logo.url} key={index}>
                  <PartnersLogo src={logo.img} />
                </LogoWrapper>
              ))}
              {logos.map((logo, index) => (
                <LogoWrapper href={logo.url} key={index}>
                  <PartnersLogo src={logo.img} />
                </LogoWrapper>
              ))}
            </MarqueeContent>
          </MarqueeTrack>
        </MarqueeWrapper>
        <Gradient left={false}
          style={{
            right: isMobile ? "-36px" : "-13.02vw",
            transform: 'rotate(180deg)'
          }}
        />
      </div>
      <PartnersWrapper id="partners-section">
        <Title bigger={true} style={{ margin: 0 }}>
          Parcerias de Sucesso
        </Title>
        <div style={{ height: isMobile ? '370px' : '450px' }}>
          {initAutoplay &&
            (isMobile ? (
            <SwiperManual id="partners-cards">
              <SwiperWrapper onTouchStart={() => setClicked(true)} style={clicked ? { animationPlayState: 'paused', animationName: 'none', animationFillMode: 'none' } : {}}>
                {partnersInfo.map((partner, index) => (
                  <div key={index}>
                    <CasesCard cases={partner} border='1px solid #1B3F71' line='#1B3F71' gradient='linear-gradient(179.75deg, rgba(0, 0, 0, 0.28) -31.38%, #0D031A 65.31%), linear-gradient(179.09deg, rgba(28, 2, 59, 0) -0.19%, rgba(66, 24, 115, 0.34) 58.4%)'/>
                  </div>
                ))}
              </SwiperWrapper>
              <FadeOutEffect />
            </SwiperManual>
          ) : (
            <Swiper
              modules={[Autoplay]}
              id="partners-cards"
              spaceBetween={32}
              slidesPerView={"auto"}
              autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
              freeMode={true}
              speed={2000}
              slidesOffsetBefore={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              slidesOffsetAfter={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              style={{ transform: 'translateX(100vw)', transition: 'all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88)' }}
            >
              {partnersInfo.map((partner, index) => (
                <SwiperSlide key={index}>
                  <CasesCard cases={partner} border='1px solid #1B3F71' line='#1B3F71' gradient='linear-gradient(179.75deg, rgba(0, 0, 0, 0.28) -31.38%, #0D031A 65.31%), linear-gradient(179.09deg, rgba(28, 2, 59, 0) -0.19%, rgba(66, 24, 115, 0.34) 58.4%)'/>
                </SwiperSlide>
              ))}
              <FadeOutEffect />
            </Swiper>
          ))
          }
        </div>
      </PartnersWrapper>
      <div style={{ position: 'relative' }}>
        <ProcessWrapper>
          <Title bigger={true} style={isMobile ? { margin: '35px 0 8px 0' } : { margin: '4.58vw 0 0 0', maxWidth: '71.79vw' }}>Nosso processo de <VioletSpan>6 etapas</VioletSpan></Title>
          <Text style={{ margin: isMobile ? '0 0 4.87vw 0' : '0' }}>Veja como a gente trabalha</Text>
          <Process ref={myRef}>
            <Title style={{ maxWidth: isMobile ? '214px' : '390px', color: 'rgba(162, 107, 227, 1)', margin: '0' }}>{processArray[active - 1]}</Title>
            {!isMobile ?
              <ProcessImage>
                <svg width="751" height="751" viewBox="0 0 751 751" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path onMouseEnter={() => setActive(6)} onClick={() => setActive(6)} d="M129.481 375C129.481 331.92 140.816 289.599 162.349 252.286C183.881 214.972 214.852 183.982 252.152 162.426" stroke={active === 6 ? "url(#paint4_linear)" : "#421873"} strokeOpacity={active === 6 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(5)} onClick={() => setActive(5)} d="M252.24 587.626C214.932 566.086 183.948 535.108 162.4 497.804C140.852 460.5 129.499 418.183 129.481 375.103" stroke={active === 5 ? "url(#paint5_linear)" : "#421873"} strokeOpacity={active === 5 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(4)} onClick={() => setActive(4)} d="M497.936 587.802C460.627 609.343 418.308 620.687 375.228 620.696C332.148 620.705 289.823 609.378 252.506 587.854" stroke={active === 4 ? "url(#paint0_linear)" : "#421873"} strokeOpacity={active === 4 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(3)} onClick={() => setActive(3)} d="M620.519 375C620.519 418.08 609.184 460.402 587.651 497.715C566.119 535.028 535.148 566.019 497.849 587.574" stroke={active === 3 ? "url(#paint1_linear)" : "#421873"} strokeOpacity={active === 3 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(2)} onClick={() => setActive(2)} d="M497.759 162.374C535.068 183.914 566.052 214.892 587.6 252.196C609.148 289.5 620.501 331.817 620.519 374.897" stroke={active === 2 ? "url(#paint2_linear)" : "#421873"} strokeOpacity={active === 2 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(1)} onClick={() => setActive(1)} d="M252.417 162.551C289.725 141.011 332.045 129.667 375.125 129.658C418.205 129.649 460.529 140.975 497.847 162.5" stroke={active === 1 ? "url(#paint3_linear)" : "#421873"} strokeOpacity={active === 1 ? "1" : "0.34"} strokeWidth="58" />
                  <rect x="100.657" y="369.076" width="88.4561" height="10.6757" fill="#0D031A" />
                  <rect x="561.239" y="369.076" width="88.4561" height="10.6757" fill="#0D031A" />
                  <rect x="475.051" y="532.262" width="88.4561" height="11.4239" transform="rotate(60 475.051 532.262)" fill="#0D031A" />
                  <rect x="243.235" y="134.209" width="88.4561" height="11.4239" transform="rotate(60 243.235 134.209)" fill="#0D031A" />
                  <rect x="233.341" y="611.216" width="88.4561" height="11.4239" transform="rotate(-60 233.341 611.216)" fill="#0D031A" />
                  <rect x="463.632" y="210.815" width="88.4561" height="11.4239" transform="rotate(-60 463.632 210.815)" fill="#0D031A" />
                  <defs>
                    <linearGradient id="paint0_linear" x1="260.031" y1="616.905" x2="594.791" y2="609.28" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="526.947" y1="595.76" x2="687.723" y2="302.036" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint2_linear" x1="642.092" y1="354.031" x2="468.108" y2="67.9322" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint3_linear" x1="223.405" y1="154.593" x2="62.6287" y2="448.317" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint4_linear" x1="108.26" y1="396.322" x2="282.244" y2="682.42" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint5_linear" x1="490.322" y1="133.447" x2="155.561" y2="141.073" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                  </defs>
                </svg>
              </ProcessImage>
              :
              <ProcessImage>
                <svg width="390" height="492" viewBox="0 0 390 492" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path onMouseEnter={() => setActive(4)} onClick={() => setActive(4)} d="M270.5 376.77C247.554 390.017 221.527 396.994 195.032 397C168.536 397.006 142.506 390.039 119.555 376.801" stroke={active === 4 ? "url(#paint0_linear_411_13)" : "#421873"} strokeOpacity={active === 4 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(3)} onClick={() => setActive(3)} d="M345.885 245.884C345.885 272.38 338.913 298.409 325.67 321.357C312.427 344.305 293.379 363.365 270.439 376.623" stroke={active === 3 ? "url(#paint1_linear_411_13)" : "#421873"} strokeOpacity={active === 3 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(2)} onClick={() => setActive(2)} d="M270.385 115.115C293.33 128.362 312.386 147.414 325.639 170.357C338.891 193.3 345.873 219.326 345.885 245.821" stroke={active === 2 ? "url(#paint2_linear_411_13)" : "#421873"} strokeOpacity={active === 2 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(1)} onClick={() => setActive(1)} d="M119.5 115.23C142.446 101.983 168.473 95.0056 194.968 95C221.464 94.9945 247.494 101.961 270.445 115.199" stroke={active === 1 ? "url(#paint3_linear_411_13)" : "#421873"} strokeOpacity={active === 1 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(6)} onClick={() => setActive(6)} d="M43.8845 245.885C43.8845 219.389 50.856 193.36 64.0988 170.412C77.3417 147.464 96.3896 128.404 119.33 115.146" stroke={active === 6 ? "url(#paint4_linear_411_13)" : "#421873"} strokeOpacity={active === 6 ? "1" : "0.34"} strokeWidth="58" />
                  <path onMouseEnter={() => setActive(5)} onClick={() => setActive(5)} d="M119.384 376.654C96.4389 363.407 77.3829 344.355 64.1305 321.412C50.878 298.469 43.8956 272.443 43.8845 245.948" stroke={active === 5 ? "url(#paint5_linear_411_13)" : "#421873"} strokeOpacity={active === 5 ? "1" : "0.34"} strokeWidth="58" />
                  <rect x="13" y="242" width="62" height="7" fill="#0D031A" />
                  <rect x="315" y="240" width="62" height="7" fill="#0D031A" />
                  <rect x="258.487" y="347" width="62" height="7.49058" transform="rotate(60 260.487 349)" fill="#0D031A" />
                  <rect x="106.487" y="86" width="62" height="7.49058" transform="rotate(60 108.487 88)" fill="#0D031A" />
                  <rect x="102" y="400.769" width="62" height="7.49058" transform="rotate(-60 102 400.769)" fill="#0D031A" />
                  <rect x="251" y="140.229" width="62" height="7.49058" transform="rotate(-60 253 138.229)" fill="#0D031A" />
                  <defs>
                    <linearGradient id="paint0_linear_411_13" x1="119.5" y1="404.504" x2="339" y2="399.504" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_411_13" x1="294.4" y1="390.52" x2="399.819" y2="197.927" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_411_13" x1="369.9" y1="232.02" x2="255.819" y2="44.4273" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_411_13" x1="270.385" y1="87.3848" x2="50.8845" y2="92.3848" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_411_13" x1="95.3695" y1="101.25" x2="-10.0504" y2="293.842" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_411_13" x1="19.8695" y1="259.75" x2="133.95" y2="447.342" gradientUnits="userSpaceOnUse">
                      <stop stopColor="#A26BE3" />
                      <stop offset="1" stopColor="#5068E5" />
                    </linearGradient>
                  </defs>
                </svg>
              </ProcessImage>
            }
          </Process>
        </ProcessWrapper>
      </div>
      <div className="page-width">
        <Title id="nextSection" bigger={true} style={{ margin: isMobile ? '43px 0 0 0' : '6.25vw 0 0 0' }}>Nossos principais serviços</Title>
        <MarketingRow>
          {marketingInfo.map((part, index) => (
            <MarketingCard part={part} key={index} />
          ))}
        </MarketingRow>
      </div>
      <TurbineSection className='page-width'>
        <Title
          bigger={true}
          style={{ fontWeight: 500, margin: 0 }}
        >
          Pronto para <b>turbinar</b> o seu negócio?
        </Title>
        <ContactButton
          width={254}
          height={67}
          link="https://lp.turbopartners.com.br/commerce/lp4-2"
          target="_blank"
          text="Começar agora"
          fontSize={20}
          solidButton={true}
          background='#A26BE3'
          shadow='0px 16px 50px rgba(162, 107, 227, 0.38)'
          icon='rocket'
          iconHeight={15}
          innerStyle={{
            alignSelf: "center",
          }}
        />
      </TurbineSection>
    </ContentWrapper>
  );
};

export default CommerceContent;
