import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(2.61vw) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(2.61vw) rotate(0deg); }
`;

const wave = keyframes`
	0% {
    transform: translateY(0px);
  }

  20% {
    transform: translateY(var(--bottom));
  }

  40%, 100% {
    transform: translateY(0);
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BigProject = styled.div`
  width: 100vw;
  height: 61vw;
  text-align: center;
  padding-top: 17.5vw;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.875vw;

  @media screen and (max-width: 1440px) {
    padding-top: 252px;
    height: 878px;
    gap: 27px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 150px;
    height: 616px;
    margin-bottom: 100px;
  }
`;

const ProjectImage = styled.img`
  transition: 1.5s ease-in-out;
  width: 22.14vw;
  height: auto;
  position: absolute;
  border-radius: 4px;
  top: ${props => props.position.top ? `calc(${props.position.top}*100vw/1920)` : ''};
  left: ${props => props.position.left ? `calc(${props.position.left}*100vw/1920)` : ''};
  bottom: ${props => props.position.bottom ? `calc(${props.position.bottom}*100vw/1920)` : ''};
  right: ${props => props.position.right ? `calc(${props.position.right}*100vw/1920)` : ''};
  transform: ${props => `rotate(${props.position.rotate}deg)`};
  z-index: ${props => `calc(${props.position.index} + 5)`};

  @media screen and (max-width: 1440px) {
    width: 319px;
    top: ${props => props.position.top ? `calc(${props.position.top}px*0.75)` : ''};
    left: ${props => props.position.left ? `calc(${props.position.left}px*0.75 + (100vw - 1440px)/2)` : ''};
    bottom: ${props => props.position.bottom ? `calc(${props.position.bottom}px*0.75)` : ''};
    right: ${props => props.position.right ? `calc(${props.position.right}px*0.75 + (100vw - 1440px)/2)` : ''};
  }

  @media screen and (max-width: 768px) {
    width: 182px;
    top: ${props => props.position.top ? `${props.position.top}px` : ''};
    left: ${props => props.position.left ? `calc((100vw - 390px)/2 + ${props.position.left}px)` : ''};
    bottom: ${props => props.position.bottom ? `${props.position.bottom}px` : ''};
    right: ${props => props.position.right ? `calc((100vw - 390px)/2 + ${props.position.right}px)` : ''};
  }
`;

const ProjectGradient = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: 4;
`;

const GradientTitle = styled.h2`
  background: linear-gradient(261.17deg, #4AA6F7 14.52%, #4AA6F7 22.5%, #1141BD 94.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: 35.47vw;
  font-size: 6.67vw;
  line-height: 6.98vw;
  font-family: 'Saira', sans-serif;
  letter-spacing: 0.015em;
  font-weight: 300;
  margin: 0;
  
  @media screen and (max-width: 1440px) {
    font-size: 96px;
    line-height: 100px;
    width: 510px;
  }

  @media screen and (max-width: 768px) {
    font-size: 64px;
    line-height: 69px;
    width: 358px;
  }
`;

const BiggerTitle = styled.h2`
  display: flex;
  white-space: pre;
  font-family: 'Saira', sans-serif;
  font-weight: 200;
  font-size: 5vw;
  line-height: 5.21vw;
  text-align: center;
  letter-spacing: 0.015em;
  color: #FFFFFF;
  margin: -1.67vw 0;

  @media screen and (max-width: 1440px) {
    font-size: 72px;
    line-height: 80px;
    margin: -24px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 7vw;
    line-height: 7.18vw;
    margin: -0.5vw 0;
  }
`;

const BiggerSpan = styled.span`
  animation: ${wave} 2.4s ease-in-out infinite;
  animation-delay: ${props => `calc(${props.letter}*0.04s)`};
  --bottom: -50px;

  @media screen and (max-width: 768px) {
    --bottom: -20px;
  }
`;

const Hero = styled.p`
  margin: 0;
  display: flex;  
  white-space: pre;
  padding: ${props => props.index === 0 ? '50px 0 0 0' : '0 0 50px 0'};
`;

const GradientHero = styled.span`
  font-family: 'Saira', sans-serif;
  width: 100%;
  font-weight: 200;
  font-size: 5vw;
  line-height: 5.52vw;
  text-align: center;
  letter-spacing: 0.015em;
  background: ${props => props.index === 0 ? 'linear-gradient(359.5deg, rgba(17, 65, 189, 0) 35.88%, #4AA6F7 82.36%, #4AA6F7 82.37%)' : 'linear-gradient(179.5deg, rgba(17, 65, 189, 0) 48.27%, #4AA6F7 95.5%, #4AA6F7 95.5%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  animation: ${wave} 2.4s ease-in-out infinite;
  animation-delay: ${props => `calc(${props.letter}*0.04s)`};
  --bottom: -50px;
  
  @media screen and (max-width: 1440px) {
    font-size: 72px;
    line-height: 80px;
  }

  @media screen and (max-width: 768px) {
    font-size: 7vw;
    line-height: 7.18vw;
    --bottom: -20px;
  }
`;

const BoostWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  @media screen and (max-width: 768px) {

  }
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.59vw;
  font-weight: 400;
  margin: 0 0 3.13vw 0;
  letter-spacing: 0.015em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 43.3vw;
  
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 52px;
    margin: 0 0 45px 0;
    max-width: 624px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    width: 100%;
    margin: 0 0 70px 0;
  }
`;

const BoostTools = styled.div`
  margin: 0 auto 4.01vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 35px;
  flex-wrap: wrap;
  z-index: 1;
  
  @media screen and (max-width: 1165px) {
    justify-content: center;
    margin-bottom: 75px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 136px;
  }
`; 

const ToolsCard = styled.div`
  width: calc((100% - 70px)/3);
  height: max-content;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 390px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: calc((76.96vw - 70px)*0.91/3);
  display: flex;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    height: 314px;
  }

  @media screen and (max-width: 1165px) {
    height: calc((100vw - 170px)*0.95/3);
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  align-self: center;
  justify-self: center;
  z-index: 1;
`;  

const ToolsTitle = styled.p`
  font-size: 1.67vw;
  line-height: 1.77vw;
  font-family: 'Saira', sans-serif;
  margin: 0;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-align: center;
  width: min-content;

  background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    width: fit-content;
    max-width: 330px;
  }
`;

const ToolsText = styled.p`
  font-size: 1.25vw;
  line-height: 1.46vw;
  margin: 0 0 1.875vw;
  color: #DEDEDE;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-align: center;
  max-width: 46.92vw;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 675px;
    margin-bottom: 27px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

const BlueElipse = styled.img`
  width: 29.9vw;
  height: auto;
  position: absolute;
  top: ${props => props.index === 0 ? '2.37vw' : '18.72vw'};
  left: ${props => props.index === 0 ? '-8.78vw' : 'unset'};
  right: ${props => props.index === 0 ? 'unset' : '-13.72vw'};
  animation: ${floatation} 12s ease-in-out infinite;
  animation-delay: ${props => props.index === 0 ? '0s' : '6s'};
  z-index: 0;

  @media screen and (max-width: 768px) {
    width: 430px;
    top: ${props => props.index === 0 ? '34px' : '270px'};
    left: ${props => props.index === 0 ? '-127px' : 'unset'};
    right: ${props => props.index === 0 ? 'unset' : '-198px'};
  }

  @media screen and (max-width: 768px) {
    width: ${props => props.index === 0 ? '470px' : '400px'};
    top: ${props => props.index === 0 ? '40px' : '1000px'};
    left: ${props => props.index === 0 ? 'unset' : '-190px'};
    right: ${props => props.index === 0 ? '-320px' : 'unset'};
  }
`;

const TechBoost = ({ isMobile }) => {

  const title = ['N', 'e', 'm', ' ', 't', 'o', 'd', 'o', ' ', 'h', 'e', 'r', 'ó', 'i', ' ', 'u', 's', 'a', ' ', 'c', 'a', 'p', 'a', '.'];

  const tools = [
    {
      image: '/tools-upsells.png',
      title: 'ODERBUMPS & UPSELLS',
    },
    {
      image: '/tools-loading.png',
      title: 'CARREGAMENTO SUPERSÔNICO',
    },
    {
      image: '/tools-cart.png',
      title: 'CARRINHO LATERAL',
    }
  ];

  const projects = [
    {
      image: 'projects-ninasaude.png',
      1920.1: {
        rotate: 8.36,
        left: 417,
        top: 322,
        index: 1
      },
      1920.2: {
        rotate: -15,
        left: 179,
        top: 340,
        index: 1
      },
      390.1: {
        rotate: 8.36,
        left: -35,
        top: 172,
        index: 1
      },
      390.2: {
        rotate: 28.23,
        left: -126,
        top: 232,
        index: 1
      }
    },
    {
      image: 'projects-vividus.png',
      1920.1: {
        rotate: 0,
        left: 478,
        bottom: 306,
        index: 3
      },
      1920.2: {
        rotate: 0,
        left: 406,
        bottom: 183,
        index: 3
      },
      390.1: {
        rotate: 0,
        left: 25,
        bottom: 203,
        index: 3
      },
      390.2: {
        rotate: 12.16,
        left: -3,
        bottom: 113,
        index: 3
      }
    },
    {
      image: 'projects-getpower.png',
      1920.1: {
        rotate: 0,
        right: 694,
        top: 455,
        index: 2
      },
      1920.2: {
        rotate: -11.34,
        right: 584,
        top: 680,
        index: 2
      },
      390.1: {
        rotate: -4.67,
        right: 29,
        bottom: 235,
        index: 2
      },
      390.2: {
        rotate: -16.78,
        right: -51,
        bottom: 139,
        index: 2
      }
    },
    {
      image: 'projects-gulafit.png',
      1920.1: {
        rotate: -9.4,
        right: 288,
        bottom: 331,
        index: 3
      },
      1920.2: {
        rotate: -1,
        right: 228,
        bottom: 383,
        index: 3
      },
      390.1: {
        rotate: -9.4,
        right: -75,
        top: 142,
        index: 3
      },
      390.2: {
        rotate: -14.45,
        right: -130,
        top: 105,
        index: 3
      }
    }
  ];

  const [cardAnimation, setCardAnimation] = React.useState(false);

  function handleWindowScroll() {
    // Animação dos cards de projeto saírem da frente do texto
    const text = document.getElementById('text-project');
    
    if(text.getBoundingClientRect().top > (0.5*window.innerHeight)) {
      setCardAnimation(false);  
    }
    if(text.getBoundingClientRect().top <= (0.5*window.innerHeight)) {
      setCardAnimation(true);  
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <div style={{ overflow: 'hidden' }}>
      <HeroWrapper>
        <Hero index={0}>
          {title.map((letter, index) => (
            <GradientHero index={0} letter={index}>{letter}</GradientHero>
          ))}
        </Hero>
        <BiggerTitle>
          {title.map((letter, index) => (
            <BiggerSpan letter={index}>{letter}</BiggerSpan>
          ))}
        </BiggerTitle>
        <Hero index={1}>
          {title.map((letter, index) => (
            <GradientHero index={1} letter={index}>{letter}</GradientHero>
          ))}
        </Hero>
      </HeroWrapper>
      <BigProject>
        <GradientTitle id="text-project">Descubra os projetos</GradientTitle>
        <ContactButton width={225} height={64} link='#projects-section' text='Ver projetos' fontSize={20} fontWeight={500} background='linear-gradient(263.91deg, #4AA6F7 -35.57%, #4AA6F7 -22.82%, #1141BD 92.97%)' innerBackground='#030917'/>
        {projects.map((project, index) => (
          <ProjectImage key={index} src={project.image} position={isMobile ? (cardAnimation ? project[390.2] : project[390.1]) : (cardAnimation ? project[1920.2] : project[1920.1])}/>
        ))}
        <ProjectGradient src={'/projects-gradient.png'}/>
      </BigProject>
      <BoostWrapper className='page-width'>
        <BlueElipse src={'/boost-elipse-1.png'} index={0}/>
        <BlueElipse src={'/boost-elipse-2.png'} index={1}/>
        <Title>
          Temos TUDO para turbinar seu website. Sério.
        </Title>
        <BoostTools>
          {tools.map((tool, index) => (
            <ToolsCard key={index}>
              <ImageWrapper>
                <Image src={tool.image}/>
              </ImageWrapper>
              <ToolsTitle>{tool.title}</ToolsTitle>
            </ToolsCard>
          ))}
        </BoostTools>
        <ToolsText>
          Juntos, vamos implementar as melhores estratégias para turbinar a <b>conversão</b> do seu Site e as <b>vendas</b> do seu negócio
          <br/><br/>
          Quais tipos de turbo mais te interessam? :)
        </ToolsText>
        <ContactButton width={295} height={64} link='https://form.respondi.app/SOMTDxh7' target="_blank" text='Escolher Turbo agora' fontSize={20} fontWeight={500} solidButton={true} background='linear-gradient(263.91deg, #4AA6F7 -35.57%, #4AA6F7 -22.82%, #1141BD 92.97%)' shadow='0px 4px 30px rgba(0, 136, 255, 0.38)'/>
      </BoostWrapper>
    </div>
  );
};

export default TechBoost;
