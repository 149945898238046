import React from 'react';
import styled, { keyframes } from 'styled-components';
import NumbersColumn from '../components/NumbersColumn';
import Icon from '../components/Icon';

const marqueeAnimationLeft = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-33.33%); }
`

const NumbersWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 5.47vw;
  padding-top: 2.47vw;

  @media screen and (max-width: 1440px) {
    padding-top: 36px;
    row-gap: 78px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 108px;
    row-gap: 60px;
  }
`; 

const BackgroundImg = styled.div`
  height: 43.8vw;
  background-image: url('/numbers-background.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1440px) {
    background-size: cover;
    background-position: center;
    height: 631px; 
  }

  @media screen and (max-width: 768px) {
    height: 312px;
    justify-content: start;
    margin-bottom: 50px;
  }
`;

const AstronautWrapper = styled.div`
  width: 100vw;
  height: 120vw;
  position: absolute;
  top: -11.62vw;
  left: 0;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    top: -167px;
  }

  @media screen and (max-width: 768px) {
    top: -136px;
  }
`;

const Astronaut = styled.img`
  width: 56.46vw;
  height: auto;
  position: absolute;
  top: 56.46vw;
  left: -56.46vw;
  opacity: 0;
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transform: translateX(-50%);

  @media screen and (max-width: 1440px) {
    width: 813px;
    left: -813px;
    top: 813px;
    max-width: 100%;
  }

  @media screen and (max-width: 768px) {
    object-fit: cover;
    width: 390px;
    height: 468px;
    top: 234px;
    left: -234px;
  }
`;

const BackgroundText = styled.p`
  margin-top: 16.69vw;
  font-weight: 400;
  font-size: 2.5vw;
  line-height: 3.07vw;
  text-align: center;
  letter-spacing: -0.0216em;
  color: #F0F0F0;
  width: 39.06vw;
  height: fit-content;
  padding: 1.46vw 2.4vw;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  b {
    color: #fff;
  }

  @media screen and (max-width: 1440px) {
    margin-top: 240px;
    font-size: 36px;
    line-height: 44px;
    width: 562px;
    padding: 21px 35px;
  }

  @media screen and (max-width: 768px) {
    margin: 226px auto 0;
    width: 82%;
    max-width: 390px;
    min-width: 252px;
    padding: 25px 38px;
    font-size: 24px;
    line-height: 29px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(157, 149, 166, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17px);
    border-radius: 8px;
    z-index: -1;
  }
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
    row-gap: 41px;
    margin-top: 130px;
  }
`;

const Title = styled.h2`
  text-align: left;
  font-size: 2.08vw;
  line-height: 2.55vw;
  color: #fff;
  letter-spacing: -1.6px;
  margin: 0;
  max-width: 29.69vw;
  
  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
    max-width: 428px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    letter-spacing: -0.0216em;
    width: 100%;
    max-width: 100%;
  }
`; 

const NumbersRow = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  column-gap: 30px;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 40px;
  }
`;

const SideText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  column-gap: 12px;
  font-size: 0.83vw;
  line-height: 0.99vw;
  font-weight: 700;
  color: #fff;
  letter-spacing: -0.0216em;
  text-align: left;

  @media screen and (max-width: 1440px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }
`;

const MarketingBackground = styled.div`
  display: flex;
  height: 55.7vw;
  position: relative;
  transition: top 3s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  overflow: hidden;
  z-index: 3;
  
  @media screen and (max-width: 1440px) {
    height: 802px;
  }
  
  @media screen and (max-width: 768px) {
    height: 1055px;
    align-items: end;
  }
`;

const LeftElipse = styled.div`
  width: 39.6vw;
  height: 39.6vw;
  position: absolute;
  bottom: 4.6vw;
  left: -14.4vw;
  z-index: 1;
  background: radial-gradient(87.84% 87.84% at 59.08% 12.16%, #0E11DB 19.17%, #DA41A5 63.97%, #EC894B 100%);
  filter: blur(51.5px);
  border-radius: 100%;

  @media screen and (max-width: 1440px) {
    width: 570px;
    height: 570px;
    bottom: 66px;
    left: -207px;
  }

  @media screen and (max-width: 768px) {
    width: 343px;
    height: 343px;
    bottom: 112px;
    left: unset;
    right: calc(40px + 50vw);
  }
`;

const RightElipse = styled.div`
  width: 39.6vw;
  height: 39.6vw;
  position: absolute;
  top: 9vw;
  left: 84vw;
  z-index: 1;
  background: radial-gradient(87.84% 87.84% at 59.08% 12.16%, #0E11DB 19.17%, #786BE3 63.97%, #A26BE3 100%);
  filter: blur(51.5px);
  border-radius: 100%;
  transform: rotate(-45deg);

  @media screen and (max-width: 1440px) {
    width: 570px;
    height: 570px;
    top: 130px;
    left: 1210px;
  }

  @media screen and (max-width: 768px) {
    width: 292px;
    height: 292px;
    top: 95px;
    left: calc(100px + 50vw);
  }
`;

const AstronautImg = styled.img`
  width: 45.31vw;
  height: auto;
  position: absolute;
  top: 5.2vw;
  left: 44.53vw;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    width: 652px;
    top: 75px;
    left: 45%;
  }

  @media screen and (max-width: 768px) {
    width: 558px;
    height: 558px;
    top: 130px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const TextWrapper = styled.div`
  margin-top: 15.91vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  row-gap: 1.04vw;
  font-size: 3.33vw;
  line-height: 4.03vw;
  font-weight: 400;
  letter-spacing: -0.0466em;
  color: #f0f0f0;

  b {
    color: #fff;
  }

  span {
    opacity: 0;
    transform: translateX(100vw); 
    transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
    max-width: 100%;
    position: relative;
  }

  @media screen and (max-width: 1440px) {
    row-gap: 15px;
    font-size: 48px;
    line-height: 58px;
    margin-top: 230px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 250px;
    row-gap: 20px;
    font-size: 32px;
    line-height: 39px;
    width: 100%;

    span {
      width: 358px;
      left: 50%;
    }
  }
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 119px;
  overflow-x: hidden;
  background: rgba(13, 3, 26, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: -4.6vw;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    height: 90px;
    margin-top: -66px;
  }

  @media screen and (max-width: 768px) {
    height: 70px;
    margin-top: 0;
  }
`;

const MarqueeTrackLeft = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationLeft} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 40px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: 768px) {
    padding: 0 26px;
    height: 39px;
  }
`;

const PartnersLogo = styled.img`
  height: auto;
  width: auto;
`;


const Numbers = ({ isMobile }) => {
  const numbersInfo = [
    {
      number: 11,
      unit: 'milhões',
      text: 'Investidos em anúncios nas principais plataformas digitais',
      type: 'money'
    },
    {
      number: 67,
      unit: 'milhões',
      text: 'Em receita geradas para nossos clientes',
      type: 'money'
    },
    {
      number: 540,
      unit: '',
      text: 'Páginas de alta conversão desenvolvidas',
      type: 'number'
    }
  ];

  const logos = [
    {
      desktop: 'logo_shopify.png',
      mobile: 'logo-mobile_shopify.png',
      url: 'https://www.shopify.com/br/parcerias'
    },
    {
      desktop: 'logo_tiktok.png',
      mobile: 'logo-mobile_tiktok.png',
      url: 'https://www.tiktok.com/business/pt-BR'
    },
    {
      desktop: 'logo_google.png',
      mobile: 'logo-mobile_google.png',
      url: 'https://www.google.com/intl/pt-BR/partners/become-a-partner/'
    },
    {
      desktop: 'logo_meta.png',
      mobile: 'logo-mobile_meta.png',
      url: 'https://www.facebook.com/business/marketing-partners/become-a-partner'
    },
    {
      desktop: 'logo_pagarme.png',
      mobile: 'logo-mobile_pagarme.png',
      url: 'https://pagar.me/'
    }
  ];

  function handleWindowScroll() {
    const astronaut = document.getElementById('astronaut');
    const astronautTop = document.getElementById('astronaut').getBoundingClientRect().top;
    const previousSectionBottom = document.getElementById('previous-section').getBoundingClientRect().bottom;
    const text1 = document.getElementById('text1');
    const text2 = document.getElementById('text2');
    const text3 = document.getElementById('text3');
    const text4 = document.getElementById('text4');
    
    // Efeito do astronauta decolando
    if(astronautTop < (isMobile ? 0.9*window.innerHeight : 1.5*window.innerHeight)) {
      astronaut.style.top = '0';
      astronaut.style.left = '50%';
      astronaut.style.opacity = 1;
    }

    if(previousSectionBottom < (isMobile ? window.innerHeight + 200 : 500)) {
      text1.style.opacity = 1;
      text1.style.transform = isMobile ? 'translateX(-50%)' : 'translateX(0)';
      setTimeout(function() {
        text2.style.opacity = 1;
        text2.style.transform = isMobile ? 'translateX(-50%)' : 'translateX(0)';
      }, 1000);
      setTimeout(function() {
        text3.style.opacity = 1;
        text3.style.transform = isMobile ? 'translateX(-50%)' : 'translateX(0)';
      }, 2000);
      setTimeout(function() {
        text4.style.opacity = 1;
        text4.style.transform = isMobile ? 'translateX(-50%)' : 'translateX(0)';
      }, 3000);
    }
  }
    
  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <NumbersWrapper>
      <div>
        <BackgroundImg className="page-width">
          <AstronautWrapper>
            <Astronaut id="astronaut" src='astronaut_image.png'/>
          </AstronautWrapper>
          <BackgroundText>
            Ainda dá tempo de fazer <b>seu negócio decolar</b>
          </BackgroundText>
        </BackgroundImg>
        <TitleRow className="page-width">
          <Title>Impulsionamos milhares de negócios diariamente</Title>
          <SideText>
            <Icon height={27.5} icon='rocket'/>
            Boosting<br/>business.
          </SideText>
        </TitleRow>
      </div>
      <div>
        <NumbersRow className="page-width" id="previous-section">
          {numbersInfo.map((info, index) => (
            <NumbersColumn info={info} index={index} key={index}/>
          ))}
        </NumbersRow>
        <MarketingBackground className="page-width">
          {/* <Background src={isMobile ? '/marketing-background_mobile.png' : '/marketing-background.png'}/> */}
          <LeftElipse/>
          <RightElipse/>
          <AstronautImg src={require('../assets/gif-astronaut.gif')} loading='lazy'/>
          <TextWrapper>
            <span id="text1">O mundo <b>muda</b></span>
            <span id="text2">O mercado <b>amadurece</b></span>
            <span id="text3">As tecnologias <b>evoluem</b></span>
            <span id="text4">E nós <b style={{ width: 'fit-content', background: 'url(astronaut_line.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom' }}>acompanhamos!</b></span>
          </TextWrapper>
        </MarketingBackground>
        <MarqueeWrapper className="page-width" top={true} left={true} id="marquee">
          <MarqueeTrackLeft>
            <MarqueeContent>
              {logos.map((logo, index) => (
                <LogoWrapper href={logo.url} key={index} target="_blank">
                  <PartnersLogo src={isMobile ? logo.mobile : logo.desktop}/>  
                </LogoWrapper>
              ))}
              {logos.map((logo, index) => (
                <LogoWrapper href={logo.url} key={index} target="_blank">
                  <PartnersLogo src={isMobile ? logo.mobile : logo.desktop}/>
                </LogoWrapper>
              ))}
              {logos.map((logo, index) => (
                <LogoWrapper href={logo.url} key={index} target="_blank">
                  <PartnersLogo src={isMobile ? logo.mobile : logo.desktop}/>
                </LogoWrapper>
              ))}
            </MarqueeContent>
          </MarqueeTrackLeft>
        </MarqueeWrapper>
      </div>
    </NumbersWrapper>
  );
};

export default Numbers;

