import React from "react";
import styled from "styled-components";
import MarketingCard from "../components/MarketingCard";
import ContactButton from '../components/ContactButton';

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 100px 0 0 0;
  box-sizing: border-box;
  height: max-content;
  width: 100%;

  .active-image {
    display: flex;
    position: fixed;
    opacity: 1;
    top: calc(50vh - 100px);
    right: 13.02vw;
  }

  .initial-image {
    display: flex;
    opacity: 1;
  }

  @media screen and (max-width: 1440px) {
    padding: 120px 0 0 0;
  }

  @media screen and (max-width: 768px) {
    padding: 110px 0 0 0;
    margin-top: -110px;
  }
`;

const Title = styled.h2`
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #F7F1FC;

  @media screen and (max-width: 1440px) {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 48px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => props.bigger ? '32px' : '26px'};
    line-height: ${props => props.bigger ? '39px' : '32px'};
    margin: 150px 0 50px 0;
  }
`;

const MarketingRow = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 30px;
  overflow-x: hidden;
  padding: 100px 0;
  margin: -40px 0;

  @media screen and (max-width: 1440px) {
    padding: 80px 0;
    margin: -20px 0;
  }

  @media screen and (max-width: 768px) {
    width: 99%;
    box-sizing: border-box;
    gap: 22px;
    padding: 65px 0;
    margin: -15px 0;
  }
`;

const CommerceCards = ({ isMobile }) => {

  const marketingInfo = [
    {
      icon: "/gestao.png",
      title: "Gestão de Perfomance",
      text: "Usamos as melhores estratégias de tráfego para te fazer vender mais",
    },
    {
      icon: "/devpc.png",
      title: "Desenvolvimento",
      text: "Desenvolvimento de sites, Landing Pages, e-commerce e integrações.",
    },
    {
      icon: "/ps.png",
      title: "Produção de Criativos",
      text: "Produzimos criativos estáticos e dinâmicos sob medida para a sua marca",
    },
    {
      icon: "/emailmkt.png",
      title: "E-mail Marketing",
      text: "E-mail, SMS e WhatsApp marketing, a fim de aumentar o seu LTV",
    },
    {
      icon: "/cro2.png",
      title: "CRO",
      text: "Otimizamos a taxa de conversão de sites, páginas de captura e de vendas",
    },
    {
      icon: "/criativo.png",
      title: "Outros",
      text: "Soluções personalizadas para o seu negócio, desde social media até SEO",
    },
  ];

  return (
    <ContentWrapper>
      <div className="page-width">
        <Title id="nextSection" bigger={true} style={{ margin: isMobile ? '43px 0 0 0' : '0' }}>Nossos principais serviços</Title>
        <MarketingRow>
          {marketingInfo.map((part, index) => (
            <MarketingCard part={part} key={index} />
          ))}
        </MarketingRow>
        <ContactButton
          className="change-link"
          icon="arrow" height={60} 
          width={360}
          solidButton={true} text="Implementar motor Turbo agora" 
          fontsize={16} font="'Oxanium', sans serif" 
          fontWeight={700} textColor="#F2EDF9" 
          background="linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)" 
          hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%);"
          shadow="unset" 
          borderRadius={4} 
          link='#redirect'
          innerStyle={{ textDecoration: 'none', margin: '0 auto 100px'}}
        />        
      </div>
    </ContentWrapper>
  );
};

export default CommerceCards;
