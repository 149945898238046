import React from 'react';
import styled, { keyframes } from 'styled-components';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(2.61vw) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(2.61vw) rotate(-360deg); }
`
const orbit = keyframes`
	0%, 100% { transform: rotate(var(--angulo)) translateX(var(--raio)) rotate(calc(var(--angulo)*(-1))) translateX(-50%) translateY(-50%); }
  50%   { transform: rotate(calc(var(--angulo) + 10deg)) translateX(var(--raio)) rotate(calc((var(--angulo) + 10deg)*(-1))) translateX(-50%) translateY(-50%); }
`

const HeroWrapper = styled.div`
  width: 100vw;
  height: 51.98vw;
  background: url('/talents-hero-background.png') no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;

  @media screen and (max-width: 1440px) {
    height: 748px;
    background-size: cover;
  }

  @media screen and (max-width: 1165px) {
    height: 64.21vw;
  }

  @media screen and (max-width: 768px) {
    height: 846px;
    background: url('/talents-hero-background_mobile.png') no-repeat;
    background-size: cover;
    background-position: center;
  }
`;

const HeroBall1 = styled.img`
  --raio: 41vw;
  --angulo: 200deg;
  position: absolute;
  top: 44.06vw;
  left: 50vw;
  width: 23.49vw;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  animation: ${orbit} 12s ease-in-out infinite;

  @media screen and (max-width: 1440px) {
    --raio: 590px;
    top: 634px;
    width: 338px;
  }

  @media screen and (max-width: 1165px) {
    --raio: 50.64vw;
    top: 54.42vw;
    width: 29.01vw;
  }

  @media screen and (max-width: 768px) {
    --raio: 160px;
    width: 315px;
    top: 838px;
    z-index: 3;
  }
`;

const HeroBall2 = styled.img`
  --raio: 34vw;
  --angulo: 280deg;
  position: absolute;
  top: 44.06vw;
  left: 50vw;
  width: 23.49vw;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  animation: ${orbit} 12s ease-in-out infinite;
  animation-delay: -4s;

  @media screen and (max-width: 1440px) {
    --raio: 490px;
    top: 634px;
    width: 338px;
  }

  @media screen and (max-width: 1165px) {
    --raio: 42.06vw;
    top: 54.42vw;
    width: 29.01vw;
  }

  @media screen and (max-width: 768px) {
    --raio: 600px;
    --angulo: 272deg;
    width: 315px;
    top: 800px;
  }
`;

const HeroBall3 = styled.img`
  --raio: 40vw;
  --angulo: 340deg;
  position: absolute;
  top: 44.06vw;
  left: 50vw;
  width: 23.49vw;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  animation: ${orbit} 12s ease-in-out infinite;
  animation-delay: -8s;

  @media screen and (max-width: 1440px) {
    --raio: 576px;
    top: 634px;
    width: 338px;
  }

  @media screen and (max-width: 1165px) {
    --raio: 49.44vw;
    top: 54.42vw;
    width: 29.01vw;
  }

  @media screen and (max-width: 768px) {
    --raio: 192px;
    width: 315px;
    top: 800px;
    z-index: 3;
  }
`;

const HeroContent = styled.div`
  padding-top: 21.04vw;
  margin: 0 auto;
  width: 53.64vw;
  max-width: 100%;
  height: inherit;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.93vw;

  @media screen and (max-width: 1440px) {
    width: 772px;
    row-gap: 28px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 308px;
    row-gap: 16px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  z-index: 2;

  @media screen and (max-width: 768px) {
    row-gap: 5.64vw;
  }
`;

const WelcomeText = styled.p`
  font-size: 1.04vw;
  line-height: 1.27vw;
  margin: 0;
  color: #27F5B0;
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 12px;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Title = styled.h2`
  font-size: 2.4vw;
  line-height: 2.92vw;
  font-weight: 800;
  margin: 1.4vw 0;
  letter-spacing: -0.0216em;
  color: #f0f0f0;

  @media screen and (max-width: 1440px) {
    font-size: 34px;
    line-height: 42px;
    margin: 20px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 39px;
    margin: 0;
  }
`;

const Text = styled.p`
  font-size: 1.25vw;
  line-height: 1.44vw;
  margin: 0;
  color: #fff;
  font-weight: 500;
  letter-spacing: -0.0216em;
  opacity: 0.7;
  max-width: 39.37vw;

  @media screen and (max-width: 1440px) {
    max-width: 567px;
    font-size: 18px;
    line-height: 21px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 18.4px;
    opacity: 0.6;
    max-width: 100vw;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, rgba(8, 3, 25, 0) 0%, #080319 100%);
  width: 100vw;
  height: 31.28vw;
  position: absolute;
  bottom: -3.59vw;
  left: 0;
  z-index: 2;
`;

const BannerWrapper = styled.div`
  width: 100vw;
  height: 51.46vw;
  background: url('/talents-banner.png') no-repeat;
  background-size: contain;
  margin-top: -3.65vw;
  padding-top: 14.48vw;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1440px) {
    height: 741px;
    margin-top: -53px;
    padding-top: 209px;
    background-size: cover;
    background-position: center;
  }

  @media screen and (max-width: 1165px) {
    height: 63.61vw;
    margin-top: -4.55vw;
    padding-top: 17.94vw;
  }

  @media screen and (max-width: 768px) {
    height: 74.61vw;
    max-height: 400px;
    margin-top: 189px;
    padding-top: 0;
    background: url('/talents-banner_mobile.png') no-repeat;
    background-size: contain;
    background-position: center bottom;
  }
`;

const BannerText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
  font-size: 1.875vw;
  line-height: 2.21vw;
  font-weight: 300;
  color: #fff;
  letter-spacing: -0.0466em;
  width: 41.04vw;
  padding: 2.34vw 3.96vw;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 1;
  position: relative;

  b {
    font-weight: 800;
  }

  @media screen and (max-width: 1440px) {
    width: 590px;
    padding: 34px 57px;
    font-size: 27px;
    line-height: 32px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 32px);
    max-width: 414px;
    font-size: 24px;
    line-height: 28.5px;
    padding: 21px 19px;
    margin-top: -35px;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: rgba(157, 149, 166, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17px);
    border-radius: inherit;
    z-index: -1;
  }
`;

const TurboDino = styled.img`
  width: 17.08vw;
  height: auto;
  position: absolute;
  top: 5.94vw;
  left: 7.24vw;
  animation: ${floatation} 14s linear infinite;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    top: 86px;
    left: 104px;
    width: 246px;
  }

  @media screen and (max-width: 1165px) {
    top: 7.43vw;
    left: 8.97vw;
    width: 21.28vw;
  }

  @media screen and (max-width: 768px) {
    top: -152px;
    left: 35px;
    width: 35.64vw;
    max-width: 220px;
    z-index: 0;
  }
`;  

const Meteor = styled.img`
  height: auto;
  position: absolute;
  width: 26.67vw;
  bottom: -22.35vw;
  right: -26.67vw;
  transition: all 2s ease-out;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 384px;
    bottom: -322px;
    right: -384px;
  }

  @media screen and (max-width: 1165px) {
    width: 32.96vw;
    bottom: -27.64vw;
    right: -32.96vw;
  }

  @media screen and (max-width: 768px) {
    width: 42.8vw;
    max-width: 250px;
    bottom: -96px;
    right: -167px;
    z-index: 2;
  }
`;

const TalentsHero = ({ isMobile }) => {

  function handleWindowScroll() {
    // Efeito de mudar imagem fixada nos passos
    const meteor = document.getElementById('meteor').getBoundingClientRect();
    
    if(meteor.top < window.innerHeight) {
      document.getElementById('meteor').style.bottom = isMobile ? '18.12vw' : '4.32vw';
      document.getElementById('meteor').style.right = '0';
    }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <div style={{overflow: 'hidden'}}>
      <HeroWrapper className='page-width'>
        <HeroContent>
          <TextWrapper>
            <WelcomeText>
              <img alt="Hello" src="hand.png" width={isMobile ? 28 : 0.0146*window.innerWidth}/>
              Boas-vindas ao foguete Turbo
            </WelcomeText>
            <Title>
              Faça parte de um time extraordinário e absolutamente fissurado em aprender
            </Title>
            {!isMobile && 
              <Text>
                Entre para o foguete Turbo e inicie uma jornada intergaláctica rumo ao seu crescimento exponencial 
              </Text>
            }
          </TextWrapper>
          {isMobile &&
            <Text>
              Entre para o foguete Turbo e inicie uma jornada intergaláctica rumo ao seu crescimento exponencial 
            </Text>
          }
          <ContactButton width={264} link="https://turbopartners.vagas.solides.com.br/" target="_blank" text="Entrar para o foguete" solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 92.58%, #EC894B 173.78%)' icon='rocket' iconHeight={16.5}/>
        </HeroContent>
        {isMobile && <Gradient/>}
          <HeroBall1 src={isMobile ? '/hero-ball-1_mobile.png' : '/hero-ball-1.png'}/>
          <HeroBall2 src={isMobile ? '/hero-ball-2_mobile.png' : '/hero-ball-2.png'}/>
          <HeroBall3 src={isMobile ? '/hero-ball-3_mobile.png' : '/hero-ball-3.png'}/>
      </HeroWrapper>
      <BannerWrapper className='page-width'>
        <BannerText>
          <span><b>Juntos,</b> vamos {isMobile ? 'ser' : 'construir'} a <b>maior</b> <b>Holding</b> de negócios digitais do <b>Brasil</b></span>
        </BannerText>
        <TurboDino src="turbo-dino.png"/>
        <Meteor src="meteor.png" id="meteor"/>
      </BannerWrapper>
    </div>
  );
};

export default TalentsHero;

