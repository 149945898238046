import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const HeroContainer = styled.div`
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  width: 100%;
  height: calc(37vw + 80px);
  position: relative;
  z-index: 5;

  @media screen and (max-width: 1440px) {
    height: 614px;
  }

  @media screen and (max-width: 768px) {
    height: max-content;
    padding-bottom: 60px;
  }
`; 

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  row-gap: 1.56vw;
  padding-top: calc(6.72vw + 80px);
  z-index: 2;
  font-family: 'Montserrat', sans serif;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
    padding-top: 177px;
  }

  @media screen and (max-width: 768px) {
    row-gap: 24px;
    width: 100%;
    padding-top: 490px;
    box-sizing: border-box;
  }
`;

const BrandRow = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  column-gap: 10px;
  z-index: 2;

  @media screen and (max-width: 768px) {
    column-gap: 7px;
  }
`;

const BrandIcon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
`;

const BrandName = styled.h2`
  color: #fff;
  font-size: 1.25vw;
  font-weight: 700;
  line-height: 1.51vw;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }

  span {
    background: #A26BE3;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 400;
  }
`;

const Title = styled.h2`
  font-size: 2.4vw;
  line-height: 2.9vw;
  font-weight: 700;
  letter-spacing: -0.0216em;
  width: 40.1vw;
  color: #fff;
  margin: 0;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 35px;
    line-height: 42px;
    width: 578px;
    max-width: 50%;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 39px;
    width: 100%;
    max-width: 100%;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 500;
  letter-spacing: -0.0108em;
  width: 40.1vw;
  margin: 0;
  color: #fff;
  opacity: 0.8;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
    width: 578px;
    max-width: 55%;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
    width: 100%;
    max-width: 100%;
  }
`;

const HeroImg = styled.img`
  position: absolute;
  top: 0;
  right: -15.02vw;
  height: inherit;
  width: auto;
  z-index: 1;

  @media screen and (max-width: 1440px) {
    right: -220px;
  }

  @media screen and (max-width: 768px) {
    top: 0;
    right: 50%;
    transform: translateX(52.5%);
    width: auto;
    height: 510px;
  }
`;

const CommerceHero = ({ isMobile }) => {

  return (
    <HeroContainer className="page-width">  
      <HeroWrapper>
        <TextWrapper>
          <BrandRow>
            <BrandIcon src={'/TurboCommerce_2.svg'}/>
            <BrandName>
              Turbo <span>Commerce</span>
            </BrandName>
          </BrandRow>
          <Title>
            Turbine o faturamento do seu negócio com o poder da internet
          </Title>
          <Subtitle>
            Tenha à sua disposição uma equipe completa especializada em escalar as vendas do seu negócio.
          </Subtitle>
          <ContactButton width={270} height={50} link='https://lp.turbopartners.com.br/commerce/lp4-2' target="_blank" text='Turbinar minhas vendas' solidButton={true} background='#A26BE3' shadow='drop-shadow(0px 16px 50px rgba(162, 107, 227, 0.38))' icon='rocket' iconHeight={15}/>
        </TextWrapper>
        <HeroImg src={'/banner-commerce-2.png'}/>
      </HeroWrapper>
    </HeroContainer>
  );
};

export default CommerceHero;
