import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const Section = styled.section`
  color: #fff;
  text-align: center;
  padding: 20px 0 100px 0px;
`;

const Title = styled.h1`
  color: #FEFEFE;
  font-family: "Sora", Helvetica;
  font-size: 48px;
  font-weight: 700;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StatCard = styled.div`
  h2 {
    color: #4E62EE;
    font-family: "Sora", Helvetica;
    font-size: 80px;
    font-weight: 700;
    margin: 0;
  }
  p {
    color: #CCCCCF;
    font-family: "Sora", Helvetica;
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const Status = ({ isMobile }) => (
  <Section>
    <Title>Decole com a Turbo</Title>
    <StatsContainer>
      <div>
        <StatCard>
          <h2>+11M</h2>
          <p>Investidos em anúncio</p>
        </StatCard>
      </div>
      <div>
        <StatCard>
          <h2>+670M</h2>
          <p>Em receita para nossos clientes</p>
        </StatCard>
      </div>
      <div>
        <StatCard>
          <h2>+840</h2>
          <p>Páginas desenvolvidas</p>
        </StatCard>
      </div>
    </StatsContainer>
    <ContactButton className="change-link" width={321} height={60} link='https://meetings.hubspot.com/joao-guarconi/consultoria-turbo-partners' solidButton={true} text="Também quero decolar" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="#4E62EE" hoverBackground="#4E62EE" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '16px', width: isMobile && '100%', margin:'0 auto' }}/>
  </Section>
);

export default Status;
