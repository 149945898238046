import React from 'react';
import styled from 'styled-components';

const TeamWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 42px;
  background: #0D0A1E;
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.59vw;
  font-weight: 400;
  margin: 0 0 3.13vw 0;
  letter-spacing: 0.015em;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 52px;
    margin: 0 0 45px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    width: 100%;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(-90deg, #4AA6F7 -20%, #1141BD 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
`; 

const Subtitle = styled.p`
  font-family: 'Graphik', sans serif;
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 400;
  margin: 16px 0 0 0;
  letter-spacing: 0.015em;
  color: #dedede;
  max-width: 40.1vw;
  
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 578px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const QuartetWrapper = styled.div`
  margin: 0 auto 5.21vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  z-index: 1;
  /* transform: translateX(100vw);
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88); */
  
  @media screen and (max-width: 1165px) {
    justify-content: center;
    margin-bottom: 75px;
  }

  @media screen and (max-width: 768px) {
    gap: 16px;
    margin-bottom: 48px;
    /* transform: translateY(50vh); */
  }
`; 

const QuartetCard = styled.div`
  width: calc((100% - 60px)/4);
  min-width: 246px;
  height: 300px;
  padding: 2px;
  border-radius: 12px;
  box-sizing: border-box;
  background: #12192B; 
  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};

  &:hover {
    background: linear-gradient(264.38deg, #4AA6F7 22.62%, #4AA6F7 29.85%, #1141BD 95.52%);
  }

  @media screen and (max-width: 1440px) {
    height: 280px;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;
    height: max-content;
  }
`; 

const QuartetCardInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 46px;
  gap: 19px;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #030917;
  
  @media screen and (max-width: 768px) {
    padding: 24px;  
  }
`;

const Icon = styled.img`
  width: ${props => props.widthItem ? `calc(${props.widthItem}*100vw/1920)` : '2.1vw'};
  height: auto;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    width: ${props => props.widthItem ? `calc(${props.widthItem}px*0.75)` : '30px'};
  }

  @media screen and (max-width: 768px) {
    width: ${props => props.widthItem ? `${props.widthItem}px` : '7.7vw'};
  }
`;  

const QuartetTitle = styled.p`
  font-size: 1.25vw;
  font-family: 'Sora', sans-serif;
  line-height: 1.3vw;
  margin: 0;
  color: #FFFFFF;
  font-weight: 600;
  letter-spacing: 0.015em;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 19px;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    margin: 0 auto;
    width: 70%;
  }
`;

const QuartetText = styled.p`
  font-size: 1.04vw;
  line-height: 1.35vw;
  margin: 0;
  color: red;
  font-weight: 400;
  letter-spacing: 0.015em;
  text-align: left;
  display: none;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`; 

const DashInfo = ({ isMobile }) => {

  const quartet = [
    {
      icon: '/lucros.png',
      width: 80,
      title: 'Descubra seu lucro verdadeiro',
      text: 'Páginas super intuitivas e fáceis de se navegar'
    },
    {
      icon: '/admin.png',
      width: 80,
      title: 'Interface amigável e intuitiva',
      text: 'Se você acha o The Flash rápido, é porque não conheceu nossos sites ;)'
    },
    {
      icon: '/produtos.png',
      width: 80,
      title: 'Priorize os produtos certos',
      text: 'Impacte seus potenciais clientes e crie uma impressão única'
    },
    {
      icon: '/graficopizza.png',
      width: 80,
      title: 'Principais KPI’s centralizados',
      text: 'No final do dia, o que importa são as vendas'
    }
  ];

  function handleWindowScroll() {
    const quartetCards = document.getElementsByClassName('quartet-cards');

    Array.from(quartetCards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <TeamWrapper className='page-width'>
      <QuartetWrapper>
        {quartet.map((quartet, index) => (
          <QuartetCard key={index} index={index} className="quartet-cards">
            <QuartetCardInner>
              <Icon src={quartet.icon} widthItem={quartet.width}/>
              <QuartetTitle>{quartet.title}</QuartetTitle>
              <QuartetText>{quartet.text}</QuartetText>
            </QuartetCardInner>
          </QuartetCard>
        ))}
      </QuartetWrapper>
      <Title style={{ display: 'none' }}>
        <div style={isMobile ? { fontSize: '40px', lineHeight: '42px' } : {}}>
          Olha só quem mora em nossa <GradientTitle>Bat-Caverna</GradientTitle>
        </div>
        <Subtitle>
          Nossa <b>Software House</b> contempla diversas especialidades que ajudarão seu negócio escalar.
        </Subtitle>
      </Title>
      <Subtitle style={{ maxWidth: 'unset', margin: '24px 0', display: 'none' }}>
        Com a <b>Turbo Tech</b>, você tem a oportunidade de terceirizar os projetos do seu negócio.
      </Subtitle>
    </TeamWrapper>
  );
};

export default DashInfo;

