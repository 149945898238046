import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 100px 0 0 0 ;

  @media screen and (max-width: 768px) {
    padding: 80px 0 0 0;
  }
`;

const PackagesWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PackageCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 16px;
  padding: 32px 25px;
  width: calc((100% - 60px)/3);
  background: ${props => props.colored ? 'linear-gradient(199.56deg, #4100C8 10.65%, #C052F3 102.35%, rgba(192, 82, 243, 0.65) 152%)' : 'transparent'};
  border: ${props => props.colored ? 'none':'1px solid #A499B2'  };
  border-radius: 16px;
  color: ${props => props.colored ? '#fff' : '#342A41'};
  font-size: 1.04vw;
  line-height: 1.25vw;
  font-weight: 400;
  transform: translate(0, 300px);
  opacity: 0;
  transition: all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
  
  h3, h4 {
    font-family: 'Oxanium';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    margin: 0;
    color: #F7F1FC;
  }

  h4 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
  }

  div div {
    padding: 16px;
    max-width: 94%;
    box-sizing: border-box;
    border: ${props => props.colored ? '1px solid #fff' : '1px solid #000'};
    border-radius: 8px;
    margin: 0;
  }

  p {
    align-self: start;
    text-align: left;
    margin: 0;
    display: flex;
    gap: 12px;
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #F7F1FC;
  }

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 18px;

    h3 {
      font-size: 27px;
      line-height: 32px;
    }

    h4 {
      font-size: 24px;
      line-height: 29px;
    }

    @media screen and (max-width: 1000px) {
      width: 338px;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      font-size: 16px;
      line-height: 19px;
      gap: 8px;
      padding: 32px 17px 70px;

      h3 {
        font-size: 32px;
        line-height: 42px;
      }

      h4 {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
`;

const SectionTitle = styled.h3`
  margin: 0 0 25px 0;
  font-family: 'Oxanium';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #F7F1FC;

  @media screen and (max-width: 1440px) {
    font-size: 36px;
    line-height: 42px;
    max-width: 609px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38px;
  }
`;
const Subtitle = styled.p`
  font-family: 'Oxanium';
  font-size: 22px;
  line-height: 130%;
  font-weight: 400;
  width: 100%;
  margin: 0 auto 70px;
  color: #fff;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 130%;
    width: 100%;
    letter-spacing: -0.005em;
  }
`;
const Star = styled.img`
  position: absolute;
  right: -2.08vw;
  top: -2.08vw;
  width: 8.54vw;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    width: 123px;
    right: -30px;
    top: -30px;
  }

  @media screen and (max-width: 768px) {
    width: 130px;
    top: -44px;
    right: -17px;
  }
`;

const PackageImage = styled.img`
  width: 4.17vw;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 60px;
  }

  @media screen and (max-width: 768px) {
    
  }
`;

const CommerceQuadros = ({ isMobile }) => {
  
  const packages = [
    {
      title: 'ACELERAÇÃO',
      price: 'a partir de: R$1.997',
      link: 'https://meetings.hubspot.com/joao-guarconi/consultoria-turbo-partners',
      items: [
        'Planejamento, gestão e estratégia dos anúncios online',
        'Trackeamento dos resultados',
        'Acompanhameto e otimização semanal',
        'Direção e criação dos criativos de venda',
        'Envio de relatórios semanais',
        'Suporte via Whatsapp',
        'Campanhas personalizadas',
        'Plataforma Google ADs (Intenção)',
        'Plataforma Facebook ADs (Atenção)'
      ],
    },
    {
      title: 'SQUAD',
      price: ' a partir de: R$6.997',
      link: 'https://meetings.hubspot.com/joao-guarconi/consultoria-turbo-partners',
      items: [
        'Escopo completo Aceleração + 👇',
        'Gestor de projetos',
        'Integração e Automações de E-mail/Whatsapp',
        'E-mail Marketing',
        'Manutenção, CRO e Alterações do Site',
        'Landing Page',
        'Consultor de vendas e processo comercial',
        'Plataforma de anuncios adicionais Tik Tok ADs, Linkedin ADs, Pinterest ADs',
        'Dashboard gerencial em tempo real'
      ],
    },
    {
      title: 'PERSONALIZADO',
      price: 'a consultar',
      link: 'https://meetings.hubspot.com/joao-guarconi/consultoria-turbo-partners',
      items: [
        'Entre em contato com o nossos consultores para entendermos mais a fundo as suas necessidades e  confeccionarmos uma proposta 100% customizada para o seu negócio.'
      ],
    }
  ]
  
  function handleWindowScroll() {
    const packageCards = document.getElementsByClassName('package-cards');

    Array.from(packageCards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  return (
    <SectionWrapper className="page-width">
      <SectionTitle id="packages" style={{ maxWidth: 'unset'}}>
        Nossos Pacotes
      </SectionTitle>
      <Subtitle id='redirect'>
        Escolha abaixo o plano que melhor se encaixa em seu negócio.
      </Subtitle>
      <PackagesWrapper>
        {packages.map((pack, index) => (
          <PackageCard key={index} colored={index === 1} index={index} className="package-cards" >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: isMobile ? 8 : 16 }}>
              {index === 1 &&
                <Star src='/creators/packages-maisvendido.png'/>
              }
              <PackageImage src={index === 1 ? '/creators/star-white.png' : '/creators/star-purple.png'}/>
              <h3>{pack.title}</h3>
              <h4>{pack.price}</h4>
              {pack.items.map((item, idx) => (
                <p key={idx}>
                  <img src={index === 1 ? '/creators/check-white.png' : '/creators/check-purple.png'} alt="check icon" width={isMobile ? "22px" : "25px"} height={isMobile ? "16px" : "18px"}/>
                  <span> 
                    {item.split(' ').map((word, wordIdx) => (
                      <span key={wordIdx} style={{ fontWeight: (index === 1 && idx === 0 && (word === 'Escopo' || word === 'completo' || word === 'Aceleração' || word === '+')) || (word === 'proposta' || word === '100%' || word === 'customizada') ? 'bold' : 'normal' }}>
                        {word}{' '}
                      </span>
                    ))}
                  </span>
                </p>
              ))}
            </div>  
            <ContactButton
             className="change-link"
             width={252}
             height={56} 
             link={pack.link}
             target="_blank"
             text="Começar agora"
             textColor={index !== 1 ? "#fff" : "linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)"} 
             font="'Oxanium'" fontSize={20} solidButton={true} 
             background={index !== 1 ? 'linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)' : '#fff'} 
             borderRadius={4}
             hoverColor= { '#fff' }
             hoverBackground={index !== 1 ? 'linear-gradient(88.92deg, rgba(65, 0, 200, 0.5) -15.8%, rgba(192, 82, 243, 0.5) 125.72%)' : '#fff'}
             innerStyle={{ marginTop: 16}}/>
          </PackageCard>  
        ))}
      </PackagesWrapper>
    </SectionWrapper>
  );
};

export default CommerceQuadros;
