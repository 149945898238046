import React from 'react';
import styled from 'styled-components';
import ContactButton from '../components/ContactButton';

const DashContainer = styled.div`
  overflow: hidden;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
`;
const Gradient1 = styled.div`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 1220px;
  top: -420px;
  left: 38px;
  opacity: 0.2;
  background: radial-gradient(circle,rgba(92,79,238,1) 0%,rgba(0,0,0,0) 75%);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    width: 470px;
    height: 350px;
    top: -150px;
    left: -80px;
  }
`;
const Gradient2 = styled.div`
  position: absolute;
  z-index: 1;
  width: 830px;
  height: 580px;
  top: 130px;
  right: -250px;
  opacity: 0.3;
  background: radial-gradient(circle,rgba(92,79,238,1) 0%,rgba(0,0,0,0) 55%);
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const DashWrapper = styled.div`
  width: 100%;
  display: flex;
  padding: calc(8.18vw + 80px) 0 100px ;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    padding: 100px 0;
    height: auto;
  }
`; 

const TextWrapper = styled.div`
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  z-index: 2;
  width: 560px;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-family: 'Sora';
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 150%;
  align-items: Left;
  vertical-align: Top;
  width: 100%;
  margin: 0 0 24px 0;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 130%;
    max-width: 100%;
    margin: 0 0 32px 0;
  }
`;

const Subtitle = styled.p`
  font-family: 'Graphik', sans-serif;
  font-size: 20px;
  line-height: 130%;
  font-weight: 400;
  width: 100%;
  margin: 0 0 24px 0;
  color: #fff;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    margin: 0 0 32px 0;
    letter-spacing: -0.005em;
  }
`;

const Afterbutton = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  line-height: 100%;
  font-weight: 400;
  width: 100%;
  margin: 24px 0 0 0;
  color: #fff;
  font-style: italic;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 19px;
  }

  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 19px;
    width: 100%;
    margin: 32px 0;
  }
`
const Logo = styled.img`
  max-width: 100%;
  height: 40px;


  @media screen and (max-width: 920px) and (min-width: 768px) {
  }
  @media screen and (max-width: 768px) {
    height: 28px;
    width: 100%;
    margin: 0 0 50px 0;
    display: flex;
    align-items: center;
    text-align: center !important;
  }
`;

const HeroImg = styled.img`
  height: 100%;
  width: 50%;
  z-index: 2;

  @media screen and (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;



const DashIntro = ({ isMobile }) => {

  return (
    <DashContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <DashWrapper>

        <TextWrapper>
          <Logo src={'logodash.svg'}/>
          <Title>
            Transforme seus dados em lucro. <b style={{color: '#5B4EEE'}}><i>Mais rápido</i></b>
          </Title>
          <Subtitle>
            O dashboard feito para gestores de tráfego e donos de
            e-commerce escalarem negócios com mais<b> clareza</b>, <b>segurança</b> e <b>agilidade.</b>         
          </Subtitle>
          <ContactButton link="https://apps.shopify.com/turbodash" target="_blank" icon="arrow" height={60} 
            solidButton={true} text="Testar por 7 dias Grátis" 
            fontSize={16} font="'Sora', sans serif" 
            fontWeight={700} textColor="#F2EDF9" 
            background="#5B4EEE" 
            hoverBackground="#3C368A" 
            shadow="unset" 
            borderRadius={4} 
            innerStyle={{ width: '100%', textDecoration: 'none' }}/>         
          <Afterbutton>
            Experimente sem precisar cadastrar seu cartão
          </Afterbutton>
        </TextWrapper>
        <Gradient1/>
        <HeroImg src={isMobile ? 'intro-dashmobile.png' : 'intro-dash.png'}/>
        <Gradient2/>
      </DashWrapper>
    </DashContainer>
  );
};

export default DashIntro;
