import React from 'react';
import Commerce1 from '../sections/Commerce1';

const LPCommerce = ({ isMobile }) => {

  return (
    <div style={{ backgroundColor: '#0B041B' }}>
      <Commerce1 isMobile={isMobile}/>
    </div>
  )
};

export default LPCommerce;
