import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  color: #fff;
  display: flex;
  text-align: center;
  width: 390px;
  border-radius: 13.801px;
  border: 0.863px solid rgba(255, 255, 255, 0.20);
  background: rgba(22, 16, 41, 0.30);
  flex-direction: column;
`;
const TextOrder = styled.div`
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const Title = styled.h2`
  color: #FEFEFE;
  font-family: "Sora", Helvetica;
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  text-align: left;
`;

const Subtitle = styled.p`
  color: #9A9FC0;
  font-family: "Sora", Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 0.8rem;
  margin: 0;
  text-align: left;
`;

const Image = styled.img`
  height: 330px;
  border-radius: 0 0 13px 13px;
`;

const ResultCard = ({ result }) => (
  <Card>
    <TextOrder>
      <div>
        <Title>{result.title}</Title>
        <Subtitle>{result.subtitle}</Subtitle>
      </div>
      <div>
        <Title>{result.title2}</Title> 
        <Subtitle>{result.subtitle2}</Subtitle> 
      </div>
    </TextOrder>
    <Image src={result.img} alt="image" />
  </Card>
);


export default ResultCard;
