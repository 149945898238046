import React from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import CommerceHero from '../sections/CommerceHero';
import CommerceContent from '../sections/CommerceContent';
import ImageMarquee from '../components/ImageMarquee';
import { Helmet } from 'react-helmet';
// import { Popover } from '@typeform/embed-react';

const TurboCommerce = ({ isMobile, isTablet }) => {

  const logos = [
    {
      desktop: '/logo_shopify.png',
      mobile: '/logo-mobile_shopify.png',
      url: 'https://www.shopify.com/br/parcerias'
    },
    {
      desktop: '/logo_tiktok.png',
      mobile: '/logo-mobile_tiktok.png',
      url: 'https://www.tiktok.com/business/pt-BR'
    },
    {
      desktop: '/logo_google.png',
      mobile: '/logo-mobile_google.png',
      url: 'https://www.google.com/intl/pt-BR/partners/become-a-partner/'
    },
    {
      desktop: '/logo_meta.png',
      mobile: '/logo-mobile_meta.png',
      url: 'https://www.facebook.com/business/marketing-partners/become-a-partner'
    },
    {
      desktop: '/logo_pagarme.png',
      mobile: '/logo-mobile_pagarme.png',
      url: 'https://pagar.me/'
    }
  ];

  return (
    <div style={{ backgroundColor: '#0D031A' }}>
      <Helmet>
        <title>Turbo Commerce</title>
        <meta name="description" content="Turbine o faturamento do seu negócio com o poder da internet"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <CommerceHero isMobile={isMobile} />
      <ImageMarquee logos={logos} isMobile={isMobile}  background={isMobile ? 'rgba(13, 3, 26, 0.5)' : '#0D031A'} border={'1px solid #1B3F71'} borderBetween={'1px solid #1B2F4A'} spaceBetween={isMobile ? 52 : 80} height={isMobile ? 70 : 121} marginTop={'-2.3vw'}/>
      <CommerceContent isMobile={isMobile} />
      <Footer isMobile={isMobile}  background={'#060613'}/>
    </div>
  )
};

export default TurboCommerce;
