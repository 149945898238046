import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.0216em;  
  color: #FFF;
  position: relative;
  z-index: 1;
  min-width: 235px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(45, 35, 56, 0.1);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    z-index: -1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 900px) and (min-width: 769px) {
    padding: 20px;
    max-width: 300px;
    min-width: 212px;
  }

  @media screen and (max-width: 768px) {
    color: rgba(255, 255, 255, 0.7);
  }
`; 

const Text = styled.p`
  font-size: 0.94vw;
  line-height: 1.25vw;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`; 

const Line = styled.div`
  background: linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 76.86%, #EC894B 97.89%);
  height: 1px;
  width: 100%;
  margin: 16px 0;
`;

const ClientRow = styled.div`
  display: flex;
  align-items: center;
`;

const Photo = styled.img`
  border-radius: 60px;
  height: 44px;
  width: 44px;
  margin: 0 11px 0 0;

  @media screen and (max-width: 768px) {
    width: 51px;
    height: 51px;
    margin: 0 12px 0 3px;
  }
`;

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h4`
  font-size: 1.04vw;
  line-height: 1.2vw;
  font-weight: 700;
  margin: 4px 0;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 17px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    color: #fff;
  }
`;

const Role = styled.p`
  font-size: 0.83vw;
  line-height: 0.99vw;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const CommentsCard = ({ client }) => {

  return (
    <CardWrapper>
      <Text>
        “{client.text}”
      </Text>
      <div style={{ width: '100%' }}>
        <Line/>
        <ClientRow>
          <Photo src={client.photo}/>
          <ClientInfo>
            <Name>{client.name}</Name>
            <Role>{client.role}</Role>
          </ClientInfo>
        </ClientRow>
      </div>
    </CardWrapper>
  );
};

export default CommentsCard;

