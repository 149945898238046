import React from 'react';
import styled from 'styled-components';
import { SlSocialLinkedin, SlSocialInstagram } from 'react-icons/sl';
import ContactButton from '../components/ContactButton';

const MessageWrapper = styled.div`
  width: 100%;
  height: 56.56vw;
  background: url('/thankyou-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  
  @media screen and (max-width: 1440px) {
    height: 814px;
  }

  @media screen and (max-width: 768px) {
    background: unset;
    height: max-content;
  }
`; 

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 20px;
  width: 100%;
  font-weight: 400;
  padding: 14.95vw 0 0 0;

  @media screen and (max-width: 1440px) {
    padding: 215px 0 0 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 146px 0 70px 0;
    box-sizing: border-box;
  }
`;

const Title = styled.h2`
  font-size: 2.91vw;
  line-height: 3.54vw;
  margin: 0;
  color: #f0f0f0;
  letter-spacing: -0.0216em;

  @media screen and (max-width: 1440px) {
    font-size: 42px;
    line-height: 51px;
  }

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 43.5px;
  }
`;

const Text = styled.p`
  font-size: 1.25vw;
  line-height: 1.67vw;
  margin: 0 0 20px 0;
  color: #EDEDED;
  opacity: 0.7;
  letter-spacing: -0.0108em;
  max-width: 52.35vw;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 24px;
    max-width: 754px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19.5px;
    max-width: 100%;
    margin: 0;
  }
`;

const SocialsWrapper = styled.div`
  width: 100%;
  height: 28.125vw;
  padding: 3.96vw 4.27vw;
  box-sizing: border-box;
  margin: -3.96vw 0 7.5vw 0;
  background: rgba(22, 16, 41, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @media screen and (max-width: 1440px) {
    height: 405px;
    padding: 57px 62px;
    margin: -57px 0 108px 0;
  }

  @media screen and (max-width: 768px) {
    max-width: 414px;
    height: unset;
    margin: 0 auto;
    padding: 30px;
    box-sizing: border-box;
    z-index: 2;
    background: rgba(22, 16, 41, 0.69);
    backdrop-filter: blur(21px);
  }
`;

const GradientTag = styled.div`
  padding: 1px;
  border-radius: 4px;
  width: 21.41vw;
  height: 2.71vw;
  background: linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 76.86%, #EC894B 97.89%);
  position: relative;
  display: flex;

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 21.41vw;
    height: 2.71vw;
    background: #161029;
    border-radius: inherit;
  }
  @media screen and (max-width: 1440px) {
    width: 308px;
    height: 40px;

    &:before {
      width: 308px;
      height: 40px; 
    }
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;

    &:before {
      width: calc(100% - 2px);
      height: calc(100% - 2px);
    }
  }
`;

const TagInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25vw;
  line-height: 1.87vw;
  font-weight: 400;
  width: 21.41vw;
  height: 2.71vw;
  color: #fff;
  letter-spacing: -0.005em;
  background: rgba(162, 107, 227, 0.05);
  border-radius: inherit;
  z-index: 1;
  
  @media screen and (max-width: 1440px) {
    width: 308px;
    height: 40px;
    font-size: 18px;
    line-height: 27px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 20px;
  }
`;

const SocialText = styled.p`
  margin: 0;
  max-width: 28.8vw;
  text-align: left;
  font-size: 2.91vw;
  line-height: 3.53vw;
  color: #f0f0f0;
  font-weight: 400;
  letter-spacing: -0.0216em;

  @media screen and (max-width: 1440px) {
    max-width: 415px;
    font-size: 42px;
    line-height: 51px;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 36px;
    line-height: 43.5px;
  }
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 1.14vw; 
  column-gap: 10px;
  width: 11.77vw;
  height: 3.59vw; 
  font-size: 0.83vw;
  line-height: 1.25vw;
  font-weight: 600;
  color: #fff;
  background: rgba(22, 16, 41, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
    
  @media screen and (max-width: 1440px) {
    padding-left: 16px; 
    width: 170px;
    height: 52px; 
    font-size: 12px;
    line-height: 18px;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 69px;
    padding-left: 16px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
  }
`;

const SocialIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.08vw;
  height: 2.08vw;
  background: rgba(55, 49, 75, 0.61);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  
  @media screen and (max-width: 1440px) {
    width: 30px;
    height: 30px;
  }
  
  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

const SocialImg = styled.img`
  width: 50.36vw;
  height: auto;
  position: absolute;
  bottom: 0;
  right: -6.04vw;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 601px;
    top: -410px;
    right: 50%;
    transform: translateX(50%);
  }
`;

const ThanksMessage = ({ isMobile }) => {

  return (
    <>
      <MessageWrapper className='page-width'>
        <TextWrapper>
          <Title>
          Infelizmente, não está no momento de <b style={{color: '#da43a2'}}>Turbinar</b> seu negócio...</Title>
          <Text>
          Mas não fique triste! Preparamos Materiais Gratuitos sobre negócios e marketing digital para você.</Text>
          <ContactButton width={217} link="https://blog.turbopartners.com.br/materiais/" target="_blank" text="Ver Materiais" solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 92.58%, #EC894B 173.78%)' icon='rocket' iconHeight={16.5}/>
        </TextWrapper>
      </MessageWrapper>
      <div className='page-width' style={isMobile ? { position: 'relative', margin: '410px 0 150px 0' } : {}}>
        <SocialsWrapper>
          <GradientTag>
            <TagInnerWrapper>
              VENHA NOS CONHECER MELHOR
            </TagInnerWrapper>
          </GradientTag>
          <SocialText>Nos <b>acompanhe</b> nas redes sociais</SocialText>
          <SocialLinksWrapper>
            <SocialLink href="https://www.instagram.com/turbo.partners/" target="_blank">
              <SocialIcon>
                <SlSocialInstagram size={isMobile ? 27 : 0.0141*window.innerWidth} color="#fff"/>
              </SocialIcon>
              @turbo.partners
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/turbo-partners" target="_blank">
              <SocialIcon>
                <SlSocialLinkedin size={isMobile ? 24 : 0.0125*window.innerWidth} color="#fff"/>
              </SocialIcon>
              turbo-partners
            </SocialLink>
          </SocialLinksWrapper>
          {!isMobile && <SocialImg src="/socials-image.png"/>}
        </SocialsWrapper>
        {isMobile && <SocialImg src="/socials-image_mobile.png"/>}
      </div>
    </>
  );
};

export default ThanksMessage;

