import React from 'react';
import styled from 'styled-components';
import SubmenuItem from './SubmenuItem';
import SubmenuCard from './SubmenuCard';


const Dropdown = styled.button`
  z-index: 100;
  border: none;
  background-color: unset;
  cursor: pointer;
  padding: 0 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.04vw;
  line-height: 1.25vw;
  font-weight: ${props => (props.dropdown ? 700 : 400)};
  color: ${props => (props.dropdown ? '#4E62EE' : '#E8E8E8')};
  text-decoration: none;
    
  &:hover {
    font-weight: 700;
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 1165px) {
    width: max-content;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    padding: 0 16px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  list-style: none;
  flex-wrap: nowrap;
  padding: 5px 0;
  text-align: left;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
`;

const MenuLink = styled.a`
  z-index: 100;
  text-decoration: none;
  color: #E8E8E8;
  padding: 0 16px;
  display: flex;
  align-items: start;
  column-gap: 8px;
  font-weight: 400;
  font-size: 1.04vw;
  line-height: 1.25vw;
    
  &:hover {
    font-weight: ${props => (props.active ? '700' : '400')};
    cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};
  }

  @media screen and (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 1165px) {
    padding: 0;
    width: min-content;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    width: max-content;
    padding: 0 16px;
  }
`;

const Subitems = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 2.2vw 1.2vw;
  padding: 108px 13.02vw 42px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(8, 5, 18, 0.8);
  transition: opacity 1s ease-in;
  backdrop-filter: blur(25.5px);
  -webkit-backdrop-filter: blur(25.5px);

  @media screen and (max-width: 1440px) {
    padding: 108px calc((100vw - 1065px)/2) 42px;
  }

  @media screen and (max-width: 1165px) {
    padding: 108px 50px 42px;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px 20px;
  }

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    background-color: unset;
    position: relative;
    padding: 24px 16px;
    gap: 16px;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const MenuItem = ({ item, click, dropdown, setDropdown, index }) => {

  function dropdownClick() {
    if(dropdown !== index) {
      if(dropdown === -1) {
        click && click();
      }
      setDropdown(index);
    } else {
      setDropdown(-1);
      click && click();
    }
    console.log(dropdown);
  }

  return (
  <ListItem submenu={item.submenu}>
    {item.submenu ? (
      <>
        <Dropdown dropdown={index === dropdown} type="button" onClick={() => dropdownClick()}>
          {item.title}{' '}
          <svg style={{ marginLeft: 8, transform: index === dropdown && 'rotate(180deg)', transition: 'all 0.2s linear' }} width="11" height="8" viewBox="0 0 11 8" fill='none' xmlns="http://www.w3.org/2000/svg">
            <path d="M4.85816 6.77716L0.239162 2.15916C0.163361 2.08371 0.103213 1.99403 0.0621704 1.89527C0.0211279 1.79651 0 1.69061 0 1.58366C0 1.47671 0.0211279 1.37082 0.0621704 1.27205C0.103213 1.17329 0.163361 1.08361 0.239162 1.00816L1.00316 0.239161C1.07861 0.163361 1.16829 0.103213 1.26705 0.062171C1.36582 0.0211285 1.47171 0 1.57866 0C1.68561 0 1.79151 0.0211285 1.89027 0.062171C1.98903 0.103213 2.07871 0.163361 2.15416 0.239161L5.42816 3.51316L8.70316 0.239161C8.77861 0.163361 8.86829 0.103213 8.96705 0.062171C9.06581 0.0211285 9.17171 0 9.27866 0C9.38561 0 9.49151 0.0211285 9.59027 0.062171C9.68903 0.103213 9.77871 0.163361 9.85416 0.239161L10.6222 1.00716C10.698 1.08261 10.7581 1.17229 10.7992 1.27105C10.8402 1.36982 10.8613 1.47571 10.8613 1.58266C10.8613 1.68961 10.8402 1.79551 10.7992 1.89427C10.7581 1.99303 10.698 2.08271 10.6222 2.15816L6.00316 6.77716C5.92804 6.85299 5.83863 6.91318 5.74011 6.95426C5.64159 6.99534 5.5359 7.01649 5.42916 7.01649C5.32242 7.01649 5.21674 6.99534 5.11821 6.95426C5.01969 6.91318 4.93029 6.85299 4.85516 6.77716H4.85816Z" fill={index === dropdown ? '#4E62EE' : '#fff'}/>
          </svg>
        </Dropdown>
        <Subitems style={{ transform: index === dropdown ? 'translateY(0px)' : 'translateY(-150%)', display: index === dropdown ? 'grid' : 'none' }}>
          {item.submenu.map((submenu, index) => (
            submenu.card ? (
              <SubmenuCard key={index} submenu={submenu}/>
            ) : (
              <SubmenuItem key={index} submenu={submenu}/>
            )
          ))}
        </Subitems>
      </>
    ) : (
      <MenuLink className='change-link' active={item.url !== ''} href={item.url} target={item.targetOut ? '_blank' : ''} rel="noreferrer" style={ item.url === window.location.pathname ? { fontWeight: 700, color: '#4E62EE' } : {}}>{item.title}</MenuLink>
    )}
  </ListItem>
 );
};

export default MenuItem;
