import React from 'react';
import styled from 'styled-components';

const DashContainer = styled.div`
  overflow: hidden;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  margin-top: -10px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
const DashWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 24px;
    padding: 0 0 50px 0;
    height: auto;
  }
`; 

const TextWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100%;
  
  @media screen and (max-width: 1440px) {
    row-gap: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h2`
  font-family: 'Sora';
  font-weight: 700;
  font-size: 46px;
  color: #fff;
  line-height: 150%;
  align-items: Left;
  vertical-align: Top;
  width: 100%;
  margin: 0 0 24px 0;

  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 50px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 36px;
    max-width: 100%;
    margin: 0 0 32px 0;
  }
`;
const Subtitle = styled.p`
  font-size: 20px;
  line-height: 130%;
  font-weight: 400;
  width: 100%;
  margin: 0 0 50px 0;
  color: #fff;
  z-index: 2;

  @media screen and (max-width: 1440px) {
    font-size: 24px;
    line-height: 27px;
    width: 696px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    width: 90%;
    margin: 0;
    letter-spacing: -0.005em;

    br:last-of-type {
      display: none;
    }
  }
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    height: auto;
    width: 100%;
  }
`;
const SpaceImg = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 32px;
    height: auto;
  }
`;
const DashImg = styled.img`
  width: 30%;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
    right: 0;
  }
  @media screen and (max-width: 425px) {
    width: 100%;
  }

  transform: translate(0, 300px);
  opacity: 0;
  transition: all 1.5s cubic-bezier(0.8, 0.28, 0.29, 0.88);
  transition-delay: ${props => `calc(${props.index}*0.1s)`};
`;

const DashGrafico = ({isMobile}) => {

  function handleWindowScroll() {
    const Cards = document.getElementsByClassName('fade');

    Array.from(Cards).forEach((card) => {
      if(card.getBoundingClientRect().top < (isMobile ? 1.4*window.innerHeight : 1.2*window.innerHeight)) {
        card.style.transform = 'translate(0, 0)';
        card.style.opacity = '1';
      }
    })
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });


  return (
    <DashContainer background={window.innerWidth < 450 ? 'background-tech_mobile.png' : 'background-tech.png'} className="page-width">  
      <DashWrapper>
        <TextWrapper>
          <Title>
            Isso aqui nenhuma conta<b style={{color: '#5B4EEE'}}> te conta</b>...
          </Title>
          <Subtitle>
            Nem o Google nem o Face.
            <br></br>
            <br></br>
            O Turbo Dash te entrega um comparativo claro e preciso em relação <b>ao que sai</b> e <br></br><b>o que entra</b> no seu bolso.
          </Subtitle>
        </TextWrapper>
        <ImgWrapper>
          <SpaceImg>
            <DashImg src={'graficoex1.png'} className="fade"/>
            <DashImg src={'graficoex2.png'} className="fade"/>
            <DashImg src={'graficoex3.png'} className="fade"/>
          </SpaceImg>
        </ImgWrapper>
      </DashWrapper>
    </DashContainer>
  )
};

export default DashGrafico;
