import React from 'react';
import Header from '../sections/Header';
import Footer from '../sections/Footer';
import TechHero from '../sections/TechHero';
import TechTeam from '../sections/TechTeam';
import TechBoost from '../sections/TechBoost';
import TechImpact from '../sections/TechImpact';
import TechDevelopment from '../sections/TechDevelopment';
import TechCharacter from '../sections/TechCharacter';
import { Helmet } from 'react-helmet';

const TurboTech = ({ isMobile, isTablet }) => {

  return (
    <div style={{ backgroundColor: '#030917' }}>
      <Helmet>
        <title>Turbo Tech</title>
        <meta name="description" content="Turbine seu negócio com o poder da tecnologia"/>
      </Helmet>
      <Header  isTablet={isTablet}/>
      <TechHero isMobile={isMobile} />
      <TechTeam isMobile={isMobile} />
      <TechCharacter isMobile={isMobile} />
      <TechBoost isMobile={isMobile} />
      <TechImpact isMobile={isMobile} />
      <TechDevelopment isMobile={isMobile} />
      <Footer isMobile={isMobile}  background={'#060613'}/>
    </div>
  )
};

export default TurboTech;
