import React from 'react';
import Header from '../sections/Header';
import ThanksMessage from '../sections/ThanksMessage2';


import Footer from '../sections/Footer';

const Bye = ({ isMobile, isTablet }) => {

  return (
    <div style={{ backgroundColor: '#0D031A' }}>
      <Header  isTablet={isTablet}/>
      <ThanksMessage isMobile={isMobile} />
      <Footer isMobile={isMobile} />
    </div>
  )
};

export default Bye;
