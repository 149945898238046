import React from 'react';
import styled, { keyframes } from 'styled-components';
import CasesCard from '../components/CasesCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import ContactButton from '../components/ContactButton';
import 'swiper/css/autoplay';

const swipe = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`

const marqueeAnimationLeft = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-33.33%); }
`

const marqueeAnimationRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(33.33%); }
`

const floatation = keyframes`
	from { transform: rotate(0deg) translateX(max(2vw, 25px)) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(max(2vw, 25px)) rotate(0deg); }
`

const floatation2 = keyframes`
	from { transform: rotate(0deg) translateX(max(2vw, 25px)) rotate(0deg); }
  to   { transform: rotate(360deg) translateX(max(2vw, 25px)) rotate(-360deg); }
`

const PartnersWrapper = styled.div`
  width: 100%;
  padding: 120px 0 23px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 76px;

  .swiper {
    overflow: hidden;
    width: 100%;
    padding: 0 1px;
    cursor: grab;
    position: relative;
  }

  .swiper-wrapper {
    display: flex;
    transition-timing-function : linear;
  }

  @media screen and (max-width: 768px) {
    row-gap: 50px;
  }
`; 

const Title = styled.h2`
  font-size: 2.08vw;
  line-height: 2.54vw;
  font-weight: 800;
  margin: 0;
  color: #fff;
  letter-spacing: -0.032em;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
  }
`;

const SwiperManual = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1px 0;
  position: relative;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  padding: 0 16px;
  gap: 26px;
  animation: ${swipe} 32s linear infinite;
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 119px;
  overflow-x: hidden;
  background: rgba(13, 3, 26, 0.5);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: ${props => (props.left ? 'start' : 'end')};
  margin-top: ${props => (props.top ? '-142px' : '0')};
  z-index: 2;

  @media screen and (max-width: 1440px) {
    height: ${props => (props.top ? '90px' : '78px')};
    margin-top: ${props => (props.top ? '-75px' : '0')};
  }

  @media screen and (max-width: 768px) {
    height: ${props => (props.top ? '70px' : '58px')};
    margin-top: ${props => (props.top ? '-33px' : '0')};
  }
`;

const MarqueeTrackLeft = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationLeft} 16s linear infinite;
`;

const MarqueeTrackRight = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationRight} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const FadeOutEffect = styled.div`
  width: 13.02vw;
  height: 520px;
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 5;
  background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: calc((100vw - 1065px)/2);
    min-width: 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const GradientEffect = styled.div`
  width: 24.22vw;
  height: 391px;
  position: absolute;
  top: -14px;
  left: 0;
  z-index: 5;
  background: linear-gradient(90deg, #08031A 0%, #08031A 55%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: calc(160px + (100vw - 1065px)/2);
  }

  @media screen and (max-width: 1165px) {
    height: 265px;
    width: 210px;
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 180px;
    top: 0;
    background: linear-gradient(90deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);
  }
`;

const LogoWrapper = styled.a`
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 40px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  @media screen and (max-width: 768px) {
    padding: 0 26px;
    height: 39px;
  }
`;

const PartnersLogo = styled.img`
  height: auto;
  width: auto;
`;

const PartnersContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 5.3vw 0 4.6vw;
  margin-bottom: 6.9vw;
  position: relative;

  @media screen and (max-width: 1440px) {
    padding: 76px 0 66px;
    margin-bottom: 99px;
  }

  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    padding: 94px 0 100px;
    margin-bottom: 45px;
  }
`;

const PartnersTitle = styled.h2`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 0 3px 0;
  font-size: 1.875vw;
  line-height: 2.26vw;
  color: #f0f0f0;
  font-weight: 700;
  letter-spacing: -0.0466em;
  max-width: calc(100% - 32px);

  span {
    font-family: 'Caveat', cursive;
    margin: 0;
    font-size: 2.5vw;
    line-height: 3.15vw;
    font-weight: 400;
    color: #fff;
    letter-spacing: -0.032em;
  }

  @media screen and (max-width: 1440px) {
    font-size: 27px;
    line-height: 32px;

    span {
      font-size: 36px;
      line-height: 45px;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    z-index: 1;

    span {
      font-size: 32px;
      line-height: 40px;
    }
  }
`;

const Planet1 = styled.img`
  height: auto;
  width: 39.06vw;
  position: absolute;
  top: 3vw;
  left: -17.58vw;
  animation: ${floatation} 24s linear infinite;
  animation-delay: -8s;

  @media screen and (max-width: 1440px) {
    width: 562px;
    top: 43px;
    left: -253px;
  }

  @media screen and (max-width: 768px) {
    width: 324px;
    top: 39px;
    left: -94px;
    z-index: 0;
  }
`;

const Planet2 = styled.img`
  height: auto;
  width: 53.02vw;
  position: absolute;
  top: 3vw;
  right: -23.86vw;
  animation: ${floatation} 24s linear infinite;
  animation-delay: -16s;

  @media screen and (max-width: 1440px) {
    width: 763px;
    top: 43px;
    right: -343px;
  }

  @media screen and (max-width: 768px) {
    width: 468px;
    top: 448px;
    right: -195px;
    z-index: 0;
  }
`;

const Planet3 = styled.div`
  height: 11vw;
  width: 11vw;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 9.17vw;
  animation: ${floatation} 16s linear infinite;
  background: radial-gradient(87.84% 87.84% at 59.08% 12.16%, rgba(14, 17, 219, 0.2) 19.17%, rgba(120, 107, 227, 0.2) 63.97%, rgba(162, 107, 227, 0.2) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  filter: blur(15px);
  transform: rotate(-45deg);

  @media screen and (max-width: 1440px) {
    width: 158px;
    height: 158px;
    left: 132px;
  }
`;

const LogoDino = styled.img`
  height: auto;
  width: 14.375vw;
  position: absolute;
  bottom: 7.2vw;
  right: 12.64vw;
  animation: ${floatation2} 12s linear infinite;
  animation-delay: -6s;
  z-index: 0;

  @media screen and (max-width: 1440px) {
    width: 207px;
    bottom: 103px;
    right: 182px;
  }

  @media screen and (max-width: 768px) {
    width: 92px;
    top: 50%;
    left: calc(50% - 46px);
    z-index: 2;
  }
`;

const PartnersImage = styled.img`
  height: auto;
  width: 734px;
  margin: 55px 0 78px 0;
  z-index: 1;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 500px;
    margin: 32px 0 50px 0;
  }
`;

const CompaniesTitle = styled.div`
  font-size: 1.875vw;
  line-height: 2.26vw;
  font-weight: 400;
  letter-spacing: -0.0466em;
  color: #f0f0f0;
  margin: 0 0 4.58vw 0;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  background: url('companies-line.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  @media screen and (max-width: 1440px) {
    font-size: 27px;
    line-height: 32px;
    margin: 0 0 66px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 43px;
    margin: 0 0 32px 0;
    max-width: 390px;
    width: 100%;
    background: url('companies-line_mobile.png') center bottom no-repeat;
    background-size: 197px 15px;
  }
`;

const CompaniesWrapper = styled.div`
  margin: 14px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;


const Partners = ({ isMobile }) => {
  const [initAutoplay, setInitAutoplay] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  function handleWindowScroll() {
    const nextSectionTop = document.getElementById('next-section').getBoundingClientRect().top;

    if(nextSectionTop < 0.6*window.innerHeight) {
      setInitAutoplay(true);
      document.getElementById('partners-cards').style.transform = 'translateX(0)';
    }
  }

  React.useEffect(() => {    
    window.addEventListener("scroll", handleWindowScroll);
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    }
  });

  const partnersInfo = [
    {
      img: 'card-partners_get-power.png',
      logo: 'logo_get-power.png',
      content: '0 a 5 milhões em vendas mensais'
    },
    {
      img: 'card-partners_academia-do-sol.png',
      logo: 'logo_academia-do-sol.png',
      content: '100 mil em vendas em apenas 1 dia'
    },
    {
      img: 'card-partners_brota.png',
      logo: 'logo_brota.png',
      content: '+200k de receita na black friday'
    },
    {
      img: 'card-partners_carla-lorenzon.png',
      logo: 'logo_carla-lorenzon.png',
      content: 'De 3.8k seguidores para 78k em um mês e meio'
    },
    {
      img: 'card-partners_luma.png',
      logo: 'logo_luma.png',
      content: 'Redução de 60% no CAC com triplo de investimento mensal'
    },
    {
      img: 'card-partners_byr.png',
      logo: 'logo_byr.png',
      content: 'Aumento de 900% do faturamento'
    },
    {
      img: 'card-partners_nina-saúde.png',
      logo: 'logo_nina-saúde.png',
      content: 'Aumento do faturamento em 158% em um semestre'
    }
  ];
  
  const companies = [
    [
      {
        desktop: 'logo_brota.png',
        mobile: 'logo-mobile_brota.png',
        url: 'https://brotacompany.com.br/'
      },
      {
        desktop: 'logo_soul-zen.png',
        mobile: 'logo-mobile_soul-zen.png',
        url: 'https://soulzenshop.com/'
      },
      {
        desktop: 'logo_martha-campos.png',
        mobile: 'logo-mobile_martha-campos.png',
        url: 'https://shopmarthacampos.com.br/'
      },
      {
        desktop: 'logo_vividus.png',
        mobile: 'logo-mobile_vividus.png',
        url: 'https://www.vividus.com.br/'
      },
      {
        desktop: 'logo_nutsco.png',
        mobile: 'logo-mobile_nutsco.png',
        url: 'https://nutsco.com/'
      },
      {
        desktop: 'logo_grupo-v3.png',
        mobile: 'logo-mobile_grupo-v3.png',
        url: 'https://v3grupo.com.br/'
      }
    ],
    [
      {
        desktop: 'logo_luma.png',
        mobile: 'logo-mobile_luma.png',
        url: 'https://lumaensino.com.br/'
      },
      {
        desktop: 'logo_byr.png',
        mobile: 'logo-mobile_byr.png',
        url: 'https://lojabyr.com.br/'
      },
      {
        desktop: 'logo_it-style.png',
        mobile: 'logo-mobile_it-style.png',
        url: 'https://itstyle.store/'
      },
      {
        desktop: 'logo_ninasaúde.png',
        mobile: 'logo-mobile_ninasaúde.png',
        url: 'https://ninasaude.com.br/'
      },
      {
        desktop: 'logo_get-power.png',
        mobile: 'logo-mobile_get-power.png',
        url: 'https://getpowersolar.com.br/'
      },
      {
        desktop: 'logo_cale.png',
        mobile: 'logo-mobile_cale.png',
        url: 'https://ecale.com.br/'
      },
      {
        desktop: 'logo_peppy-berry.png',
        mobile: 'logo-mobile_peppy-berry.png',
        url: 'https://peppyberry.com.br/'
      }
    ],
    [
      {
        desktop: 'logo_gula-fitfood.png',
        mobile: 'logo-mobile_gula-fitfood.png',
        url: 'https://gulafit.com.br/'
      },
      {
        desktop: 'logo_bready.png',
        mobile: 'logo-mobile_bready.png',
        url: 'https://meubready.com.br/'
      },
      {
        desktop: 'logo_total-health.png',
        mobile: 'logo-mobile_total-health.png',
        url: '/'
      },
      {
        desktop: 'logo_tucan.png',
        mobile: 'logo-mobile_tucan.png',
        url: 'https://tucan.coffee/'
      },
      {
        desktop: 'logo_divn.png',
        mobile: 'logo-mobile_divn.png',
        url: 'https://divn.com.br/'
      },
      {
        desktop: 'logo_cooltiva.png',
        mobile: 'logo-mobile_cooltiva.png',
        url: 'https://cooltiva.com.br/'
      },
      {
        desktop: 'logo_gkbrand.png',
        mobile: 'logo-mobile_gkbrand.png',
        url: 'https://gkbrand.com.br/'
      }
    ]
  ];

  return (
    <div id="next-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden' }}>
      <PartnersWrapper>
        <Title className='page-width'>
          Parcerias de Sucesso
        </Title>
        <div style={{ height: isMobile ? '370px' : '450px' }}>
          {initAutoplay &&
            isMobile ? (
              <SwiperManual id="partners-cards">
                <SwiperWrapper onTouchStart={() => setClicked(true)} style={clicked ? {animationPlayState: 'paused', animationName: 'none', animationFillMode: 'none'} : {}}>
                  {partnersInfo.map((partner, index) => (
                    <div key={index}>
                      <CasesCard cases={partner} gradient='linear-gradient(179.75deg, rgba(8, 3, 26, 0) -31.38%, #08031A 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid rgba(255, 255, 255, 0.2)' line='rgba(120, 107, 227, 0.2)'/>
                    </div>
                  ))}
                </SwiperWrapper>
                <FadeOutEffect/>
              </SwiperManual>
            ) : (
              <Swiper
                modules={[Autoplay]}
                id="partners-cards"
                spaceBetween={32}
                slidesPerView={"auto"}
                autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
                freeMode={true}
                speed={2000}
                slidesOffsetBefore={window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50)}
                slidesOffsetAfter={window.innerWidth > 1440 ? 0.1302*window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065)/2 : 50)}
                style={{ transform: 'translateX(100vw)', transition: 'all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88)' }}
              >
                {partnersInfo.map((partner, index) => (
                  <SwiperSlide key={index}>
                    <CasesCard cases={partner} gradient='linear-gradient(179.75deg, rgba(8, 3, 26, 0) -31.38%, #08031A 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid rgba(255, 255, 255, 0.2)' line='rgba(120, 107, 227, 0.2)'/>
                  </SwiperSlide>
                ))}
                <FadeOutEffect/>
              </Swiper>
            )
          }
        </div>
      </PartnersWrapper>
      <PartnersContent>
        {!isMobile && <LogoDino src="turbo-dino.png"/>}
        <Planet1 src='planet1.png'/>
        <Planet2 src='planet2.png'/>
        {!isMobile && <Planet3 src='planet3.png'/>}
        <PartnersTitle className="page-width">
          <span>É inevitável</span>
          Você também será atraído para o nosso ecossistema
        </PartnersTitle>
        <div style={{ position: 'relative' }}>
          <PartnersImage src={isMobile ? 'partners_image-mobile.png' : 'partners_image.png'}/>
          {isMobile && <LogoDino src="turbo-dino.png"/>}
        </div>
        <ContactButton width={216} height={45} link="https://lp.turbopartners.com.br/commerce/lp4-2" target="_blank" text='Entrar no foguete' icon='rocket' iconHeight={16.5} solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 76.86%, #EC894B 97.89%)'/>
      </PartnersContent>
      <CompaniesTitle className={isMobile && 'page-width'}>
        Algumas empresas que já entraram:
      </CompaniesTitle>
      <CompaniesWrapper>
        <GradientEffect/>
        <MarqueeWrapper left={true}>
          <MarqueeTrackLeft>
            <MarqueeContent>
              {companies[0].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                  <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[0].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[0].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
            </MarqueeContent>
          </MarqueeTrackLeft>
        </MarqueeWrapper>
        <MarqueeWrapper>
          <MarqueeTrackRight>
            <MarqueeContent>
              {companies[1].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[1].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[1].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
            </MarqueeContent>
          </MarqueeTrackRight>
        </MarqueeWrapper>
        <MarqueeWrapper left={true}>
          <MarqueeTrackLeft>
            <MarqueeContent>
              {companies[2].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[2].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
              {companies[2].map((company, index) => (
                <LogoWrapper href={company.url} key={index} target="_blank">
                <PartnersLogo src={isMobile ? company.mobile : company.desktop}/>
                </LogoWrapper>
              ))}
            </MarqueeContent>
          </MarqueeTrackLeft>
        </MarqueeWrapper>
        <GradientEffect style={{ right: 0, transform: 'rotate(180deg)', left: 'unset' }}/>
      </CompaniesWrapper>
    </div>
  );
};

export default Partners;

