import React from 'react';
import styled from 'styled-components';
import 'swiper/css/autoplay';

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const WinsWrapper = styled.div`
  padding: 100px 0 0 0;

  @media screen and (max-width: 768px) {
    padding: 45px 0 30px;
  }
`;

const WinsText = styled.div`
  width: 36.6%;

  @media screen and (max-width: 1440px) {
    width: 390px;
    max-width: 41.5%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;
  }
`;

const WinsCards = styled.ul`
  width: 48.6%;
  padding: 0;
  margin: 0;
  border-top: 1px solid #1F1C3C;
  border-bottom: 1px solid #1F1C3C;

  li:nth-child(odd) {
    padding: 30px 15px 30px 0;
  }

  li:nth-child(even) {
    padding: 30px 0 30px 15px;
  }

  li:nth-child(-n + 2) {
    border-bottom: 1px solid #1F1C3C;
  }

  @media screen and (max-width: 1440px) {
    width: 518px;
    max-width: calc(58.5% - 32px);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;

    li:nth-child(odd) {
      padding: 16px 3px 16px 0;
    }

    li:nth-child(even) {
      padding: 16px 0 16px 3px;
    }
  }
`;

const CommerceGanhos = ({ isMobile }) => {
  
  const wins = [
    {
      icon: '/commerce/notebook.svg',
      title: '+ Visitantes no Site',
      text: 'Mais cadastros, mais reservas e mais fãs fiéis'
    },
    {
      icon: '/commerce/cart.svg',
      title: '+ Vendas Online',
      text: 'Mais faturamento e mais lucro no seu bolso'
    },
    {
      icon: '/commerce/store.svg',
      title: '+ Visitas na Loja',
      text: 'Mais movimento no seu estabelecimento'
    },
    {
      icon: '/commerce/tel.svg',
      title: '+ Ligações',
      text: 'Mais pedidos e mais clientes interessados'
    }
  ];

  return (
    <PageWrapper className='flex column align-center'>
      <WinsWrapper className={`page-width flex ${isMobile ? 'column justify-center' : 'row justify-between'} align-center gap-32`}>
        <WinsText className={`flex column ${isMobile ? 'align-center text-center' : 'align-start text-left'} gap-16`}>
          <img src='/commerce/logo.svg' alt="Logo da Turbo Commerce"/>
          <Title font={isMobile ? 40 : 64}>
            O que você pode ganhar com a <GradientText background='linear-gradient(89.42deg, #4100C8 -11.47%, #C052F3 90.85%)'>Turbo Commerce</GradientText>
          </Title>
        </WinsText>
        <WinsCards className='flex wrap desktop-2 mobile-2'>
          {wins.map((item, idx) => (
            <li key={idx} className='list-item flex column align-center text-center gap-6'>
              <img src={item.icon} alt="" width={100} height={100}/>
              <Subtitle font={isMobile ? 18 : 20} style={{ opacity: 1, fontWeight: 700 }}>
                {item.title}
              </Subtitle>
              <Subtitle font={16} style={isMobile ? { width: '166px', maxWidth: '100%' } : { width: (idx < 2 ? '209px' : '160px'), maxWidth: 'calc(0.2925*(100vw - 100px) - 16px)' }}>
                {item.text}
              </Subtitle>
            </li>
          ))}
        </WinsCards>
      </WinsWrapper>
    </PageWrapper>
  );
};

export default CommerceGanhos;
