import React from 'react';
import styled from 'styled-components';
import Lottie from "lottie-react";
import animationData from '../assets/animation.json';
import 'swiper/css/autoplay';

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";

const GearWrapper = styled.div`
  padding: 60px 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 70px;
  position: relative;
  z-index: 2;

  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    cursor: default;
  }

  @media screen and (max-width: 1440px) {
    row-gap: 50px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 -16px;
    width: 100vw;
  }
`;
const SwiperManual = styled.div`
  width: 100%;
  overflow: auto;
  padding: 1px 0;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: max-content;
  padding: 0 16px;
  gap: 10px;
`;


const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const Gears = styled.div`
  background-image: url('/commerce/gears-background.png');
  background-size: 1920px 428px;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 85px 0 0 0 ;

  svg g :first-child path {
    fill: none;
  }

  svg g :not(:first-child) path[fill="rgb(255,255,255)"] {
    fill: #C052F3;
  }

  svg g :not(:first-child) path[fill="rgb(45,46,45)"] {
    fill: rgba(58,0,90,255);
  }

  @media screen and (max-width: 768px) {
    background-image: url('/commerce/gears-background_mobile.png');
    background-size: 768px 428px;
    padding: 29px 0 45px ;

    svg g :not(:first-child) path[fill="rgb(45,46,45)"] {
      fill: rgba(19,0,33,255);
    }
  }
`;

const GearsText = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const GearsCard = styled.li`
  position: relative;
  border-radius: 6px;
  padding: 20px;
  height: 550px;
  box-sizing: border-box;
  z-index: 1
  transform: translate3d(0px, 0px, 0px);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(11, 4, 27, 0.36);
    border: 1px solid #1F1C3C;
    backdrop-filter: blur(50px);
    border-radius: inherit;
    box-sizing: border-box;
    z-index: -1;
    transform: translate3d(0px, 0px, 0px);
  }

  @media screen and (max-width: 1440px) {
    padding: 15px;
  }

  @media screen and (max-width: 1024px) {
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: calc(100vw - 40px);
    max-width: 360px;
  }
`;

const GearsIcon = styled.img`
  width: 32px;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 24px;
  }

  @media screen and (max-width: 768px) {
    width: 32px;
  }
`;

const CommerceMotor = ({ isMobile }) => {
  
  const gears = [
    {
      gradientTitle: '1ª Marcha:',
      title: 'Arrancada',
      subtitle: 'Preparamos o terreno antes de maximizar o seu retorno sobre investimento em anúncios online.',
      texts: [
        'Estudaremos a fundo o seu modelo de negócios',
        'Identificaremos dores/desejos dos seus potenciais clientes',
        'Garantimos o acesso ao gerenciador de negócios, hospedagem, CRM, entre outros',
        'Montaremos uma estratégia de vendas personalizada para o seu business'
      ]
    },
    {
      gradientTitle: '2ª e 3ª Marchas:',
      title: 'Aceleração',
      subtitle: 'Aceleramos as vendas do seu negócio com o poder da internet.',
      texts: [
        'Criamos anúncios online de alta conversão e sob medida para a sua marca',
        'Testaremos e identificaremos quais são os seus melhores públicos',
        'Otimizamos constantemente as suas campanhas de tráfego pago',
        'Avaliaremos quais são os canais de vendas que te entregam os melhores resultados.'
      ]
    },
    {
      gradientTitle: '4ª e 5ª Marchas:',
      title: 'Eficiência',
      subtitle: 'Seu negócio atinge a ”velocidade de cruzeiro”. Aqui, ajustes finos fazem a sua receita decolar.',
      texts: [
        'Resgatamos o “dinheiro que está na mesa”: criamos automações de recuperação de vendas',
        'Intensificamos os testes A/B de páginas, criativos, headlines, etc',
        'Implementamos estratégias como Member Get Member, funis de e-books, entre outras',
        'Focamos em fazer seu LTV (a receita por cliente) crescer em altíssima velocidade'
      ]
    }
  ];
  
  return (
    <PageWrapper className='flex column align-center'>
      <Gears className="page-width flex column align-center">
        <GearsText className='flex column align-center text-center'>
          <Lottie animationData={animationData} loop={true} autoplay={true} style={{ width: '69px', height: '69px' }}/>
          <Title font={isMobile ? 28 : 40} style={{ margin: '13px 0 8px 0' }}>
            E como funciona o Motor "Turbo"?
          </Title>
        </GearsText>  
        <GearWrapper>
          {isMobile ? (
          <SwiperManual>
            <SwiperWrapper>
              {gears.map((item, index) => (
                <ul className='grid desktop-3 mobile-1' style={{ marginTop: isMobile ? '22px' : '44px' }}>
                  <GearsCard key={index} className='grid-item flex column text-left gap-16'>
                    <Title font={isMobile ? 28 : 40}>
                      <GradientText background='linear-gradient(89.03deg, #4100C8 -8.66%, #C052F3 26.32%)'>{item.gradientTitle}</GradientText>
                      <br/>
                      {item.title}
                    </Title>
                    <Subtitle font={isMobile ? 18 : 20} style={{ opacity: 1, marginTop: '16px' }}>
                      {item.subtitle}
                    </Subtitle>
                    {item.texts.map((text, idx) => (
                      <div key={idx} className='flex row align-center gap-16'>
                        <GearsIcon src="/commerce/verifica.svg"/>
                        <Subtitle font={16}>
                          {text}
                        </Subtitle>
                      </div>
                    ))}
                  </GearsCard>
                </ul>
              ))}
            </SwiperWrapper>
          </SwiperManual>
          ) : (
          <Swiper
            id="gears-cards"
            modules={[FreeMode]}
            spaceBetween={25}
            slidesPerView={"3"}
            freeMode={true}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            breakpoints={{
              0: {
                spaceBetween: 10
              },
              768: {
                spaceBetween: 25
              }
            }}
          >
            {gears.map((item, index) => (
              <SwiperSlide>
                <GearsCard key={index} className='grid-item flex column text-left gap-16'>
                  <Title font={isMobile ? 28 : 40}>
                    <GradientText background='linear-gradient(89.03deg, #4100C8 -8.66%, #C052F3 26.32%)'>{item.gradientTitle}</GradientText>
                    <br/>
                    {item.title}
                  </Title>
                  <Subtitle font={isMobile ? 18 : 20} style={{ opacity: 1, marginTop: '16px' }}>
                    {item.subtitle}
                  </Subtitle>
                  {item.texts.map((text, idx) => (
                    <div key={idx} className='flex row align-center gap-16'>
                      <GearsIcon src="/commerce/verifica.svg"/>
                      <Subtitle font={16}>
                        {text}
                      </Subtitle>
                    </div>
                  ))}
                </GearsCard>
              </SwiperSlide>
            ))}
          </Swiper>
          )}
        </GearWrapper> 
      </Gears>

    </PageWrapper>
  );
};

export default CommerceMotor;
