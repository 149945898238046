import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 329px;
  height: 448px;
  padding: 0 24px 53px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: start;
  border: ${props => props.border};
  border-radius: 10px;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 270px;
    height: 368px;
    padding: 0 20px 43px 20px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background-image: ${props => props.background};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    z-index: -1;
  }
`; 

const Logo = styled.img`
  height: auto;
  width: fit-content;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${props => props.line};
  margin: 12px 0;
`;

const Text = styled.h4`
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: -0.032em;
  text-align: left;  
  color: #EFEFEF;
  margin: 0;
  height: 87px;
  display: flex;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
    height: 72px;
  }
`;

const CasesCard = ({ cases, gradient, border, line }) => {

  return (
    <CardWrapper border={border} background={`${gradient}, url(${cases.img})`}>
      <Logo src={cases.logo}/>
      <Line line={line}/>
      <Text>{cases.content}</Text>
    </CardWrapper>
  );
};

export default CasesCard;

