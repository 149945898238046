import React from 'react';
import styled, {keyframes} from 'styled-components';
import TechMarquee from '../components/TechMarquee';
import CharSlide from '../components/CharSlide';
import ContactButton from '../components/ContactButton';

const movePen = keyframes`
  0%, 50%, 100% {
    transform: rotate(var(--angulo1));
    top: var(--top1);
    right: var(--right1);
  }
  25% {
    transform: rotate(var(--angulo2));
    top: var(--top2);
    right: var(--right2);
  }
  75%{
    transform: rotate(var(--angulo3));
    top: var(--top3);
    right: var(--right3);
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 13.75vw;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    padding-bottom: 198px;
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  font-family: 'Saira', sans-serif;
  font-size: 3.33vw;
  line-height: 3.59vw;
  font-weight: 400;
  margin: 60px 0 98px;
  letter-spacing: 0.015em;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 95%;
  
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 52px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 34px;
    gap: 18px;
    margin-bottom: 68px;
    max-width: 100%;
  }
`;

const GradientTitle = styled.span`
  background: linear-gradient(-90deg, #4AA6F7 -20%, #1141BD 140%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 0;
  white-space: nowrap;
`;

const Subtitle = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.015em;
  color: #DEDEDE;
  text-align: center;
  max-width: 47.34vw;
  
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 682px;
  }
`;

const CopywriterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6vw;

  @media screen and (max-width: 1440px) {
    margin-bottom: 87px;
  }

  @media screen and (max-width: 1165px) {
    flex-direction: column-reverse;
  }
`;

const CopywriterContent = styled.div`
  width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 1.875vw;

  @media screen and (max-width: 1440px) {
    gap: 27px;
    width: 576px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;
  }
`;

const CopywriterImage = styled.img`
  width: 35.21vw;
  height: auto;
  margin: 0 -20px 0 -14px;

  @media screen and (max-width: 1440px) {
    width: 507px;
  }

  @media screen and (max-width: 1165px) {
    width: 100%;
    max-width: 690px;
    margin: 0 0 34px 0;
  }
  
  @media screen and (max-width: 768px) {
    max-width: 452px;
  }
`;

const CopywriterPen = styled.img`
  width: 5.94vw;
  height: auto;
  position: absolute;
  --right1: 5.3vw;
  --top1: -2.7vw;
  --angulo1: 0deg;
  --right2: 16.61vw;
  --top2: 3.4vw;
  --angulo2: 5deg;
  --right3: 2.4vw;
  --top3: -8vw;
  --angulo3: -4deg;
  animation: ${movePen} 6s ease-in-out infinite;

  @media screen and (max-width: 1440px) {
    width: 86px;
    --right1: 76px;
    --top1: -39px;
    --right2: 239px;
    --top2: 49px;
    --right3: 35px;
    --top3: -115px;
  }

  @media screen and (max-width: 1165px) {
    width: 110px;
    --right1: 105px;
    --top1: -59px;
    --right2: 314px;
    --top2: 54px;
    --right3: 56px;
    --top3: -155px;
  }

  @media screen and (max-width: 768px) {
    width: 16.57%;
    --right1: 17%;
    --top1: -7%;
    --right2: 52%;
    --top2: 11%;
    --right3: 11%;
    --top3: -20.5%;
  }
`;

const CopywriterText = styled.p`
  max-width: 36vw;
  margin: 0;
  font-size: 1.25vw;
  line-height: 1.46vw;
  font-weight: 400;
  letter-spacing: 0.015em;
  color: #DEDEDE;
  text-align: left;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 21px;
    max-width: 520px;
  }
`;

const TechCharacter = ({ isMobile }) => {

  return (
    <>
      <TechMarquee isMobile={isMobile}/>
      <SectionWrapper className="page-width">
        <Title>
          <div>Selecione o <GradientTitle>personagem</GradientTitle> que melhor atende às suas <GradientTitle>necessidades</GradientTitle></div>
          <Subtitle>
            Independente de qual seja o personagem escolhido, é certo que a equipe de desenvolvedores da <b>Turbo Tech</b> conseguirá te atender.
          </Subtitle>
        </Title>
        <CharSlide/>
      </SectionWrapper>
      <CopywriterWrapper className="page-width">
        <CopywriterContent>
          <Title style={{ margin: 0, textAlign: 'left' }}>
            <span>Tenha um site escrito por <GradientTitle>Copywriters</GradientTitle> e <GradientTitle>UX Writers</GradientTitle></span>
          </Title>
          <CopywriterText>
            Sites simplesmente bonitos podem até <b>impactar</b>, mas não necessariamente <b>vender.</b>
            <br/><br/>
            Por esse motivo, a Turbo Tech também conta com uma equipe de profissionais especializados na escrita de <b>textos persuasivos.</b>
          </CopywriterText>    
          <ContactButton width={360} height={64} link='https://form.respondi.app/SOMTDxh7' target="_blank" text='Quero sites que vendem' fontSize={20} fontWeight={500} background='linear-gradient(263.91deg, #4AA6F7 -35.57%, #4AA6F7 -22.82%, #1141BD 92.97%)' innerBackground='#030917' shadow='0px 4px 30px rgba(0, 136, 255, 0.1)'/>
        </CopywriterContent>
        <div style={{ position: 'relative' }}>
          <CopywriterImage src={'/copywriter-image.png'}/>
          <CopywriterPen src={'/copywriter-pen.png'}/>
        </div>
      </CopywriterWrapper>
    </>
  );
};

export default TechCharacter;
