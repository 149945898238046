import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
  width: calc((100% - 60px)/3);
  max-width: auto;
  min-width: 288px;
  height: 15.31vw;
  max-height: auto;
  min-height: 220px;
  padding: 0 2.08vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: calc((100% - 44px)/2);
    height: unset;
    min-width: unset;
    min-height: unset;
    max-height: unset;
    padding: 0 25px 36px 25px;
  }

  @media screen and (max-width: 620px) {
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    height: 100%;
    z-index: -1;
    background: rgba(45, 35, 56, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17px);
    border-radius: 16px;
  }
`; 

const Icon = styled.img`
  position: absolute;
  top: -6.5vw;
  width: 20.83vw;
  height: 20.83vw;

  @media screen and (max-width: 1440px) {
    top: -93px;
    width: 300px;
    height: 300px;
  }

  @media screen and (max-width: 768px) {
    top: -120px;
    width: 400px;
    height: 400px;
  }
`;

const Title = styled.h4`
  font-size: 1.46vw;
  line-height: 1.77vw;
  font-weight: 700;
  letter-spacing: -0.0208em;
  text-align: center;  
  color: #FFF;
  margin: 7.5vw 0 16px 0;
  width: max-content;

  @media screen and (max-width: 1440px) {
    font-size: 21px;
    line-height: 26px;
    margin: 110px 0 16px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    margin: 150px 0 16px 0;
    width: fit-content;
  }
`; 

const Text = styled.p`
  font-size: 1.04vw;
  line-height: 1.27vw;
  font-weight: 400;
  letter-spacing: -0.0128em;
  text-align: center;  
  color: rgba(157, 149, 166, 1);
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 15px;
    line-height: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19px;
  }
`; 

const MarketingCard = ({ part }) => {

  return (
    <CardWrapper>
      <Icon src={part.icon}/>
      <Title>
        {part.title}
      </Title>
      <Text>
        {part.text}
      </Text>
    </CardWrapper>
  );
};

export default MarketingCard;

