import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import ContactButton from '../components/ContactButton';
import Lottie from "lottie-react";
import animationData from '../assets/animation.json';
import CasesCard from "../components/CasesCard";
import QeA from '../components/QeA';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import { createFormResult } from '../form';
import {ReactComponent as EngineImg} from '../assets/click-image.svg';
import {ReactComponent as Instagram} from '../assets/instagram.svg';
import {ReactComponent as Linkedin} from '../assets/linkedin.svg';
import {ReactComponent as Facebook} from '../assets/facebook.svg';
import {ReactComponent as Youtube} from '../assets/youtube.svg';

const swipe = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
`;

const pulse1 = keyframes`
  0% { transform: scale(1);}
  40% { transform: scale(1.4);}
  100% { transform: scale(1.4);}
`;

const pulse2 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  10% { transform: scale(1); opacity: 1; }
  20% { transform: scale(1.3); opacity: 0; }
  100% { transform: scale(1.3); opacity: 0; }
`;

const pulse3 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  18% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.25); opacity: 0; }
  100% { transform: scale(1.25); opacity: 0; }
`;

const pulse4 = keyframes`
  0% { transform: scale(1); opacity: 1; }
  28% { transform: scale(1); opacity: 1; }
  40% { transform: scale(1.2); opacity: 0; }
  100% { transform: scale(1.2); opacity: 0; }
`;

const pulse5 = keyframes`
  0% { transform: scale(1);}
  40% { transform: scale(1.2);}
  100% { transform: scale(1.2);}
`;

const arrow = keyframes`
	0% { transform: translateY(0px); }
  100%   { transform: translateY(26px); }
`;

const arrowColor = keyframes`
	0% { stop-color: var(--start-color) }
  100% { stop-color: var(--finish-color) }
`;

const marqueeAnimationLeft = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-33.33%); }
`;

const marqueeAnimationRight = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(33.33%); }
`;

const PageWrapper = styled.div`
  color: #F7F1FC;
  background: #0B041B;
  font-family: 'Oxanium', sans serif;
  letter-spacing: -0.0128em;
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 50px;
  z-index: 100;

  @media screen and (max-width: 768px) {
    top: 30px;
    max-width: 452px;
  }
`;

const Header = styled.div`
  border-radius: 4px;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 16px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(31, 28, 60, 0.0025);
    border: 1px solid #1F1C3C;
    backdrop-filter: blur(6px);
    border-radius: inherit;
    box-sizing: border-box;
    z-index: -1;
  }
`;

const HeroContainer = styled.div`
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  padding: 250px 0 160px;
  border-bottom: 1px solid #1F1C3C;

  @media screen and (max-width: 768px) {
    padding: 134px 0 60px;
  }
`; 

const ColumnWrapper = styled.div`
  text-align: left;
  width: 100%;
  max-width: ${props => props.index === 1 ? '35.9vw' : '29.7vw'};

  @media screen and (max-width: 1440px) {
    max-width: ${props => props.index === 1 ? '518px' : '428px'};
  }

  @media screen and (max-width: 768px) {
    max-width: 420px;

    form {
      margin-top: -24px;
    }
  }
`;

const Title = styled.h2`
  font-size: ${props => `calc(${props.font}*100vw/1920)`};
  line-height: 125%;
  font-weight: 500;
  letter-spacing: 0;
  width: 100%;
  max-width: ${props => props.width ? `calc(${props.width}*100vw/1920)` : 'unset'};
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: ${props => `calc(${props.font}px*0.75)`};
    max-width: ${props => props.width ? `calc(${props.width}px*0.75)` : 'unset'};
  }

  @media screen and (max-width: 768px) {
    font-size: ${props => `${props.font}px`};
    max-width: ${props => props.width ? `${props.width}px` : 'unset'};
  }
`;

const GradientText = styled.span`
  background: ${props => props.background};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Subtitle = styled.p`
  font-size: ${props => `${props.font}px`};
  line-height: 150%;
  font-weight: 600;
  font-family: 'Red Hat Display', sans serif;
  letter-spacing: -0.0128em;
  width: 100%;
  max-width: 100%;
  margin: 0;
  opacity: 0.8;
`;

const HeroImg = styled.img`
  height: auto;
  width: 100%;
  margin-top: -8px;

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: 452px;
    margin: -16px 0 0 -16px;
  }
`;

const FormControl = styled.div`
  border-radius: 4px;
  padding: 1px;
  background: linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%);

  input, select {
    align-items: center;
    padding: 16px 4.2%;
    height: 60px;
    background: #120C20;
    color: #F7F1FC;
    font-family: 'Oxanium', sans serif;
    font-size: 20px;
    line-height: 125%;
    border: none;
    border-radius: inherit;

    ::placeholder {
      color: #F7F1FC;
      font-family: 'Oxanium', sans serif;
      font-size: 20px;
      line-height: 125%;
    }

    :focus-visible {
      outline: #F7F1FC solid 1px;
    }
  }

  select {
    padding-right: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('/commerce/arrow-select.svg');
    background-repeat: no-repeat;
    background-position: 95.7% 50%;
  }

  .errorMsg {
    margin: 6px 0 6px 4.2%;
  }
`;

const FormButton = styled.button`
  cursor: pointer;
  border: unset;
  background: unset;
  padding: 0;
  border-radius: 4px;
`;

const SectionWrapper = styled.div`
  border-bottom: 1px solid #1F1C3C;
`;

const Why = styled.div`
  padding: 55px 0;
  
  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const ArrowWrapper = styled.div`
  display: flow-root;
  position: relative;
  padding: 2px;
  height: 60px;
  width: 38px;
  box-sizing: border-box;
  border-radius: 64px;
  margin-top: 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #4100C8 0%, #C052F3 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  svg {
    margin-top: 5px;
    animation: ${arrow} 1s ease-out infinite;
  }

  .main-stop {
    animation: ${arrowColor} 1s ease-in-out infinite;
    --start-color: rgba(65, 0, 200, 0);
    --finish-color: rgba(65, 0, 200, 1);
  }

  .alt-stop {
    animation: ${arrowColor} 1s ease-in-out infinite;
    --start-color: rgba(128, 41, 221, 0.5);
    --finish-color: rgba(192, 82, 243, 1);
  }
`;

const Sales = styled.div`
  padding: 100px 0;

  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const TextWrapper = styled.div`
  width: ${props => props.width};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const SalesImg = styled.img`
  width: 100%;
  height: auto;
  max-width: calc(65% - 32px);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const Gears = styled.div`
  background-image: url('/commerce/gears-background.png');
  background-size: 1920px 428px;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 85px 0 60px ;

  svg g :first-child path {
    fill: none;
  }

  svg g :not(:first-child) path[fill="rgb(255,255,255)"] {
    fill: #C052F3;
  }

  svg g :not(:first-child) path[fill="rgb(45,46,45)"] {
    fill: rgba(58,0,90,255);
  }

  @media screen and (max-width: 768px) {
    background-image: url('/commerce/gears-background_mobile.png');
    background-size: 768px 428px;
    padding: 29px 0 45px ;

    svg g :not(:first-child) path[fill="rgb(45,46,45)"] {
      fill: rgba(19,0,33,255);
    }
  }
`;

const GearsText = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const GearsCard = styled.li`
  position: relative;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(11, 4, 27, 0.36);
    border: 1px solid #1F1C3C;
    backdrop-filter: blur(50px);
    border-radius: inherit;
    box-sizing: border-box;
    z-index: -1;
  }

  @media screen and (max-width: 1440px) {
    padding: 15px;
  }

  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const GearsIcon = styled.img`
  width: 32px;
  height: auto;

  @media screen and (max-width: 1440px) {
    width: 24px;
  }

  @media screen and (max-width: 768px) {
    width: 32px;
  }
`;

const WinsWrapper = styled.div`
  padding: 60px 0 115px;

  @media screen and (max-width: 768px) {
    padding: 45px 0 30px;
  }
`;

const WinsText = styled.div`
  width: 36.6%;

  @media screen and (max-width: 1440px) {
    width: 390px;
    max-width: 41.5%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;
  }
`;

const WinsCards = styled.ul`
  width: 48.6%;
  padding: 0;
  margin: 0;
  border-top: 1px solid #1F1C3C;
  border-bottom: 1px solid #1F1C3C;

  li:nth-child(odd) {
    padding: 30px 15px 30px 0;
  }

  li:nth-child(even) {
    padding: 30px 0 30px 15px;
  }

  li:nth-child(-n + 2) {
    border-bottom: 1px solid #1F1C3C;
  }

  @media screen and (max-width: 1440px) {
    width: 518px;
    max-width: calc(58.5% - 32px);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 420px;

    li:nth-child(odd) {
      padding: 16px 3px 16px 0;
    }

    li:nth-child(even) {
      padding: 16px 0 16px 3px;
    }
  }
`;

const CasesWrapper = styled.div`
  padding-top: 66px;
  box-sizing: border-box;
  row-gap: 50px;
  overflow: hidden;
    
  .swiper {
    overflow: hidden;
    width: 100vw;
    cursor: grab;
    position: relative;
  }

  .swiper-wrapper {
    display: flex;
    transition-timing-function : linear;
  }

  @media screen and (max-width: 768px) {
    padding-top: 34px;
    row-gap: 32px;
  }
`;

const SwiperManual = styled.div`
  overflow: auto;
  padding: 1px 0;
  position: relative;

  ::-webkit-scrollbar { 
    display: none;
  }
`;

const SwiperWrapper = styled.div`
  width: max-content;
  padding: 0 16px;
  gap: 15px;
  animation: ${swipe} 32s linear infinite;
`;

const FadeOutEffect = styled.div`
  width: 13.02vw;
  height: 520px;
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 5;
  background: linear-gradient(270deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: calc((100vw - 1065px)/2);
    min-width: 50px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CompaniesWrapper = styled.div`
  gap: 74px;
  padding: 116px 0 66px;

  @media screen and (max-width: 768px) {
    padding: 60px 0 34px;
    gap: 61px;
    max-width: 420px;
  }
`;

const CompaniesContainer = styled.div`
  position: relative;
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 119px;
  overflow-x: hidden;
  background: rgba(13, 3, 26, 0.5);
  border-top: 1px solid #1F1C3C;
  border-bottom: 1px solid #1F1C3C;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.left ? 'start' : 'end')};
  z-index: 2;

  @media screen and (max-width: 768px) {
    height: 58px;
  }
`;

const MarqueeTrackLeft = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationLeft} 16s linear infinite;
`;

const MarqueeTrackRight = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimationRight} 16s linear infinite;
`;

const GradientEffect = styled.div`
  width: 27.3vw;
  height: 391px;
  position: absolute;
  top: -14px;
  left: ${props => props.right ? 'unset' : 0};
  right: ${props => props.right ? 0 : 'unset'};
  transform: ${props => props.right ? 'rotate(180deg)' : 'unset'};
  z-index: 5;
  background: linear-gradient(90deg, #08031A 0%, #08031A 55%, rgba(8, 3, 26, 0) 100%);

  @media screen and (max-width: 1440px) {
    width: max(calc(160px + (100vw - 1065px)/2), 210px);
  }

  @media screen and (max-width: 768px) {
    width: 100px;
    height: 180px;
    top: 0;
    background: linear-gradient(90deg, #08031A 0%, rgba(8, 3, 26, 0) 100%);
  }
`;

const LogoWrapper = styled.a`
  height: 65px;
  padding: 0 40px;
  border-right: 1px solid #1F1C3C;

  @media screen and (max-width: 768px) {
    padding: 0 26px;
    height: 39px;
  }
`;

const Transform3D = styled.div`
  position: absolute;
  top: ${props => props.item.top};
  right: ${props => props.item.right};
  left: ${props => props.item.left};
  bottom: ${props => props.item.bottom};
  width: ${props => props.item.width};
  height: ${props => props.item.height};
  z-index: ${props => props.index + 1};
  animation: ${pulse5} 2.5s ease-out infinite;
`

const ShapeImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  transform: ${props => props.item.iconPosition};
  width: ${props => props.item.iconWidth};
  height: auto;
  z-index: ${props => props.index + 1};
`;

const Shape3D = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 7.3px;
  transform: ${props => props.item.transform};
  background: rgba(217, 217, 217, 0.05);
  backdrop-filter: ${props => `blur(${props.item.blur})`};
  position: relative;
`;

const EngineWrapper = styled.div`
  padding: 96px 0 68px 0;

  .flex {
    padding: 80px 0 calc(80px + 5.5%);
  }

  .click-svg {
    z-index: 1;
    width: 100%;
    height: 100%;
    
    circle, path {
      animation: 2.5s ease-out infinite;
      transform-origin: center;
    }
  }

  .click-svg :nth-child(-n + 2) {
    animation-name: ${pulse1};
    animation-delay: -0.1s;
  }

  .click-svg :nth-child(3) {
    animation-name: ${pulse2};
  }

  .click-svg :nth-child(4) {
    animation-name: ${pulse3};
  }

  .click-svg :nth-child(5) {
    animation-name: ${pulse4};
  }

  .image-wrapper {
    position: relative;
    z-index: 0;
    margin: 0 -1.4% 0 -6.1%;
    width: 58.2%;
    height: 37.65%;
    perspective: 870px;
    perspective-origin: 51% 57%;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      width: 43.3%;
      height: 67%;
      background: linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%);
      filter: blur(100px);
      z-index: -1;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 60px 0;

    .flex {
      padding: 0;
    }

    .image-wrapper {
      width: 115%;
      margin: 0 -2.7% 0 -12.3%;

      &:before {
        width: 43.3%;
        height: 67%;
        filter: blur(50px);
      }
    }
  }
`;

const CancelWrapper = styled.div`
  padding: 81px 0; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 90px 0 43px; 
  }
`;

const CancelBanner = styled.div`
  gap: 56px; 
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #4100C8 0%, #C052F3 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
  }

  @media screen and (max-width: 1440px) {
    gap: 0px;
  }
`;

const CancelImg = styled.img`
  width: 32.6%;
  height: auto;
  align-self: end;
  margin-top: -5.9%;

  @media screen and (max-width: 1440px) {
    width: 335px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: -8%;
    padding: 0 10px;
    box-sizing: border-box;
    background: linear-gradient(90deg, #4100C8 0%, #C052F3 100%);
    background-size: 100% 2px;
    background-position-y: 100%;
    background-repeat: no-repeat;     
  }
`;

const FAQWrapper = styled.div`
  padding: 100px 0 190px; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
    padding: 43px 0 75px; 
  }
`;

const FAQContent = styled.div`
  width: 825px;
  max-width: calc(100% - 374px);
  box-sizing: border-box; 

  @media screen and (max-width: 768px) {
    max-width: 420px;
  }
`;

const FooterWrapper = styled.div`
  border-top: 1px solid #1F1C3C;
  padding: 60px 0 90px;
  gap: 40px;

  @media screen and (max-width: 768px) {
    border-top: unset;
    padding: 0 0 42px;
  } 
`;

const Social = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 69px;
  border-radius: 22px;
  background: #120C20;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    background: linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%);
    opacity: 0;
    transition: opacity 0.6s ease-in-out;
    z-index: -1;
  }

  svg stop {
    transition: all 0.6s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    stop {
      stop-color: #F7F1FC;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: calc(25% - 12px);
    max-height: calc(25% - 12px);
  }
`;

const Commerce1 = ({ isMobile }) => {
  
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    const cellphone = data.WhatsApp.replace(/\D/g,'');

    if(cellphone.length < 10) {
      setError('WhatsApp', { type: 'custom', message: 'Número de telefone inválido' }, { shouldFocus: true });
    } else if(data.Segmento === "") {
      setError('Segmento', { type: 'custom', message: 'Selecione uma opção' }, { shouldFocus: true });
    } else if(data.Funcionários === "") {
      setError('Funcionários', { type: 'custom', message: 'Selecione uma opção' }, { shouldFocus: true });
    } else if(data.Faturamento === "") {
      setError('Faturamento', { type: 'custom', message: 'Selecione uma opção' }, { shouldFocus: true });
    } else {
      await createFormResult(data);
      window.location.href="/obrigado-empresas";
    }
  };

  const segments = [ 'Serviço', 'Varejo', 'Indústria', 'E-commerce', 'SAAS', 'Educação', 'Outro' ];

  const employees = [ '00 - 01', '02 - 10', '11 - 20', '21 - 50', '51 - 100', '+100' ];

  const revenue = [ 'até 20 mil', 'De 21 mil a 50 mil', 'De 51 mil a 70 mil', 'De 71 mil a 100 mil', 'De 101 mil a 400 mil', 'De 401 mil a 1 milhão', 'Mais de 1 milhão' ];

  const texts = [
    {
      icon: '/commerce/phone.svg',
      text: 'Têm uma poderosa máquina de vendas que trabalha para elas.'
    },
    {
      icon: '/commerce/web.svg',
      text: 'Descobriram como usar a internet para vender para todo o Brasil e mundo inteiro.'
    },
    {
      icon: '/commerce/coin.svg',
      text: 'Entenderam que um bom produto não basta. É preciso saber COMO ofertá-lo.'
    }
  ];

  const gears = [
    {
      gradientTitle: '1ª Marcha:',
      title: 'Arrancada',
      subtitle: 'Preparamos o terreno antes de maximizar o seu retorno sobre investimento em anúncios online.',
      texts: [
        'Estudaremos a fundo o seu modelo de negócios',
        'Identificaremos dores/desejos dos seus potenciais clientes',
        'Garantimos o acesso ao gerenciador de negócios, hospedagem, CRM, entre outros',
        'Montaremos uma estratégia de vendas personalizada para o seu business'
      ]
    },
    {
      gradientTitle: '2ª e 3ª Marchas:',
      title: 'Aceleração',
      subtitle: 'Aceleramos as vendas do seu negócio com o poder da internet.',
      texts: [
        'Criamos anúncios online de alta conversão e sob medida para a sua marca',
        'Testaremos e identificaremos quais são os seus melhores públicos',
        'Otimizamos constantemente as suas campanhas de tráfego pago',
        'Avaliaremos quais são os canais de vendas que te entregam os melhores resultados.'
      ]
    },
    {
      gradientTitle: '4ª e 5ª Marchas:',
      title: 'Eficiência',
      subtitle: 'Seu negócio atinge a ”velocidade de cruzeiro”. Aqui, ajustes finos fazem a sua receita decolar.',
      texts: [
        'Resgatamos o “dinheiro que está na mesa”: criamos automações de recuperação de vendas',
        'Intensificamos os testes A/B de páginas, criativos, headlines, etc',
        'Implementamos estratégias como Member Get Member, funis de e-books, entre outras',
        'Focamos em fazer seu LTV (a receita por cliente) crescer em altíssima velocidade'
      ]
    }
  ];

  const wins = [
    {
      icon: '/commerce/notebook.svg',
      title: '+ Visitantes no Site',
      text: 'Mais cadastros, mais reservas e mais fãs fiéis'
    },
    {
      icon: '/commerce/cart.svg',
      title: '+ Vendas Online',
      text: 'Mais faturamento e mais lucro no seu bolso'
    },
    {
      icon: '/commerce/store.svg',
      title: '+ Visitas na Loja',
      text: 'Mais movimento no seu estabelecimento'
    },
    {
      icon: '/commerce/tel.svg',
      title: '+ Ligações',
      text: 'Mais pedidos e mais clientes interessados'
    }
  ];

  const cases = [
    {
      img: '/get-power_card-image.png',
      logo: '/logo_get-power.png',
      content: '0 a 5 milhões em vendas mensais'
    },
    {
      img: '/academia-do-sol_card-image.png',
      logo: '/logo_academia-do-sol.png',
      content: '100 mil em vendas em apenas 1 dia'
    },
    {
      img: '/brota_card-image.png',
      logo: '/logo_brota.png',
      content: '+200k de receita na black friday'
    },
    {
      img: '/carla-lorenzon_card-image.png',
      logo: '/logo_carla-lorenzon.png',
      content: 'De 3.8k seguidores para 78k em um mês e meio'
    },
    {
      img: '/luma_card-image.png',
      logo: '/logo_luma.png',
      content: 'Redução de 60% no CAC com triplo de investimento mensal'
    },
    {
      img: '/byr_card-image.png',
      logo: '/logo_byr.png',
      content: 'Aumento de 900% do faturamento'
    },
    {
      img: '/nina-saude_card-image.png',
      logo: '/logo_nina-saúde.png',
      content: 'Aumento do faturamento em 158% em um semestre'
    }
  ];

  const companies = [
    [
      {
        desktop: '/logo_brota.png',
        mobile: '/logo-mobile_brota.png',
        url: 'https://brotasubitem.com.br/'
      },
      {
        desktop: '/logo_soul-zen.png',
        mobile: '/logo-mobile_soul-zen.png',
        url: 'https://soulzenshop.com/'
      },
      {
        desktop: '/logo_martha-campos.png',
        mobile: '/logo-mobile_martha-campos.png',
        url: 'https://shopmarthacampos.com.br/'
      },
      {
        desktop: '/logo_vividus.png',
        mobile: '/logo-mobile_vividus.png',
        url: 'https://www.vividus.com.br/'
      },
      {
        desktop: '/logo_nutsco.png',
        mobile: '/logo-mobile_nutsco.png',
        url: 'https://nutsco.com/'
      },
      {
        desktop: '/logo_grupo-v3.png',
        mobile: '/logo-mobile_grupo-v3.png',
        url: 'https://v3grupo.com.br/'
      }
    ],
    [
      {
        desktop: '/logo_luma.png',
        mobile: '/logo-mobile_luma.png',
        url: 'https://lumaensino.com.br/'
      },
      {
        desktop: '/logo_byr.png',
        mobile: '/logo-mobile_byr.png',
        url: 'https://lojabyr.com.br/'
      },
      {
        desktop: '/logo_it-style.png',
        mobile: '/logo-mobile_it-style.png',
        url: 'https://itstyle.store/'
      },
      {
        desktop: '/logo_ninasaúde.png',
        mobile: '/logo-mobile_ninasaúde.png',
        url: 'https://ninasaude.com.br/'
      },
      {
        desktop: '/logo_get-power.png',
        mobile: '/logo-mobile_get-power.png',
        url: 'https://getpowersolar.com.br/'
      },
      {
        desktop: '/logo_cale.png',
        mobile: '/logo-mobile_cale.png',
        url: 'https://ecale.com.br/'
      },
      {
        desktop: '/logo_peppy-berry.png',
        mobile: '/logo-mobile_peppy-berry.png',
        url: 'https://peppyberry.com.br/'
      }
    ],
    [
      {
        desktop: '/logo_gula-fitfood.png',
        mobile: '/logo-mobile_gula-fitfood.png',
        url: 'https://gulafit.com.br/'
      },
      {
        desktop: '/logo_bready.png',
        mobile: '/logo-mobile_bready.png',
        url: 'https://meubready.com.br/'
      },
      {
        desktop: '/logo_total-health.png',
        mobile: '/logo-mobile_total-health.png',
        url: '/'
      },
      {
        desktop: '/logo_tucan.png',
        mobile: '/logo-mobile_tucan.png',
        url: 'https://tucan.coffee/'
      },
      {
        desktop: '/logo_divn.png',
        mobile: '/logo-mobile_divn.png',
        url: 'https://divn.com.br/'
      },
      {
        desktop: '/logo_cooltiva.png',
        mobile: '/logo-mobile_cooltiva.png',
        url: 'https://cooltiva.com.br/'
      },
      {
        desktop: '/logo_gkbrand.png',
        mobile: '/logo-mobile_gkbrand.png',
        url: 'https://gkbrand.com.br/'
      }
    ]
  ];

  const FAQInfo = [
    {
      question: 'Vocês garantem resultado?',
      answer: 'Só faríamos isso se fôssemos desonestos. Na verdade, nós garantimos a você um serviço de alta qualidade. O resultado é consequência. Essa é a mesma resposta que nós demos para empreendedores parceiros em que nós ajudamos a escalar em mais de 10x o faturamento.'  
    },
    {
      question: 'Quanto custa?',
      answer: 'O investimento no serviço depende do escopo do projeto. Os valores se iniciam em R$1500. Você pode receber uma proposta personalizada para o seu negócio preenchendo o formulário agora.'  
    },
    {
      question: 'Quais serviços vocês prestam?',
      answer: 'O nosso carro chefe é a aceleração de negócios a partir dos anúncios online. Nós temos um time completo de especialistas no digital, como copywriters, UI/UX Designers, Designers Gráficos , Gestores de Performance e Desenvolvedores, prontos para escalar suas vendas a partir da internet. Nesse sentido, nós confeccionamos para você criativos sob medida, otimizamos suas campanhas, trabalhamos estratégias de remarketing e também construímos sites do zero.'  
    },
    {
      question: 'Posso cancelar a qualquer momento?',
      answer: 'Sim. Sem letras miúdas. Você não paga nenhuma multa ao cancelar.'  
    },
    {
      question: 'Quanto devo investir em tráfego?',
      answer: 'Nós recomendamos aos nossos clientes um investimento mínimo de R$35 por dia. Este valor é mais do que suficiente para os testes iniciais. À medida que você for obtendo seus primeiros resultados, você pode aumentar o seu investimento mensal.'  
    },
    {
      question: 'Vocês fazem site?',
      answer: 'Sim. Temos um time de UX Writing, UI Design e Desenvolvimento que está pronto para turbinar seu negócio com  sites e Landing Pages de alta conversão.'  
    }
  ];

  const cardsSVG = [
    {
      width: '20.2%',
      height: '32.9%',
      top: '31%',
      right: '8.1%',
      icon: '/commerce/card-icon-1.svg',
      iconWidth: '163%',
      iconPosition: 'translate(-19.3%,-18%)',
      blur: '9px',
      transform: 'rotateX(0deg) rotateY(357deg) rotateZ(34deg) skewX(10deg) skewY(6deg)'
    },
    { 
      width: '29.5%',
      height: '44.2%',
      top: '9.4%',
      right: '13.8%',
      icon: '/commerce/card-icon-2.svg',
      iconWidth: '105.8%',
      iconPosition: 'translate(-3.2%,-13.7%)',
      blur: '13px',
      transform: 'rotateX(347deg) rotateY(3deg) rotateZ(21deg) skewX(26deg) skewY(-1deg)'
    },
    {
      width: '16.5%',
      height: '26.7%',
      bottom: '26.7%',
      left: '20.8%',
      icon: '/commerce/card-icon-3.svg',
      iconWidth: '135%',
      iconPosition: 'translate(-13%,-14%)',
      blur: '8px',
      transform: 'rotateX(-8deg) rotateY(-1deg) rotateZ(10deg) skewX(5deg) skewY(11deg)'
    },
    {
      width: '19.5%',
      height: '32.7%',
      bottom: '12.7%',
      left: '21.8%',
      icon: '/commerce/card-icon-4.svg',
      iconWidth: '158%',
      iconPosition: 'translate(-18%,-16%)',
      blur: '9px',
      transform: 'rotateX(0deg) rotateY(360deg) rotateZ(329deg) skewX(363deg) skewY(13deg)'
    }
  ];

  const [initAutoplay, setInitAutoplay] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);

  function countTo() {
    let from = 0;
    let to = 70;
    let step = 1;
    let interval = 2000/70;

    if(from === to) {
      document.getElementById('counter').textContent = from;
      return;
    }

    let counter = setInterval(function(){
      from += step;
      document.getElementById('counter').textContent = from;

      if(from === to) {
        clearInterval(counter)
      }
    }, interval);
  }

  function handleEffects() {
    // Efeito aparecer carrossel
    const casesSectionTop = document.getElementById('cases-section').getBoundingClientRect().top;

    if (casesSectionTop <= 0.8 * window.innerHeight) {
      setInitAutoplay(true);
      if (!isMobile && document.getElementById('cases-cards')) {
        document.getElementById('cases-cards').style.transform = 'translateX(0)';
      }
    };

    const counter = document.getElementById('counter');
    const counterTop = counter.getBoundingClientRect().top;
    if (counterTop < 0.9*window.innerHeight && counter.textContent === ' ' ) {
      countTo();
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleEffects);

    return () => {
      window.removeEventListener('scroll', handleEffects);
    }
  });

  return (
    <PageWrapper className='flex column align-center'>
      <HeaderWrapper className='page-width'>
        <Header className='flex row justify-between'>
          <div className='flex row align-center' style={{ gap: '9px' }}>
            <img src='/commerce/logo.svg' alt="Logo da Turbo Commerce" width={isMobile ? '27px' : '32px'} height={isMobile ? '27px' : '32px'}/>
            <Title font={isMobile ? 14 : 22} style={{ fontWeight: 700 }}>Turbo <GradientText background='linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)'>Commerce</GradientText></Title>
          </div>
          <ContactButton width={isMobile ? 139 : 155} height={isMobile ? 44 : 50} link='#form' solidButton={true} text="Turbinar agora" font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)" shadow="unset" borderRadius={4}/>
        </Header>
      </HeaderWrapper>
      <HeroContainer id="form" className="page-width flex column align-center">  
        <HeroWrapper className={`full-width flex ${isMobile ? 'column align-center' : 'row gap-32'} justify-between`}>  
          <ColumnWrapper index={1} className="flex column gap-16">
            <Title font={isMobile ? 28 : 54}>
              Acelere o crescimento das <GradientText background='linear-gradient(85.09deg, #C052F3 4.59%, #4100C8 100.44%)'>vendas do seu negócio</GradientText> com o “motor” Turbo
            </Title>
            <Subtitle font={isMobile ? 16 : 20}>
              Preencha o formulário para ter um time completo de especialistas em marketing digital turbinando a sua empresa.
            </Subtitle>
            <HeroImg src={'/commerce/graphic_commerce-hero.png'}/>
          </ColumnWrapper>
          <ColumnWrapper index={2}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex column gap-16 full-width">
            <FormControl>
              <input required type="text" name="Nome" placeholder="Qual é o seu nome?"
                {...register("Nome")}
              />
            </FormControl>
            <FormControl>
              <input required type="text" name="Email" placeholder="E o seu E-mail?"
                {...register("Email", {
                  pattern: {
                    value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                    message: "Email não é válido."
                  }
                })}
              />
              {errors.Email && <p className="errorMsg">{errors.Email.message}</p>}
            </FormControl>
            <FormControl>
              <Controller
                control={control}
                name="WhatsApp"
                render={({ field: { onChange, onBlur, ref } }) => (
                  <InputMask
                    mask="(99) 99999-9999"
                    onBlur={onBlur}
                    onChange={onChange}
                    inputRef={ref}
                    placeholder="Qual é o seu WhatsApp?"
                    type="tel"
                    required
                    {...register("WhatsApp")}
                  />
                )}
              />
              {errors.WhatsApp && <p className="errorMsg">{errors.WhatsApp.message}</p>}
            </FormControl>
            <FormControl>
              <input required type="text" name="Empresa" placeholder="Qual é o nome da sua empresa?" 
                {...register("Empresa")}
              />
            </FormControl>
            <FormControl className="selector-wrapper">
              <select name="Segmento" defaultValue=""
                {...register("Segmento")}
              >
                <option value="" disabled>Qual o seu segmento?</option>
                {segments.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
              {errors.Segmento && <p className="errorMsg">{errors.Segmento.message}</p>}
            </FormControl>
            <FormControl className="selector-wrapper">
              <select name="Funcionários" defaultValue=""
                {...register("Funcionários")}
              >
                <option value="" disabled>Quantos funcionários você tem hoje?</option>
                {employees.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
              {errors.Funcionários && <p className="errorMsg">{errors.Funcionários.message}</p>}
            </FormControl>
            <FormControl className="selector-wrapper">
              <select name="Faturamento" defaultValue=""
                {...register("Faturamento")}
              >
                <option value="" disabled>Qual é o faturamento mensal da sua empresa?</option>
                {revenue.map((item, index) => (
                  <option value={item} key={index}>
                    {item}
                  </option>
                ))}
              </select>
              {errors.Faturamento && <p className="errorMsg">{errors.Faturamento.message}</p>}
            </FormControl>
            <input type="hidden" name="Página Forms" value={window.location.pathname.slice(1)} {...register("Página Forms")}/>
            <div>
              <FormButton className="full-width" type="submit">
                <ContactButton icon="arrow" height={60} solidButton={true} text="Receber contato da Turbo" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ width: '100%', textDecoration: 'none' }}/>
              </FormButton>
            </div>
          </form>
          </ColumnWrapper>
        </HeroWrapper>
      </HeroContainer>
      <div className='page-width'>
      	<SectionWrapper className='flex justify-center full-width'>
          <Why className="flex column align-center gap-16">
            <Title font={isMobile ? 28 : 40} style={{ maxWidth: isMobile ? '100%' : '82.5%' }}>
              Sabe por que existem tantas <GradientText background='linear-gradient(89.04deg, #4100C8 37%, #C052F3 125%)'>empresas novas</GradientText>, do MESMO segmento que o seu, crescendo numa <GradientText background='linear-gradient(89.08deg, #4100C8 10%, #C052F3 94%)'>velocidade assustadora</GradientText>?
            </Title>
            <Subtitle font={isMobile ? 16 : 20}>
              E digo mais: empresas com produtos ou serviços até inferiores aos seus...
            </Subtitle>
            <ArrowWrapper>
              <svg width="15" height="22">
                <defs>
                  <linearGradient id="gradient" x1="50%" y1="0%" x2="50%" y2="100%" gradientTransform="rotate(-45)">
                    <stop className="main-stop" offset="0" stopColor="rgba(65, 0, 200, 0)"/>
                    <stop className="alt-stop" offset="1" stopColor="rgba(128, 41, 221, 0.5)"/>
                  </linearGradient>
                </defs>
                <path d="M8.62061 1C8.62061 0.447715 8.17289 2.41411e-08 7.62061 0C7.06832 -2.41411e-08 6.62061 0.447715 6.62061 1L8.62061 1ZM6.9135 21.3623C7.30402 21.7528 7.93719 21.7528 8.32771 21.3623L14.6917 14.9983C15.0822 14.6078 15.0822 13.9746 14.6917 13.5841C14.3011 13.1936 13.668 13.1936 13.2775 13.5841L7.6206 19.241L1.96375 13.5841C1.57323 13.1936 0.940062 13.1936 0.549537 13.5841C0.159013 13.9746 0.159013 14.6078 0.549537 14.9983L6.9135 21.3623ZM6.62061 1L6.6206 20.6552L8.6206 20.6552L8.62061 1L6.62061 1Z" fill="url(#gradient)"/>
              </svg>
            </ArrowWrapper>
          </Why>
        </SectionWrapper>
      </div>
      <div className='page-width'>
        <SectionWrapper className='flex justify-center full-width'>
        <Sales className='flex row gap-32 align-center justify-between wrap-reverse'>
          <TextWrapper width='35%' className='flex column gap-16 align-start text-left'>
            <Title font={isMobile ? 28 : 40}>
              <GradientText background='linear-gradient(89.03deg, #4100C8 0%, #C052F3 100%)'>Simples</GradientText>. É porque elas...
            </Title>
            {texts.map((item, index) => (
              <Subtitle key={index} font={16} className='flex row align-center gap-16'>
                <img src={item.icon} alt=""/>
                <span>{item.text}</span>
                </Subtitle>
              ))}
              <ContactButton icon="arrow" width={361} height={60} link='#form' solidButton={true} text="Quero acelerar minhas vendas" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '16px', width: isMobile && '100%' }}/>
            </TextWrapper>
            <SalesImg src='/commerce/sales-machine.png'/>
          </Sales>
        </SectionWrapper>
      </div>
      <Gears className="page-width flex column align-center">
        <GearsText className='flex column align-center text-center'>
          <Lottie animationData={animationData} loop={true} autoplay={true} style={{ width: '69px', height: '69px' }}/>
          <Title font={isMobile ? 28 : 40} style={{ margin: '13px 0 8px 0' }}>
            E como funciona essa máquina de vendas?
          </Title>
          <Subtitle font={isMobile ? 16 : 20}>
            Como funciona a metodologia do ”motor” Turbo?
          </Subtitle>
        </GearsText>
        <ul className='grid desktop-3 mobile-1' style={{ marginTop: isMobile ? '22px' : '44px' }}>
          {gears.map((item, index) => (
            <GearsCard key={index} className='grid-item flex column text-left gap-16'>
              <Title font={isMobile ? 28 : 40}>
                <GradientText background='linear-gradient(89.03deg, #4100C8 -8.66%, #C052F3 26.32%)'>{item.gradientTitle}</GradientText>
                <br/>
                {item.title}
              </Title>
              <Subtitle font={isMobile ? 18 : 20} style={{ opacity: 1, marginTop: '16px' }}>
                {item.subtitle}
              </Subtitle>
              {item.texts.map((text, idx) => (
                <div key={idx} className='flex row align-center gap-16'>
                  <GearsIcon src="/commerce/verifica.svg"/>
                  <Subtitle font={16}>
                    {text}
                  </Subtitle>
                </div>
              ))}
            </GearsCard>
          ))}
        </ul>
      </Gears>
      <WinsWrapper className={`page-width flex ${isMobile ? 'column justify-center' : 'row justify-between'} align-center gap-32`}>
        <WinsText className={`flex column ${isMobile ? 'align-center text-center' : 'align-start text-left'} gap-16`}>
          <img src='/commerce/logo.svg' alt="Logo da Turbo Commerce"/>
          <Title font={isMobile ? 40 : 64}>
            O que você pode ganhar com a <GradientText background='linear-gradient(89.42deg, #4100C8 -11.47%, #C052F3 90.85%)'>Turbo Commerce</GradientText>
          </Title>
        </WinsText>
        <WinsCards className='flex wrap desktop-2 mobile-2'>
          {wins.map((item, idx) => (
            <li key={idx} className='list-item flex column align-center text-center gap-6'>
              <img src={item.icon} alt="" width={100} height={100}/>
              <Subtitle font={isMobile ? 18 : 20} style={{ opacity: 1, fontWeight: 700 }}>
                {item.title}
              </Subtitle>
              <Subtitle font={16} style={isMobile ? { width: '166px', maxWidth: '100%' } : { width: (idx < 2 ? '209px' : '160px'), maxWidth: 'calc(0.2925*(100vw - 100px) - 16px)' }}>
                {item.text}
              </Subtitle>
            </li>
          ))}
        </WinsCards>
      </WinsWrapper>
      <CompaniesWrapper className='flex column align-center'>
        <Title font={isMobile ? 40 : 64} width={isMobile ? null : 846}>
          + de <GradientText background='linear-gradient(90.15deg, #4100C8 18.1%, #C052F3 72.2%)'>R$<span id='counter'> </span> milhões</GradientText> em vendas para nossos clientes 
        </Title>
        <CompaniesContainer>
          <GradientEffect/>
          {companies.map((item, index) => (
            <MarqueeWrapper left={index !== 1} key={index}>
              {index === 1 ? 
                <MarqueeTrackRight>
                  <div className='flex row align-center'>
                    {[...Array(3)].map((e, i) => (
                      <div key={i} className='flex row align-center'>
                        {item.map((subitem, idx) => (
                          <LogoWrapper className='flex align-center' href={subitem.url} key={idx} target="_blank">
                            <img src={isMobile ? subitem.mobile : subitem.desktop} alt=""/>
                          </LogoWrapper>
                        ))}
                      </div>
                    ))}
                  </div>
                </MarqueeTrackRight>  
              :
                <MarqueeTrackLeft>
                  <div className='flex row align-center'>
                    {[...Array(3)].map((e, i) => (
                      <div key={i} className='flex row align-center'>
                        {item.map((subitem, idx) => (
                          <LogoWrapper className='flex align-center' href={subitem.url} key={idx} target="_blank">
                            <img src={isMobile ? subitem.mobile : subitem.desktop} alt=""/>
                          </LogoWrapper>
                        ))}
                      </div>
                    ))}
                  </div>
                </MarqueeTrackLeft>    
              }
            </MarqueeWrapper>
          ))}
          <GradientEffect right={true}/>
        </CompaniesContainer>
      </CompaniesWrapper>
      <CasesWrapper id="cases-section" className='flex column full-width'>
        <Title font={isMobile ? 28 : 40}>
          Cases de Sucesso
        </Title>
        <div style={{ height: isMobile ? '370px' : '450px' }}>
          {initAutoplay &&
            (isMobile ? (
            <SwiperManual className='full-width'>
              <SwiperWrapper className='flex nowrap' onTouchStart={() => setClicked(true)} style={clicked ? { animationPlayState: 'paused', animationName: 'none', animationFillMode: 'none' } : {}}>
                {cases.map((item, index) => (
                  <div key={index}>
                    <CasesCard cases={item} gradient='linear-gradient(179.75deg, rgba(11, 4, 27, 0) -31.38%, #0B041B 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid #1F1C3C' line='#1F1C3C'/>
                  </div>
                ))}
              </SwiperWrapper>
              <FadeOutEffect />
            </SwiperManual>
          ) : (
            <Swiper
              modules={[Autoplay]}
              id="cases-cards"
              spaceBetween={32}
              slidesPerView={"auto"}
              autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
              freeMode={true}
              speed={2000}
              slidesOffsetBefore={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              slidesOffsetAfter={window.innerWidth > 1440 ? 0.1302 * window.innerWidth : (window.innerWidth > 1165 ? (window.innerWidth - 1065) / 2 : 50)}
              style={{ transform: 'translateX(100vw)', transition: 'all 2s cubic-bezier(0.8, 0.28, 0.29, 0.88)' }}
            >
              {cases.map((item, index) => (
                <SwiperSlide key={index}>
                  <CasesCard cases={item} gradient='linear-gradient(179.75deg, rgba(11, 4, 27, 0) -31.38%, #0B041B 65.31%), linear-gradient(179.09deg, rgba(8, 3, 26, 0) -0.19%, #08031A 58.4%)' border='1px solid #1F1C3C' line='#1F1C3C'/>
                </SwiperSlide>
              ))}
              <FadeOutEffect />
            </Swiper>
          ))
          }
        </div>
      </CasesWrapper>
      <EngineWrapper className='page-width flex row gap-32 align-center justify-between wrap-reverse'>
        <TextWrapper width='44.4%' className='flex column gap-16 text-left'>
          <Title font={isMobile ? 28 : 40}>
            Faça da internet o principal <GradientText background='linear-gradient(90.8deg, #4100C8 -16.08%, #C052F3 85.96%)'>motor de crescimento</GradientText> do seu negócio
          </Title>
          <Subtitle font={isMobile ? 16 : 20}>
            Leve sua marca para onde está a atenção das pessoas: a ”rua” mais movimentada do mundo.
          </Subtitle>
          <ContactButton icon="arrow" width={321} height={60} link='#form' solidButton={true} text="Turbine minhas vendas" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '16px', width: isMobile && '100%' }}/>
        </TextWrapper>
        <div className='image-wrapper'>
          <EngineImg className='click-svg'/>
          {cardsSVG.map((item, index) => (
            <Transform3D item={item} index={index}>
              <ShapeImg src={item.icon} item={item} index={index}/>    
              <Shape3D item={item}/>
            </Transform3D>
          ))}
        </div>
      </EngineWrapper>
      <CancelWrapper className='page-width'>
        <CancelBanner className='flex row wrap align-center justify-center'>
          <CancelImg src='/commerce/cancel-image.png'/>
          <TextWrapper width='637px' className='flex column text-center align-center' style={{ padding: isMobile ? '20px 27px 27px' : '20px 20px 30px 0', maxWidth: isMobile ? '' : 'calc(100% - 355px)' }}>
            <Title font={isMobile ? 28 : 40}>
              Cancele quando quiser
            </Title>
            <Subtitle font={isMobile ? 16 : 20}>
              Se, por qualquer motivo, você não estiver 100% satisfeito com o nosso serviço, basta cancelar o contrato sem pagar nenhum centavo de multa.
            </Subtitle>
            <ContactButton icon="arrow" width={338} height={60} link='#form' solidButton={true} text="Começar agora sem risco" fontsize={18} font="'Oxanium', sans serif" fontWeight={600} textColor="#F7F1FC" background="linear-gradient(88.92deg, rgba(65, 0, 200, 1) -15.8%, rgba(192, 82, 243, 1) 125.72%)" hoverBackground="linear-gradient(88.92deg, rgba(65, 0, 200, 0.7) -15.8%, rgba(192, 82, 243, 0.7) 125.72%)" shadow="unset" borderRadius={4} innerStyle={{ textDecoration: 'none', marginTop: '32px', width: isMobile && '100%' }}/>
          </TextWrapper>
        </CancelBanner>
      </CancelWrapper>
      <FAQWrapper className='page-width flex row justify-between wrap gap-32'>
        <TextWrapper width='27.6%' className={`flex column gap-16 ${isMobile ? 'text-center' : 'text-left'}`} style={{ minWidth: 'min-content' }}>
          <Subtitle font={isMobile ? 28 : 32}>
            <GradientText background='linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)'>F A Q</GradientText>
          </Subtitle>
          <Title font={isMobile ? 40 : 64} style={{ borderTop: '1px solid #1F1C3C', paddingTop: '16px' }}>
            Dúvidas Frequentes
          </Title>
        </TextWrapper>
        <FAQContent className='flex column'>
          {FAQInfo.map((item, index) => (
            <QeA qea={item} separatorColor='#1F1C3C' key={index}/>
          ))}
        </FAQContent>
      </FAQWrapper>
      <div className='page-width'>
        <FooterWrapper className={`flex ${isMobile ? 'column' : 'row'} align-center justify-between`}>
          <div className={`flex column gap-16 ${isMobile ? 'text-center' : 'text-left'}`}>
            <div className={`flex row align-center ${isMobile ? 'justify-center' : ''}`} style={{ gap: '12px' }}>
              <img src='/commerce/logo.svg' alt="Logo da Turbo Commerce" width='41px' height='41px'/>
              <Title font={28} style={{ fontWeight: 700, width: 'fit-content' }}>Turbo <GradientText background='linear-gradient(88.92deg, #4100C8 -15.8%, #C052F3 125.72%)'>Commerce</GradientText></Title>
            </div>
            <Subtitle font={16} style={{ fontWeight: 400 }}>Todos os direitos reservados</Subtitle>
            <Subtitle font={16} style={{ fontWeight: 400 }}><b>Turbo</b> Partners 2023 - CNPJ: 42.100.292/0001-84</Subtitle>
          </div>
          <div className='flex row gap-16'>
            <Social href="https://www.instagram.com/turbo.partners/" target="_blank">
              <Instagram/>
            </Social>
            <Social href="https://www.facebook.com/Turbo-Partners-111691498031338/" target="_blank">
              <Linkedin/>
            </Social>
            <Social href="https://www.linkedin.com/company/turbo-partners" target="_blank">
              <Facebook/>
            </Social>
            <Social href="https://www.youtube.com/@TurboPartners?sub_confirmation=1" target="_blank">
              <Youtube/>
            </Social>
          </div>
        </FooterWrapper>
      </div>
    </PageWrapper>
  );
};

export default Commerce1;
