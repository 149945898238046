import React from "react";
import styled, { keyframes } from "styled-components";
import ContactButton from '../components/ContactButton';

const marqueeAnimation = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const CreatorsWrapper = styled.div`
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 29.38vw;
  background: url('/creators/background.png') no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding-top: 5.4vw;
  
  @media screen and (max-width: 1440px) {
    height: 423px;
    padding-top: 77px;
  }

  @media screen and (max-width: 768px) {
    background: url('/creators/background_mobile.png') no-repeat;
    background-position: center bottom;
    height: 717px;
    padding-top: 55px;
  }
`;

const GifWrapper = styled.div`
  position: absolute;
  top: 5.4vw;
  right: 20.8vw;
  width: 9.6vw;
  height: 13.4vw;
  transform: rotate(10deg);
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    top: 77px;
    right: calc((100vw - 1065px)/2 + 113px);
    width: 138px;
    height: 193px;
    border-radius: 3px;
  }

  @media screen and (max-width: 768px) {
    top: 425px;
    right: calc((100vw - 390px)/2 + 57px);
    width: 138px;
    height: 191px;
    border-radius: 2.25px;
  }
`;

const CreatorsGif = styled.img`
  width: 100%;
  height: auto;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 2.92vw;
  line-height: 3.54vw;
  letter-spacing: -0.0108em;
  color: #342A41;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 42px;
    line-height: 51px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    font-size: 29px;
    line-height: 37px;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  gap: 16px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  width: 35.05vw;
  margin: 16px 0;
  font-size: 1.25vw;
  line-height: 1.52vw;
  font-weight: 400;
  letter-spacing: -0.0108em;
  color: #342A41;
  opacity: 0.8;
  text-align: center;

  @media screen and (max-width: 1440px) {
    width: 505px;
    font-size: 18px;
    line-height: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 288px;
    font-size: 16px;
    line-height: 19.5px;
    margin: 18px 0 26px 0;
  }
`;

const GradientTitle = styled.span`
  background: ${props => props.index === 1 ? 'linear-gradient(90.37deg, #27F5B0 -4.73%, #A26BE3 240.74%)' : 'linear-gradient(90.02deg, #27F5B0 -138.89%, #A26BE3 97.23%)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const MarqueeWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 119px;
  overflow-x: hidden;
  background: linear-gradient(89.74deg, #27F5B0 0.22%, #6163F0 52.2%, #BD49FF 99.77%);
  display: flex;
  align-items: center;
  z-index: 1;
  margin-top: -6.3vw;

  @media screen and (max-width: 1440px) {
    margin-top: -90px;
  }

  @media screen and (max-width: 768px) {
    height: 97px;
    margin-top: 20px;
  }
`;

const MarqueeTrack = styled.div`
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: ${marqueeAnimation} 16s linear infinite;
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  column-gap: 8px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.0128em;
  color: #fff;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
    font-size: 16.5px;
    line-height: 20px;
    column-gap: 6px;
  }
`;

const ItemIcon = styled.img`
  height: auto;
  width: auto;

  @media screen and (max-width: 768px) {
    transform: scale(0.82);
  }
`;

const CreatorsContent = ({ isMobile }) => {

  React.useEffect(() => {
    const words = ['vendas', 'engajamento', 'fortunas'];
    let i = 0;

    function typingEffect() {
      let word = words[i].split('');
      var loopTyping = function () {
        if (word.length > 0) {
          document.getElementById('word').innerHTML += word.shift();
        } else {
          setTimeout(deletingEffect, 500);
          return false;
        }
        setTimeout(loopTyping, 200);
      };
      loopTyping();
    }

    function deletingEffect() {
      let word = words[i].split('');
      var loopDeleting = function () {
        if (word.length > 0) {
          word.pop();
          document.getElementById('word').innerHTML = word.join('');
        } else {
          if (words.length > i + 1) {
            i++;
          } else {
            i = 0;
          }
          typingEffect();
          return false;
        }
        setTimeout(loopDeleting, 100);
      };
      loopDeleting();
    }

    typingEffect();
  }, []);

  const marquee = [
    {
      icon: "/creators/marquee_1.png",
      text: "Quem chega cedo bebe água limpa",
    },
    {
      icon: "/creators/marquee_2.png",
      text: "Conteúdo = Dinheiro",
    },
    {
      icon: "/creators/marquee_3.png",
      text: "Conteúdos gerados por usuários",
    },
    {
      icon: "/creators/marquee_4.png",
      text: "Menos é mais",
    },
    {
      icon: "/creators/marquee_5.png",
      text: "Simplicidade vende",
    },
    {
      icon: "/creators/marquee_6.png",
      text: "Estratégias recém-chegadas ao Brasil",
    },
    {
      icon: "/creators/marquee_7.png",
      text: "Fale a língua da plataforma",
    }
  ];

  return (
    <CreatorsWrapper>
      <ContentWrapper className="page-width">
        <GifWrapper>
          <CreatorsGif src={require('../assets/creators-gif.gif')} loading='lazy'/>
        </GifWrapper>
        <Title>
          <GradientTitle index={1}>Vídeos</GradientTitle> simples de<br/>
          15s geram <GradientTitle id="word"></GradientTitle>
          <span className="blink">|</span>
        </Title>
        <Text>Escolha entre as 2 opções abaixo e comece a pegar o dinheiro que (ainda) está na mesa.</Text>
        <ContactWrapper>
          <ContactButton
            width={312}
            height={52}
            link="#packages"
            text="Quero pra minha empresa"
            font="'Paralucent'"
            solidButton={true}
            background='linear-gradient(89.78deg, #27F5B0 0.2%, #6163F0 118.91%, #BD49FF 124.32%)'
            shadow='0px 4px 12px rgba(39, 245, 176, 0.38)'
            icon='rocket'
            iconHeight={16.5}
          />
          <ContactButton
            width={isMobile ? 312 : 278}
            height={52}
            link="https://form.respondi.app/WNXz6Z0b"
            target="_blank"
            text="Quero ser um creator"
            font="'Paralucent'"
            solidButton={true}
            background='linear-gradient(89.74deg, #27F5B0 -39.69%, #6163F0 0.91%, #BD49FF 93.91%)'
            shadow='0px 4px 12px rgba(162, 108, 227, 0.5)'
            icon='user'
            iconHeight={18}
          />
        </ContactWrapper>
      </ContentWrapper>
      <MarqueeWrapper>
        <MarqueeTrack>
          <MarqueeContent>
            {marquee.map((item, index) => (
              <ItemWrapper key={index}>
                <ItemIcon src={item.icon} />
                {item.text}
              </ItemWrapper>
            ))}
            {marquee.map((item, index) => (
              <ItemWrapper key={index}>
                <ItemIcon src={item.icon} />
                {item.text}
              </ItemWrapper>
            ))}
          </MarqueeContent>
        </MarqueeTrack>
      </MarqueeWrapper>
    </CreatorsWrapper>
  );
};

export default CreatorsContent;
