import React from 'react';
import styled from 'styled-components';
import { AiFillLock } from 'react-icons/ai';
import { IconContext } from "react-icons";

const CardWrapper = styled.div`
  border-radius: 16px;
  width: 389px;
  height: 361px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  background: #08031A;
  background-clip: padding-box; /* !importanté */
  
  @media screen and (max-width: 768px) {
    width: 301px;
    max-width: calc(100vw - 32px);
  }

  &:before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -3;
    border-radius: inherit; /* !importanté */
    background: ${props => props.background};
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }
`; 

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`; 

const Title = styled.h4`
  font-size: 24px;
  line-height: 29px;
  font-weight: 800;
  letter-spacing: -1.08px;
  text-align: left;  
  color: #fff;
  width: max-content;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.99px;
  }
`; 

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  opacity: 0.7;
  text-align: left;  
  color: #fff;
  margin: 0;
`;

const Icon = styled.img`
  width: 35px;
  height: 35px;

  @media screen and (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const Button = styled.a`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  width: 327px;
  max-width: 85%;
  height: 50px;
  margin: 0 42px 35px 42px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  column-gap: 10px;
  background: ${props => props.active ? props.color : `linear-gradient(#08031A, #08031A) padding-box, ${props.color} border-box`};
  border: ${props => props.active ? '' : '2px solid transparent'};
  color: ${props => props.active ? '#fff' : props.color};
  filter: ${props => `drop-shadow(0px 16px 50px ${props.shadow})`};
  
  @media screen and (max-width: 768px) {
    box-shadow: ${props => `0px 16px 50px ${props.shadow}`};
    margin: 0 21px 28px 21px;
  }

  &:hover {
    @media screen and (min-width: 768px) {
      cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};
      color: #fff;
      background: ${props => (props.active ? '' : props.color)};
      box-shadow: ${props => `0px 16px 50px ${props.shadow}`};
      transition: background 1s;
    }
  }
`;

const BrandsCard = ({ brand, isMobile }) => {
  const [hover, setHover] = React.useState(false);
  
  return (
    <CardWrapper background={brand.color}>
      <div style={{ margin: isMobile ? '28px 21px 0 21px' : '47px 42px 0 42px' }}>
        <TitleWrapper>
          <Icon src={brand.icon}/>
          <Title>Turbo <span style={brand.title === 'Creators' ? { backgroundColor: '#f3ec78', backgroundImage: 'linear-gradient(90deg, #27F5B0 0%, #A26BE3 98.06%)', backgroundSize: '100%', WebkitBackgroundClip: 'text', MozBackgroundClip: 'text', WebkitTextFillColor: 'transparent', MozTextFillColor: 'transparent', fontWeight: 300, marginLeft: '-2px' } : { fontWeight: 300, color: brand.color, marginLeft: '-2px' }}>{brand.title}</span></Title>
        </TitleWrapper>
        <Text>{brand.content}</Text>
      </div>
      <Button className='change-link' color={brand.color} active={brand.active} shadow={brand.shadow} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} href={brand.active ? brand.url : undefined} target={brand.title === "Class" ? "_blank" : "_self"}>
        <span>
          {brand.button}
        </span>
        {!brand.active && 
          <svg width="18" height="18">
            <defs>
              <linearGradient id="myGradient">
                <stop offset="0%"  stopColor="#27F5B0" />
                <stop offset="98%" stopColor="#A26BE3" />
              </linearGradient>
            </defs>
            <IconContext.Provider value={{ attr: { fill: (hover && !isMobile ? '#fff' : (brand.title === 'Creators' ? "url('#myGradient')" : brand.color)) }}}>
              <AiFillLock size="18px"/>
            </IconContext.Provider>
          </svg>
        }
      </Button>
    </CardWrapper>
  );
};

export default BrandsCard;
