import { GoogleSpreadsheet } from "google-spreadsheet";

const turbojson = {
  "type": "service_account",
  "project_id": "lp-commerce",
  "private_key_id": "3327c01dc669d8940127580f6d7602038af81e9d",
  "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC6wD/poLpMwbJB\nGQpDsK2SB5ZaPsylM+ZNTyjx7B/hve/xOECzlY/qt6oEFMTmFnBz4bzlCgKu5l0H\nh7XK9RzqMHSRjgOiteeRtCOoRIQsGayxkJzYy4HUF79/aUSZupFCa5lkffkhoTxR\nLQDHRBUodpvAdkke+PPSC3trvv8S8TIGV0RfOehZWdRaeTRMBSPQeAgJklUz2XOE\n0pYPyJ7qBKPtQoVNuhTqW7UT2a3WJYSOwMlHVink8S3l4xDXgWS+mJ3MB/YRvMxC\nh3Xk1/oeuOUyj1qZPJjsKnnq8TuS+YqUj+xeqORz/RfNAB52AF25UzNOkBtsLjwZ\nLfmY/NSZAgMBAAECggEAAyQa5xdu7r3RcqwaGrhGO8GrfxjVjmqcgzzIvI9Fxtaa\nn6u8KzEXAWIFsWl2hC6zhUAc8jFsXmFNm66bXt6ndfkVlYGmRhaJvq8BQbImUstl\n7CYkJzbUDWYkBcpL2bXpkyILa6U9Re99RoRrjLkfIyXh5IIGJJKCylaAWiLKzZhw\nLArdNhfsf8/CpEKSHIHxnHMNWN0pU34v2I29tqkmW+H6Ci2CHJWB/HcIk1GUpMpG\nbnzIy9OtIW4pifho6gnMKkzczl9QDqqcEF5/Do6H6NvoVjY/7ZPbJaANq+6Q7uHC\nbbhpMOZvDlJ2kCQiftAqWjgR7TB26Q3BjGz+wEj10QKBgQDj1ycE1oADcaubKE5b\nYXlagqpw8hJurr4glozHOoL4CCOCVHgC7hBHnApMrUf2jMpfPGebxFm3Sl5ELN99\n4/ixcQS0kgJkIgTZlg48LgfXorVBzLutCAUsV+oltPnOkwEjdQt8sSJ4xv75ll1T\nvvo/KZ6TZGuJDKnozmw6dqX0EQKBgQDR1QfqFA9n7Dd9ewD3iOdl7bQXNoCLGyIJ\n0xvpvPKBEUahRAy85Z7ju8IVujBgQRnpSyMp58lB7whlhqrxIN56DTrv5cHtGMb2\nE0GcU+y316+21vohUpmBKAcW5GxBl5Se8ZIGEf/a/JltrydBSY0wRIjmau2EQBsk\nyps7BPNACQKBgQC4HqlghnsCzXwxMZxIyneIBin4hhcRLNNmgGMZqRYnnGQdsWce\nbwXm6JmGJ04NVu8/kQ/lWAJsVYPiMiUVlgHmWv/DcG7QPpGOyG2OgmBCCTHTD8Dm\nbqHHmPAWMq7hGfkEj1SOGpNR6PYp+jJHOAEAxABq+e7Q3NP917qscC9iEQKBgArL\n1zhoB9LlCIXgSlByLtXDwTxghYSixMGUpx8DpcaWSPgLNEyT0/ULuBS+G7cYImkq\ngIircGOAFQKER09YzZmiDKJDRBMa70HCA+IzPz7GTjguH3iPc259zL+UyS0my3LS\nIx1E19FApfRKys5FJoqRPCn+3nGrirn94rbKrmlhAoGAH8CcFzJ8hThgEPp6v6Ga\nEJEUARCCr8CTGe8Fe4so+wl+kqp1XH/gWF54nOXPHxTYcPSVGJMu3UJWeemyATEF\n33ZQYX/64vehVorbOR4tXPc01UfBthwADvnDQ9w7LClLidVLS++Xt9gUORDI28d8\n6W6UowtVjfFyODOtLC+IV+Y=\n-----END PRIVATE KEY-----\n",
  "client_email": "turbopartners@lp-commerce.iam.gserviceaccount.com",
  "client_id": "114091743631802538320",
  "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  "token_uri": "https://oauth2.googleapis.com/token",
  "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/turbopartners%40lp-commerce.iam.gserviceaccount.com"
}

const doc = new GoogleSpreadsheet('1bVzRj2UdGAgrZUn-xcy8S15LKz-GyJthXITjxbyJZe8');

const appendSpreadsheet = async (row) => {
try {
    await doc.useServiceAccountAuth({
    client_email: turbojson.client_email,
    private_key: turbojson.private_key
    });
    await doc.loadInfo();

    const sheet = doc.sheetsByIndex[0];
    const result = await sheet.addRow(row);
    return result;
} catch (e) {
    console.error('Error: ', e);
}
};

export const createFormResult = async (data) => {
    return appendSpreadsheet(data)
}
